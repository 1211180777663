import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Title from 'src/components/Basicos/Form/Title';

const BarChartVertical = ({ title, data = [] }) => {

  return (
    <div className='flex flex-col h-full'>
      <div className="flex w-full justify-between pr-5 py-5">
        <Title
          className={`text-2xl font-bold font-mont-bold text-gray-system-mega-imoveis`}
          content={title} />
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={800}
          height={300}
          data={data}
          layout="vertical"
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type='number' />
          <YAxis dataKey="name" type="category" width={95} />
          <Tooltip />
          <Legend iconType='circle' />
          <Bar dataKey="analise_diretoria" name='Análise pela Diretoria' stackId="a" fill="#00CFC3" barSize={20} />
          <Bar dataKey="analise_gerencia" name='Análise pela Gerência' stackId="b" fill="#6271FF" barSize={20} />
          <Bar dataKey="analise_aprovada" name='Aprovados' stackId="c" fill="#FF7E62" barSize={20} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
export default BarChartVertical;
