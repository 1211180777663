import React from 'react';
import { dateLaravelToPtBr } from 'src/helpers/functions';

interface ModeloProps {
    nome?: any;
    tipo_garantia?: any;
    status?: any;
    tipo?: any;
    data_imovel?: any;
}

const Header: React.FC<ModeloProps> = ({ tipo, nome, tipo_garantia, status, data_imovel }) => {
    return (
        <div className='header-pdf'>
            <span className='ml-4'>
                {'Ficha de ' + tipo}
            </span>
            <div className='flex info-header-pdf mb-4'>
                {tipo == 'Imóvel' ? <><span className='ml-4 mr-2'>Data de Criação:</span> {dateLaravelToPtBr(data_imovel)}</> :
                    <>
                        <span className='ml-4 mr-2'>Nome:</span> {nome}
                        {tipo == 'Proprietário' || tipo == 'Fiador' ? '' :
                            <><span className='ml-4 mr-2'>Tipo de Garantia:</span> {tipo_garantia}</>
                        }
                        {tipo == 'Proprietário' ? '' :
                            <><span className='ml-4 mr-2'>Status:</span> {status}</>
                        }
                    </>
                }
            </div>
        </div>
    );
};

export default Header;