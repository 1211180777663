import React, {createContext, useState, useContext} from 'react';
import {parametersFaturaProps, DadosRelatorioAnaliticoProps, DadosRelatorioSinteticoProps, UsinaData} from 'src/@types/types';
interface IControlModalConsultasContext{
    isOpenModal: boolean;
    textTitleNavBar: String;
    closePageConsultas: () => void;
    openPageConsultas: (textTile:String) => void;
    dadosRelatorioAnalitico: DadosRelatorioAnaliticoProps;
    dadosRelatorioSintetico: DadosRelatorioSinteticoProps;
    parametersFatura: parametersFaturaProps;
    dadosUsinaHeader: UsinaData,
    setDadosRelatorioAnaliticoFn: (dadosFormRelatorioAnalico: DadosRelatorioAnaliticoProps) => void;
    setDadosRelatorioSinteticoFn: (dadosFormRelatorioAnalico: DadosRelatorioAnaliticoProps) => void;
    setParametersFaturaFn: (parametersFatura: parametersFaturaProps, dadosUsina: UsinaData) => void;
}

interface IControleModalConsultasProps{
    children: React.ReactNode;
}

const ControlModalConsultasContext = createContext<IControlModalConsultasContext>({} as IControlModalConsultasContext);

const ControlModalProvider : React.FC <IControleModalConsultasProps> = ({children}) => {
    const [isOpenNewPageConsultas, setIsOpenNewPageConsultas] = useState<boolean>(false);
    const [textTitleNavBar, setTextTitleNavBar] = useState<String>('');
    const [dadosRelatorioAnalitico, setDadosRelatorioAnalitico] = useState<DadosRelatorioAnaliticoProps>({});
    const [dadosRelatorioSintetico, setDadosRelatorioSintetico] = useState<DadosRelatorioSinteticoProps>({});
    const [parametersFatura, setparametersFatura] = useState<parametersFaturaProps>({});
    const [dadosUsinaHeader, setDadosUsinaHeader] = useState<UsinaData>({});

    const closePageConsultas = () => {
        setIsOpenNewPageConsultas(false);
        setTextTitleNavBar('');
    }

    const openPageConsultas = (textTile: String) => {
        setIsOpenNewPageConsultas(true);
        setTextTitleNavBar(textTile);
    }

    const setDadosRelatorioAnaliticoFn = (dadosFormRelatorioAnalico: DadosRelatorioAnaliticoProps) => {
        setDadosRelatorioAnalitico({
            ...dadosFormRelatorioAnalico
        });
    }
    const setDadosRelatorioSinteticoFn = (dadosFormRelatorioAnalico: DadosRelatorioSinteticoProps) => {
        setDadosRelatorioSintetico({
            ...dadosFormRelatorioAnalico
        });
    }
    const setParametersFaturaFn = (parametersFatura: parametersFaturaProps, dadosUsina: UsinaData) => {
        setparametersFatura({
            ...parametersFatura
        });
        setDadosUsinaHeader({
            ...dadosUsina
        });
    }

    return(
        <ControlModalConsultasContext.Provider value={{
            isOpenModal: isOpenNewPageConsultas,
            textTitleNavBar: textTitleNavBar,
            closePageConsultas,
            openPageConsultas,
            dadosRelatorioAnalitico,
            dadosRelatorioSintetico,
            parametersFatura,
            dadosUsinaHeader,
            setDadosRelatorioAnaliticoFn,
            setDadosRelatorioSinteticoFn,
            setParametersFaturaFn
        }}>
            {children}
        </ControlModalConsultasContext.Provider>
    );
}

function useControlModal(): IControlModalConsultasContext {
    const context = useContext(ControlModalConsultasContext);
    if(!context) {
        throw new Error('useControlModal must be used whtin ControlModalProvider');
    }
    return context;
}

export { ControlModalProvider, useControlModal };