import React, { Fragment } from "react";
// import { AiOutlineCheckCircle } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
// import { VscError } from "react-icons/vsc";
import { Props } from "./types";

export default function Toast({
  toastContent = "Coloque uma mensagem aqui",
  toastType,
}: Props): JSX.Element {
  return (
    <Fragment>
      <div className="flex">
        {(toastType === "error" && (
          <Fragment>
            {/* <VscError size="1.3rem" className="mr-1" /> */}
            <span className="font-bold ml-1 text-sm">{toastContent}</span>
          </Fragment>
        )) ||
          (toastType === "success" && (
            <Fragment>
              {/* <AiOutlineCheckCircle size="1.3rem" className="mr-1" /> */}
              <span className="font-bold ml-1 text-sm">{toastContent}</span>
            </Fragment>
          )) ||
          (toastType === "warning" && (
            <Fragment>
              <FiAlertCircle size="1.3rem" className="mr-1" />
              <span className="font-bold ml-1 text-sm">{toastContent}</span>
            </Fragment>
          ))}
      </div>
    </Fragment>
  );
}
