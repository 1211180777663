import React from "react";

type Props = {
  children: JSX.Element | Array<JSX.Element>;
};

type State = {
  temErro: boolean;
};

export default class VerificarErroInterno extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = { temErro: false };
  }

  static getDerivedStateFromError(): State {
    return { temErro: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.log(error);
  }

  render() {
    return this.state.temErro ? (
      <div className="grid grid-cols-1 justify-items-center py-16 invisible">
        <div className="container text-center">
          <div className="mb-3">
            <h1 className="text-4xl">Ooops, algum erro aconteceu....</h1>
            <p className="text-2xl">
              Caso persista, contate seu Administrador/Softbuilder!
            </p>
          </div>

          <div className="flex justify-center mb-2 pt-5 cursor-pointer">
            <button
              onClick={() => window.location.reload()}
              type="button"
              className="btn-save-vendedor bg-green-500 text-lg text-white font-bold px-8 py-2 rounded-full shadow-md"
            >
              Recarregar pagina
            </button>
          </div>
        </div>
      </div>
    ) : (
      this.props.children
    );
  }
}
