import React from 'react';
import Popup from 'reactjs-popup';
import './style.css'

type Props = {
    open: any;
    onCloseModal: any;
    usuarios: any;
    titulo: any
}

function ModalUsuarios
    ({ open, onCloseModal, usuarios, titulo }: Props): JSX.Element {

    return (
        <div>
            <Popup
                open={open}
                closeOnDocumentClick
                lockScroll
                onClose={onCloseModal}
            >
                <div className='modal-usuarios'>
                    <h1 className='titulo-modal-usuarios estilo-fonte-negrito'>{titulo}</h1>
                    <div className='usuarios-grupo-modal'>
                        {usuarios?.map((usuario: any) => {
                            return (
                                <div className='mb-2'>
                                    <h1 className='cor-cinza'>{usuario.nome} {usuario.sobrenome} - {usuario.email}</h1>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Popup>
        </div>
    );
};

export default ModalUsuarios;