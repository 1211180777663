import React, { Fragment, useEffect, useState } from 'react';

import Loader from 'src/components/Basicos/Loader';
import TableWithGlobalSearch from 'src/components/Basicos/TableWithGlobalSearch';
import { compararDatas } from 'src/helpers/functions';
import { axiosApi } from 'src/services/axiosInstance';
import { useControlContextCadastro } from '../../../contexts/CadastroControlContext';
import { proprietarios } from "./data.columns.json";


const CadastroProprietarios: React.FunctionComponent = () => {
    const [data, setData] = useState<Array<any>>([]);
    const [loader, setLoader] = useState(false);
    const { etapaActive, dateActive, setDateFilter, setEtapa, sedeActive, checkCancelado } = useControlContextCadastro();

    const getProprietarios = async () => {
        try {
            setLoader(true)
            const { data } = await axiosApi.get(`/proprietario`);
            setData(data?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
            setDateFilter(null, null);
            setEtapa(0);
        }
    }

    const filterProprietarioByStatus = (EtapaId: Number) => {
        if (EtapaId !== 0) {
            return data.filter((item: any) => { return item?.ultimo_historico?.etapa_historico_validacao_id === EtapaId });
        }
        return data;
    };

    const filterProprietarioBySede = (data: any) => {
        if (sedeActive != 0)
            return data.filter((item: any) => { return item?.sede_responsavel_id === sedeActive });
        return data;
    }

    const filterProprietarioByDate = (dateInicial: any, dateFinal: any) => {
        return filterProprietarioBySede(compararDatas(dateInicial, dateFinal, filterProprietarioByStatus(etapaActive)));
    };

    const filterCancelado = (data: any) => {
        if (checkCancelado == true)
            return data.filter((item: any) => { return item?.status == 2 })
        else
            return data.filter((item: any) => { return item?.status == 1 })
    }

    useEffect(() => {
        getProprietarios();
    }, []);

    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
                <div className='row-auto' style={classes.container}>
                    <div className="card-content">
                        <div className="grid flex-col p-6">
                            <TableWithGlobalSearch
                                columns={proprietarios.columns}
                                pathName={`proprietario/detalhes`}
                                data={filterCancelado(filterProprietarioByDate(dateActive?.dateInicial, dateActive?.dateFinal).sort((a: any, b: any) => Number(b.id) - Number(a.id)))}
                                actions={{
                                    show: () => { }
                                }}
                                isButtons={true}
                                itsCount={true}
                                requestClose={getProprietarios}
                            />
                        </div>
                    </div>
                </div>
            </Loader>

        </Fragment>
    );
}

const classes = {
    container: {
        marginTop: '3.5rem',
        marginButtom: '3.5rem',
        marginLeft: '2rem',
        marginRight: '2rem',
    },
};

export default CadastroProprietarios;