import React from 'react';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import './styles.css';
import Modelo1 from 'src/components/PDF/ModelosLocatario/Modelo1';
import Modelo2 from 'src/components/PDF/ModelosLocatario/Modelo2';
import Modelo3 from 'src/components/PDF/ModelosLocatario/Modelo3';
import Modelo4 from 'src/components/PDF/ModelosLocatario/Modelo4';
import ModeloPJ1 from 'src/components/PDF/ModelosLocatario/ModeloPJ1';

interface ComponenteProps {
    locatario?: any
}

const ComponentToPDF: React.FC<ComponenteProps> = ({ locatario }) => {

    return (
        <>
            {
                locatario?.tipo_locatario_id == 1 ? (
                    locatario?.conjuge_id != null && locatario?.procurador_id != null ? <Modelo1 locatario={locatario} /> :
                        locatario?.conjuge_id == null && locatario?.procurador_id != null ? <Modelo2 locatario={locatario} /> :
                            locatario?.conjuge_id != null && locatario?.procurador_id == null ? <Modelo3 locatario={locatario} /> :
                                <Modelo4 locatario={locatario} />
                ) : <ModeloPJ1 locatario={locatario} />
            }
            { }
        </>
    );
};

export default ComponentToPDF;