import React, { useContext, useState, createContext } from 'react';

interface IAuthContext {
    logged: boolean;
    signIn(email: string, password: string): void;
    signOut(): void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC = ({ children }) => {
    const [logged, setLogged] = useState<boolean>(() => {
        const isLogged = localStorage.getItem('user:logged');
        return !!isLogged;
    });

    const signIn = (email: string, password: string) => {
        if (email === '' && password === '') {
            localStorage.setItem('user:logged', 'true');
            setLogged(true);
        } else {
            alert('E-mail ou senha invalido');
        }
    }

    const signOut = () => {
        localStorage.removeItem('user:logged');
        setLogged(false);
    }

    return (
        <AuthContext.Provider value={{ logged, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
}

function useAuth(): IAuthContext {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useControlModal must be used whtin ControlModalProvider');
    }
    return context;
}

export { AuthProvider, useAuth };
