import React from "react";
import "./tailwind.output.css";
import Rotas from "src/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthState } from "./store/types";
import { connect, ConnectedProps } from "react-redux";
import { setDadosAtualizados } from "src/store/actions/auth";
import { useEffect } from "react";
import { ativarRefreshToken } from "./services/axiosInstance";
import VerificarErroInterno from "./components/Error";
import "react-toastify/dist/ReactToastify.css";
import ApplicationProvider from "./contexts/ApplicationProvider";

const mapStateToProps = ({ auth }: { auth: AuthState }) => {
  return {
    token: auth.access_token?.token,
    expires_in: auth.expires_in,
  };
};

const mapDispatchToProps = {
  setDadosAtualizados,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {};

function App({ token, setDadosAtualizados }: Props): JSX.Element {
  useEffect(() => {
    if (token) {
      ativarRefreshToken(setDadosAtualizados);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VerificarErroInterno>
      <ApplicationProvider>
        <Router>
          <Rotas isToken={token} />
        </Router>
      </ApplicationProvider>
    </VerificarErroInterno>
  );
}

export default connector(App);
