import React, { Fragment, useState } from 'react';
import './style.css';
import Popup from 'reactjs-popup';
import { axiosApi } from 'src/services/axiosInstance';

type Props = {
    isOpen: any
    isClose: any
    closeOnOutsideClick: any
    lockScroll: any
    changeFiltro: any
    onChange?: any;
    closeModalCancelar?: any;
    locatarioId?: any;
}

const ModalCancelarMobile: React.FunctionComponent<Props> = ({
    isOpen,
    lockScroll,
    onChange,
    isClose,
    closeModalCancelar,
    locatarioId
}) => {
    const [motivo, setMotivo] = useState<any>();

    const onChangeMotivo = (e: any) => {
        const { value } = e.target;
        setMotivo(value);
    };

    const onSubmit = async () => {
        try {
            await axiosApi.put(`cancelar-locatario/${locatarioId}`, motivo);
        } catch (error) {
            console.log(error);
        } finally {
            closeModalCancelar();
        }
    }
    return (
        <Popup
            modal
            open={isOpen}
            closeOnDocumentClick={closeModalCancelar}
            lockScroll={lockScroll}
            onClose={closeModalCancelar}
        >
            <Fragment>
                <div className='modal-cancelar-mobile'>
                    <div className="w-full">
                        <div>
                            <p className="font-extrabold text-center text-1xl font-mont-bold" style={{ color: "#081E52" }}>Deseja cancelar esse cadastro?</p>
                            <p className="font-extrabold text-center text-1xl mb-4 font-mont-bold" style={{ color: "#081E52" }}>Digite o motivo do cancelamento.</p>
                        </div>
                    </div>
                    <div className='text-modal-cancelar'>
                        <textarea
                            rows={4}
                            onChange={onChangeMotivo}
                            required
                            name={`comentario_fiador`}
                            style={{ maxHeight: "8rem", minHeight: "3.5rem" }}
                            className="block p-4 w-full h-32 mt-2 text-sm border-2 border-opacity-100 text-gray-system-mega-imoveis border-gray-system-mega-imoveis rounded-lg focus:outline-none placeholder-gray-700 resize-none"
                            placeholder={`Dê detalhes do porquê o processo de locação foi cancelado`}
                        />
                    </div>
                    <div className="flex flex-row w-full justify-center row-buttons-modal-delete">

                        <button
                            type={`button`}
                            className={`flex h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs bg-white text-blue-mega-imoveis cursor-pointer rounded-lg justify-center items-center buttons-modal-delete`}
                            onClick={() => closeModalCancelar()}
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            onClick={() => onSubmit()}
                            className={`flex h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs bg-blue-mega-imoveis text-white cursor-pointer rounded-lg justify-center items-center buttons-modal-delete`}
                        >
                            Confirmar
                        </button>
                    </div>
                </div>
            </Fragment>
        </Popup>
    );
}

export default ModalCancelarMobile;