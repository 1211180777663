import React, { Fragment, useEffect, useState } from 'react';

import Loader from 'src/components/Basicos/Loader';
import TableWithGlobalSearch from 'src/components/Basicos/TableWithGlobalSearch';
import { compararDatas } from 'src/helpers/functions';
import { axiosApi } from 'src/services/axiosInstance';
import { useControlContextCadastro } from '../../../contexts/CadastroControlContext';
import { fiador } from "./data-columns.json";
import FiadorMobile from './indexMobile';
import { useControlContextMobile } from 'src/contexts/MobileContext';


const CadastroFiador: React.FunctionComponent = () => {
    const [data, setData] = useState<Array<any>>([]);
    const { etapaActive, dateActive, setDateFilter, statusEtapaActive } = useControlContextCadastro();
    const [loader, setLoader] = useState(false);
    const { setMobile, mobile } = useControlContextMobile();

    const getFiador = async () => {
        try {
            setLoader(true)
            const { data } = await axiosApi.get(`/fiador`);
            setData(data?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
            setDateFilter(null, null);
        }
    }

    const filterFiadorByStatusEtapa = (data: any) => {
        if (statusEtapaActive !== 0) {
            return data.filter((item: any) => { return item?.ultimo_historico?.status_validacao_id === statusEtapaActive });
        }
        return data;
    };

    const filterFiadorByStatus = (statusId: Number) => {
        if (statusId !== 0) {
            return data.filter((item: any) => { return item?.ultimo_historico?.etapa_historico_validacao_id === statusId });
        }
        return data;
    };

    const filterFiadorByDate = (dateInicial: any, dateFinal: any) => {
        return compararDatas(dateInicial, dateFinal, filterFiadorByStatus(etapaActive));
    };

    useEffect(() => {
        getFiador();
        setMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }, []);

    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
                {mobile ?
                    <FiadorMobile
                        data={filterFiadorByStatusEtapa(filterFiadorByDate(dateActive.dateInicial, dateActive.dateFinal).sort((a: any, b: any) => Number(b.id) - Number(a.id)))}
                    /> :
                    <div className='row-auto' style={classes.container}>
                        <div className="card-content">
                            <div className="grid flex-col p-6">
                                <TableWithGlobalSearch
                                    columns={fiador.columns}
                                    pathName={`fiador/detalhes`}
                                    data={filterFiadorByStatusEtapa(filterFiadorByDate(dateActive.dateInicial, dateActive.dateFinal).sort((a: any, b: any) => Number(b.id) - Number(a.id)))}
                                    actions={{
                                        show: () => { }
                                    }}
                                    isButtons={true}
                                    itsCount={true}
                                    requestClose={null}
                                />
                            </div>
                        </div>
                    </div>
                }
            </Loader>
        </Fragment>
    );
}

const classes = {
    container: {
        marginTop: '3.5rem',
        marginButtom: '3.5rem',
        marginLeft: '2rem',
        marginRight: '2rem',
    },
};

export default CadastroFiador;