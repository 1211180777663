import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

type Props = {
  content?: string;
};

function EmptyPlaceholder({ content }: Props): JSX.Element {
  return (
    <div className="flex justify-center items-center py-10 mt-3 mb-5 border-4 border-dashed border-gray-300 rounded-lg h-48 text-gray-300 text-xl">
      <AiOutlineInfoCircle size="1.3rem" className="mt-1 mr-2" />
      <span className="font-bold">{content}</span>
    </div>
  );
}
export default EmptyPlaceholder;
