import React from 'react';
import Modal from '../../ModalNoticia';

type Props = {
    open: boolean;
    titulo: string | undefined;
    modalId: string | number | undefined;
    nameRouter?: string;
    isClose?: any;
    requestData?: any;
    onChangeStatus?: any;
    status?: any;
}

const ModalEnviado: React.FC<Props> = ({
    isClose,
    open,
    modalId,
    titulo = "Tem Certeza que Deseja Excluir?",
    nameRouter = "",
    requestData,
    onChangeStatus = "",
    status = ""
}: Props): JSX.Element => {

    const close = () => {
        isClose()
    }

    return (
        <Modal
            isOpen={open}
            isClose={() => close()}
            headerStyle={''}
            widthModal={`35%`}
            closeOnOutsideClick={false}
            modalStyle={`modal-style bg-white rounded-lg py-5 px-10 modal-resetar-senha`}
        >
            <div>
                <form className={`flex flex-col items-center h-32 justify-between`}>
                    <div className="flex">
                        <p className="font-extrabold text-center text-2xl mb-4 font-mont-bold" style={{ color: "#081E52" }}>{titulo}</p>
                    </div>

                    <div className='frase-modal-senha'>
                        <h1 className='mt-4 mb-4'>Nova senha enviada por email ao colaborador.</h1>
                    </div>

                    <div className="flex flex-row w-full justify-center mt-4 botoes-reset-senha">

                        <button
                            type={`button`}
                            className={`flex w-full h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs bg-blue-mega-imoveis text-white cursor-pointer rounded-lg justify-center items-center tamanho-botao-reset`}
                            onClick={() => isClose()}
                        >
                            Ok
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
}

export default ModalEnviado;