import React, { Fragment, useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import CardNoticia from "src/components/Basicos/Cards/CardNoticias";
import Inputlabel from "src/components/Basicos/Form/Input/InputLabel";
import InputSelect from "src/components/Basicos/Form/Input/InputSelect";
// import Header from "src/components/Basicos/Header";
import Loader from "src/components/Basicos/Loader";
import ModalDelete from "src/components/Funcionalidades/ModalDelete";
import Modal from "src/components/Funcionalidades/ModalNoticia";
import { compararDatas, searchFilter, tipoFilter } from "src/helpers/functions";
import { axiosApi } from "src/services/axiosInstance";
import EditNoticia from "./EditNoticia";
import { IDataForm, noticiaProps, Option, TipoNoticias } from "./types";


const Noticias: React.FunctionComponent = () => {

    const [typeInicio, setTypeInicio] = useState("text");
    const [typeFim, setTypeFim] = useState("text");
    const [open, setOpen] = useState<boolean>(false);
    const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
    const [dataNoticias, setdataNoticias] = useState<Array<noticiaProps>>([]);
    const [tiposNoticias, setTiposNoticias] = useState<Array<Option>>([]);
    const [dataForm, setDataForm] = useState<IDataForm>({});
    const [id, setId] = useState<string | number | undefined>();
    const [currentPage, setcurrentPage] = useState(1);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const [loader, setLoader] = useState(false);

    const itemsPerPage = 3;
    const pageNumberLimit = 5;
    const renderData = (data: any) => {
        return (
            data?.map((item: noticiaProps, index: number) => {
                return (
                    <div key={index}>
                        <CardNoticia
                            data={item}
                            onClick={() => console.log("Onclick")}
                            onClickDelete={() => handleDelete(item?.id)}
                            onClickEdit={() => handleEdit(item?.id)}
                        />
                    </div>
                )
            })
        );
    };

    const handleClick = (event: any) => {
        setcurrentPage(Number(event.target.id));
    };

    const filterNews = (data: Array<noticiaProps>) => {

        const compareDate = compararDatas(dataForm.periodo_inicio_vigencia, dataForm.periodo_fim_vigencia, data);
        const filterSearch = searchFilter(dataForm?.search_news, compareDate);
        const filter = tipoFilter(dataForm?.tipo_noticia_id, filterSearch);

        return filter;
    }

    const pages = [];
    for (let i = 1; i <= Math.ceil(filterNews(dataNoticias).length / itemsPerPage); i++) {
        pages.push(i);
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filterNews(dataNoticias).slice(indexOfFirstItem, indexOfLastItem);

    const renderPageNumbers = pages.map((number: any) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            const active = currentPage === number ? "bg-blue-dark-mega-imoveis text-white" : 'bg-white text-blue-dark-mega-imoveis';
            return (
                <div
                    key={number}
                    id={number}
                    onClick={handleClick}
                    className={`p-2 border-blue-dark-mega-imoveis border-2 font-semibold cursor-pointer rounded-lg w-10 h-10 flex justify-center items-center ${active}`}
                >
                    {number}
                </div>
            );
        } else {
            return null;
        }
    });

    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);

        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);

        if ((currentPage - 1) % pageNumberLimit === 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };

    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <div className="flex flex-row gap-1 justify-center items-center" onClick={handleNextbtn}>
            <div className="rounded-full bg-blue-dark-mega-imoveis w-1 h-1" />
            <div className="rounded-full bg-blue-dark-mega-imoveis w-1 h-1" />
            <div className="rounded-full bg-blue-dark-mega-imoveis w-1 h-1" />
        </div>;
    }

    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <div className="flex flex-row gap-1 justify-center items-center" onClick={handlePrevbtn}>
            <div className="rounded-full bg-blue-dark-mega-imoveis w-1 h-1" />
            <div className="rounded-full bg-blue-dark-mega-imoveis w-1 h-1" />
            <div className="rounded-full bg-blue-dark-mega-imoveis w-1 h-1" />
        </div>;
    }

    // const handleLoadMore = () => {
    //     setitemsPerPage(itemsPerPage + 5);
    // };

    // const history = useHistory();

    const closeModal = (): void => {
        setOpenModalDelete(false);
        setOpen(false);
        setId(undefined);
    }

    const handleDelete = (id: string | number | undefined) => {
        setOpenModalDelete(true);
        setId(id);
    }

    const handleEdit = (id: string | number | undefined) => {
        setOpen(true);
        setId(id);
    }

    const getNoticias = async () => {
        try {
            setLoader(true);
            const { data } = await axiosApi.get('/noticias');
            setdataNoticias(data?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    const getTiposNoticias = async () => {
        try {
            setLoader(true);
            const { data } = await axiosApi.get('/tipo-noticia');
            const tipoNoticia = data.data.map((el: TipoNoticias) => ({
                label: el.descricao,
                value: el.id,
            }));
            setTiposNoticias(tipoNoticia);
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    const onChangeSelect = (item: any, action: any) => {
        setDataForm({
            ...dataForm,
            [action.name]: item.value
        })
    }

    const onChange = (event: any) => {
        const { name, value } = event.target;
        setDataForm({
            ...dataForm,
            [name]: value
        })
    };

    useEffect(() => {
        getNoticias();
        getTiposNoticias();
    }, []);


    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
                <ModalDelete
                    modalId={id}
                    open={openModalDelete}
                    titulo={`Tem certeza que deseja excluir?`}
                    nameRouter={'noticias'}
                    isClose={closeModal}
                    requestData={getNoticias}
                />
                <Modal
                    title={`Notícia`}
                    isOpen={open}
                    isClose={closeModal}
                    modalId={id}
                    titleColor={`text-black`}
                    preTitleColor={`text-black`}
                    widthModal={`75%`}
                >
                    <EditNoticia
                        noticiaId={id}
                        isClose={closeModal}
                    />
                </Modal>
                {/* <Header
                    titulo="Notícias"
                    withButtom={true}
                    classNameButtom={`font-semibold my-4 mr-12 p-4 text-sm uppercase focus:outline-none text-white rounded-lg`}
                    contentButtom={`nova notícia`}
                    onClick={() => history.push("/noticias/nova-noticia")}
                /> */}
                <div className="p-8">
                    <div className="relative">
                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                        </div>
                        <input
                            name={`search_news`}
                            className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder={'Pesquise por alguma notícia'}
                            value={dataForm?.search_news ?? ""}
                            onChange={onChange}
                        />
                    </div>
                    <div className="flex flex-col md:flex-row sm:flex-col py-3 gap-2">
                        <div className="flex w-full lg:w-3/3 mb-12 lg:mb-0 flex-col lg:flex-row gap-2">
                            <InputSelect
                                name={`tipo_noticia_id`}
                                options={tiposNoticias}
                                className={`bg-white w-full text-gray-900 text-sm border-gray-300 border rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2`}
                                colClassname={`w-full`}
                                onChange={onChangeSelect}
                                placeholder={"Tipo"}
                                value={tiposNoticias?.filter((option) => (option?.value === dataForm?.tipo_noticia_id))}
                            />
                            <Inputlabel
                                type={typeInicio}
                                className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 p-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full`}
                                colClassname={`w-full`}
                                name={"periodo_inicio_vigencia"}
                                placeholder={`Período Início Vigência`}
                                onFocus={() => setTypeInicio("date")}
                                value={dataForm?.periodo_inicio_vigencia ?? ""}
                                onChange={onChange}
                                titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
                            />
                            <Inputlabel
                                type={typeFim}
                                className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4`}
                                colClassname={`w-full`}
                                name={"periodo_fim_vigencia"}
                                placeholder={`Período Fim Vigência`}
                                onFocus={() => setTypeFim("date")}
                                value={dataForm?.periodo_fim_vigencia ?? ""}
                                onChange={onChange}
                                titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
                            />
                        </div>
                    </div>
                    {renderData(currentItems)}
                    <div className="flex flex-row space-x-4 justify-center mt-10">
                        {pages.length > 0 ? <div>
                            <button
                                onClick={handlePrevbtn}
                                disabled={currentPage === pages[0] ? true : false}
                                className={`flex h-10 p-2 uppercase font-semibold border-blue-dark-mega-imoveis bg-blue-dark-mega-imoveis text-white border-2 cursor-pointer rounded-lg justify-center items-center`}
                            >
                                Anterior
                            </button>
                        </div> : ''}
                        {pageDecrementBtn}
                        {renderPageNumbers}
                        {pageIncrementBtn}
                        {pages.length > 0 ? <div>
                            <button
                                onClick={handleNextbtn}
                                disabled={currentPage === pages[pages.length - 1] ? true : false}
                                className={`flex h-10 p-2 focus:outline-none uppercase font-semibold border-blue-dark-mega-imoveis bg-blue-dark-mega-imoveis text-white border-2 cursor-pointer rounded-lg justify-center items-center`}
                            >
                                próximo
                            </button>
                        </div> : ''}
                    </div>
                </div>
            </Loader>
        </Fragment>
    );
}

export default Noticias;