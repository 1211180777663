import React from 'react';
import Header from '../Universal/HeaderPDF';
import Footer from '../Universal/FooterPDF';
import Linha from '../Universal/Linha';
import ModeloOutros1 from './OutrasInfo/ModeloOutros1';
import { mask } from 'src/helpers/functions';
import { formatarValor } from 'src/components/Basicos/funcoes';

interface ModeloProps {
    locatario?: any
}

const Modelo1: React.FC<ModeloProps> = ({ locatario }) => {
    return (
        <>
            {/* Página 1 */}
            <div className='pagina-pdf'>
                <Header
                    tipo='Locatário'
                    nome={locatario?.informacoes_basicas?.nome_cliente}
                    tipo_garantia={locatario?.garantia?.descricao}
                    status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                />
                <div className='body-pdf'>
                    <h1 className='sub-title estilo-fonte-negrito'>
                        {locatario?.tipo_locatario_id == 1 ? "Identificação" : "Dados da Empresa"}
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <Linha
                        campo10={`${locatario?.tipo_locatario_id == 1 ? 'CPF' : 'CNPJ'}`} valor10={mask(locatario?.informacoes_basicas?.cpf, "cpf_cnpj")}
                        campo20='E-mail*' valor20={locatario?.informacoes_basicas?.email} />
                    <Linha
                        campo10={`${locatario?.tipo_locatario_id == 1 ? 'Nome' : 'Razão Social'}`} valor10={locatario?.informacoes_basicas?.nome_cliente}
                        campo20='Estado Civil*' valor20={locatario?.informacoes_basicas?.estado_civil?.descricao}
                        campo21='N° de Dependentes*' valor21={locatario?.informacoes_basicas?.qtd_dependentes} />
                    <h1 className='text-xs'>Documento de Identificação Oficial com Foto*</h1>
                    <Linha
                        campo10='RG Frente*' valor10={locatario?.informacoes_basicas?.rg_cnh_frente?.nome}
                        campo20='A Locação é por procuração?*' valor20={locatario?.procurador_id != null ? 'Sim' : 'Não'} />
                    <Linha
                        campo10='RG Verso*' valor10={locatario?.informacoes_basicas?.rg_cnh_verso?.nome}
                        campo20='Garantia Locatícia*' valor20={locatario?.garantia?.descricao} />
                    <Linha
                        campo10='Telefone 1*' valor10={mask(locatario?.informacoes_basicas?.telefone1, "telefone")}
                        campo11='Telefone 2' valor11={mask(locatario?.informacoes_basicas?.telefone2, "telefone")} />

                    <div className='borda-principal-pdf mt-4'></div>

                    <h1 className='sub-title estilo-fonte-negrito'>
                        Cônjuge
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <Linha
                        campo10='Nome*' valor10={locatario?.conjuge?.informacoes_basicas?.nome_cliente}
                        campo20='CPF*' valor20={mask(locatario?.conjuge?.informacoes_basicas?.nome_cliente, "cpf_cnpj")} />
                    <Linha
                        campo10='Telefone 1*' valor10={locatario?.conjuge?.informacoes_basicas?.telefone1}
                        campo11='Telefone 2' valor11={locatario?.conjuge?.informacoes_basicas?.telefone2} campo20='E-mail*' valor20={locatario?.conjuge?.informacoes_basicas?.email} />
                    <h1 className='text-xs'>Documento de Identificação Oficial com Foto*</h1>
                    <Linha
                        campo10='RG Frente*' valor10={locatario?.conjuge?.informacoes_basicas?.rg_cnh_frente?.nome} />
                    <Linha
                        campo10='RG Verso*' valor10={locatario?.conjuge?.informacoes_basicas?.rg_cnh_verso?.nome} />
                    <Linha
                        campo10='Tipo de Atividade Profissional*' valor10={locatario?.conjuge?.atividade_profissional?.tipo_atividade?.descricao}
                        campo11='Renda Mensal*' valor11={formatarValor(locatario?.conjuge?.atividade_profissional?.renda_mensal)} isEndereco2={true}
                        campo20='Logradouro' valor20={locatario?.conjuge?.atividade_profissional?.endereco?.logradouro ?? null}
                        campo21='Número' valor21={locatario?.conjuge?.atividade_profissional?.numero ?? null} />
                    <Linha
                        campo10='Nome da Empresa' valor10={locatario?.conjuge?.atividade_profissional?.nome_empresa}
                        campo20='Complemento' valor20={locatario?.conjuge?.atividade_profissional?.endereco?.complemento ?? null} />
                </div>

                <div className='footer-pdf'>
                    <Footer />
                </div>
            </div>

            {/* Página 2 */}
            <div className='pagina-pdf'>
                <Header
                    tipo='Locatário'
                    nome={locatario?.informacoes_basicas?.nome_cliente}
                    tipo_garantia={locatario?.garantia?.descricao}
                    status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                />
                <div className='body-pdf'>
                    <Linha
                        campo10='Tempo de Empresa' valor10={locatario?.conjuge?.atividade_profissional?.tempo_empresa?.descricao}
                        campo11='Cargo Atual' valor11={locatario?.conjuge?.atividade_profissional?.cargo_atual}
                        campo20='Bairro' valor20={locatario?.conjuge?.atividade_profissional?.endereco?.bairro} />
                    <Linha
                        campo10='CEP' valor10={mask(locatario?.conjuge?.atividade_profissional?.endereco?.cep, "cep")}
                        campo20='Cidade' valor20={locatario?.conjuge?.atividade_profissional?.endereco?.cidade}
                        campo21='Estado' valor21={locatario?.conjuge?.atividade_profissional?.endereco?.estado} />
                    <Linha
                        campo10='Último Extrato do Cartão de Crédito*' valor10={locatario?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome} />
                    <h1 className='text-xs'>Documento de Identificação Oficial com Foto*</h1>
                    <Linha
                        campo10='Contracheque' valor10={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.nome}
                        campo20='Contracheque 2' valor20={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.nome} />
                    <Linha
                        campo10='Contracheque 3' valor10={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.nome}
                        campo20='Imposto de Renda' valor20={locatario?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.nome} />
                    <Linha
                        campo10='CTPS' valor10={locatario?.conjuge?.atividade_profissional?.arquivo_ctps?.nome}
                        campo20='Extrato Bancário' valor20={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.nome} />
                    <Linha
                        campo10='Extrato Bancário 2' valor10={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.nome}
                        campo20='Extrato Bancário 3' valor20={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.nome} />

                    <div className='borda-principal-pdf mt-4'></div>

                    <h1 className='sub-title estilo-fonte-negrito'>
                        Residência Atual
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <Linha
                        campo10='Sua Residência é*' valor10={locatario?.residencia_atual?.tipo_residencia?.descricao}
                        campo11={`${locatario?.residencia_atual?.tipo_residencia?.id == 2 ? "Valor Aluguel" : ""}`} valor11={`${locatario?.residencia_atual?.tipo_residencia?.id == 2 ? `${locatario?.residencia_atual?.valor_aluguel}` : ""}`}
                        campo20='Complemento' valor20={locatario?.endereco?.complemento} />
                    <Linha
                        campo10='CEP' valor10={mask(locatario?.endereco?.cep, "cep")}
                        campo20='Bairro*' valor20={locatario?.endereco?.bairro} />
                    <Linha
                        campo10='Comprovante de Endereço' valor10={locatario?.residencia_atual?.arquivo?.nome}
                        campo20='Cidade' valor20={locatario?.endereco?.cidade}
                        campo21='Estado' valor21={locatario?.endereco?.estado} />
                    <Linha isEndereco1={true}
                        campo10='Logradouro*' valor10={locatario?.endereco?.logradouro}
                        campo11='Numero*' valor11={locatario?.endereco?.numero} />

                    <div className='borda-principal-pdf mt-4'></div>

                    <h1 className='sub-title estilo-fonte-negrito'>
                        Atividade Profissional
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <Linha
                        campo10='Tipo de Atividade Profissional*' valor10={locatario?.atividade_profissional?.tipo_atividade?.descricao}
                        campo11='Renda Mensal*' valor11={formatarValor(locatario?.atividade_profissional?.renda_mensal, "dinheiro") ?? null} isEndereco2={true}
                        campo20='Logradouro' valor20={locatario?.atividade_profissional?.endereco?.logradouro ?? null}
                        campo21='Número' valor21={locatario?.atividade_profissional?.endereco?.numero ?? null} />
                </div>

                <div className='footer-pdf'>
                    <Footer />
                </div>
            </div>

            {/* Página 3 */}
            <div className='pagina-pdf'>
                <Header
                    tipo='Locatário'
                    nome={locatario?.informacoes_basicas?.nome_cliente}
                    tipo_garantia={locatario?.garantia?.descricao}
                    status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                />
                <div className='body-pdf'>
                    <Linha
                        campo10='Nome da Empresa' valor10={locatario?.atividade_profissional?.nome_empresa}
                        campo20='Complemento' valor20={locatario?.atividade_profissional?.endereco?.complemento ?? null} />
                    <Linha
                        campo10='Tempo de Empresa' valor10={locatario?.atividade_profissional?.tempo_empresa?.descricao ?? null}
                        campo11='Cargo Atual' valor11={locatario?.atividade_profissional?.cargo_atual ?? null}
                        campo20='Bairro' valor20={locatario?.atividade_profissional?.endereco?.bairro ?? null} />
                    <Linha
                        campo10='CEP' valor10={mask(locatario?.atividade_profissional?.endereco?.cep, "cep") ?? null}
                        campo20='Cidade' valor20={locatario?.atividade_profissional?.endereco?.cidade ?? null}
                        campo21='Estado' valor21={locatario?.atividade_profissional?.endereco?.estado ?? null} />
                    <Linha
                        campo10='Último Extrato do Cartão de Crédito*' valor10={locatario?.atividade_profissional?.arquivo_extrato_bancario?.nome}
                        campo20='Imposto de Renda' valor20={locatario?.atividade_profissional?.arquivo_imposto_renda?.nome} />

                    <div className='borda-principal-pdf mt-4'></div>

                    <h1 className='sub-title estilo-fonte-negrito'>
                        Dados do Procurador
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <Linha
                        campo10='Nome do Procurador*' valor10={locatario?.procurador?.informacao_basica?.nome_cliente}
                        campo20='CPF*' valor20={mask(locatario?.procurador?.informacao_basica?.cpf, 'cpf_cnpj') ?? null} />
                    <Linha
                        campo10='Telefone 1*' valor10={mask(locatario?.procurador?.informacao_basica?.telefone1, 'telefone') ?? null}
                        campo11='Telefone 2' valor11={mask(locatario?.procurador?.informacao_basica?.telefone2, 'telefone') ?? null}
                        campo20='E-mail*' valor20={locatario?.procurador?.informacao_basica?.email ?? null} />
                    <h1 className='text-xs'>Documento de Identificação Oficial com Foto*</h1>
                    <Linha
                        campo10='RG Frente*' valor10={locatario?.procurador?.informacao_basica?.rg_cnh_frente?.nome ?? null}
                        campo20='Estado Civil' valor20={locatario?.procurador?.informacao_basica?.estado_civil?.descricao ?? null}
                        campo21='N° de Dependentes' valor21={locatario?.procurador?.informacao_basica?.qtd_dependentes ?? null} />
                    <Linha
                        campo10='RG Verso*' valor10={locatario?.procurador?.informacao_basica?.rg_cnh_verso?.nome ?? null}
                        campo20='Documento da Procuração' valor20={locatario?.procurador?.documento_procuracao?.nome ?? null} />
                    <Linha
                        campo10='CEP*' valor10={mask(locatario?.procurador?.endereco?.cep, 'cep') ?? null}
                        campo20='Bairro*' valor20={locatario?.procurador?.endereco?.bairro ?? null} />
                    <Linha isEndereco1={true}
                        campo10='Logradouro' valor10={locatario?.procurador?.endereco?.logradouro ?? null}
                        campo11='Número' valor11={locatario?.procurador?.endereco?.numero ?? null}
                        campo20='Cidade' valor20={locatario?.procurador?.endereco?.cidade ?? null}
                        campo21='Estado' valor21={locatario?.procurador?.endereco?.estado ?? null} />
                    <Linha
                        campo10='Complemento' valor10={locatario?.procurador?.endereco?.complemento ?? null} />

                    <div className='borda-principal-pdf mt-4'></div>

                </div>

                <div className='footer-pdf'>
                    <Footer />
                </div>
            </div>

            {/* Página 4 */}
            <ModeloOutros1 locatario={locatario} />
        </>
    );
};

export default Modelo1;