import React, { Fragment, useEffect, useState } from "react";
import iconAnalise from "src/assets/icones/icon-analise.svg"
import CardTimeLapse from "../../Cards/CardTimeLapse";
import { AccordionTimeLine } from "../../AccordionTimeLine";
import iconCrownFill from "src/assets/icones/icon-crown-fill.svg"
import iconCrown from "src/assets/icones/icon-crown.svg"
import { alertMsg } from "src/helpers/functions";

export type Props = {
    imovelId?: number;
    timeline?: any;
    setStatusTimeLine?: any,
    setEtapaAtual?: any,
    setStateLinkImovel?: any,
    etapaAtual?: number,
    comentario?: any,
    setValueOption?: any,
    imovel?: any,
    isPrincipal?: boolean
}

export default function TimelimeCadastroImovel({
    imovelId,
    timeline,
    setStatusTimeLine,
    setEtapaAtual,
    setStateLinkImovel,
    comentario,
    etapaAtual,
    imovel,
    isPrincipal = false
}: Props): JSX.Element {
    const statusWithButton = [1, 3, 9];
    const [dataOption, setDataOption] = useState();
    const optionsAvaliacaoImovel: Array<any> = [
        { value: 10, label: "Realizado" },
        { value: 11, label: "Não Realizado" },
    ];

    const condition = (comentario === null || comentario === "" || comentario === " " || comentario === undefined);

    useEffect(() => {
        if (dataOption !== undefined) {
            if (etapaAtual === 8 && condition && dataOption === 10) {
                alertMsg("error", "Adicione o link do imóvel publicado");
            } else {
                setStatusTimeLine(dataOption, imovelId);
            }
        }
    }, [dataOption]);

    // useEffect(() => {
    //     if (dataOption !== undefined) {
    //         setStatusTimeLine(dataOption, imovelId);
    //     }
    // }, [dataOption]);

    return (
        <Fragment>
            <div className="flex flex-col w-full scale-150">
                <AccordionTimeLine
                    disable={isPrincipal}
                    data={imovel}
                    type={'imovel'}
                >
                    <div className={`flex flex-col p-4  ${isPrincipal ? 'bg-gray-ligth-system-mega-imoveis' : 'bg-white'} rounded-xl gap-2`}>
                        {isPrincipal ? <div className="flex flex-row justify-between font-mont-bold">
                            <div className={`flex ml-auto h-16 w-16 items-center justify-center p-1 focus:outline-none `} onClick={() => { }}>
                                <img src={!isPrincipal ? iconCrown : iconCrownFill} className={`inset-0 ${!isPrincipal ? 'h-8 w-8' : 'h-12 w-12'} object-center`} alt="icon_crown" />
                            </div>
                        </div> : ""}
                        <div className="flex flex-col justify-center items-center md:flex-row gap-4 w-full md:justify-between">
                            <CardTimeLapse
                                etapa={1}
                                image={iconAnalise}
                                itsButton={isPrincipal}
                                isIcon={false}
                                cadastroId={imovelId}
                                titleCard={'Cadastro Recebido'}
                                nextPathName={'view'}
                                status={timeline?.[1]?.status_id}
                                subTitleCard={timeline?.[1]?.updated_at ?? null}
                                active={timeline?.[1]?.status !== null}
                                buttonTitle={'visualizar cadastro'}
                                width={'w-48'}
                            />
                            <CardTimeLapse
                                etapa={3}
                                etapaName={'2'}
                                color={'bg-orange-500'}
                                colorText={'text-orange-500'}
                                status={timeline?.[3]?.status_id}
                                nextPathName={'analise-cadastro'}
                                image={iconAnalise}
                                itsButton={isPrincipal ? statusWithButton.includes(timeline?.[3]?.status_id) : false}
                                cadastroId={imovelId}
                                motivo={timeline?.[3]?.motivo ?? null}
                                buttonTitle={'Analisar cadastro'}
                                titleCard={'Análise do Cadastro'}
                                subTitleCard={timeline?.[3]?.updated_at ?? null}
                                active={timeline?.[3]?.status !== null}
                                isIcon={true}
                                width={'w-48'}

                            />
                            <CardTimeLapse
                                etapa={7}
                                etapaName={'3'}
                                status={timeline?.[7]?.status_id}
                                image={iconAnalise}
                                cadastroId={imovelId}
                                itsButton={false}
                                motivo={timeline?.[7]?.motivo ?? null}
                                titleCard={'Imóvel em Avaliação'}
                                itsButtonDropDown={timeline?.[7]?.status_id !== 10}
                                options={optionsAvaliacaoImovel}
                                subTitleCard={timeline?.[7]?.updated_at ?? null}
                                active={timeline?.[7]?.status !== null}
                                setStateOption={setDataOption}
                                setStateEtapa={setEtapaAtual}
                                currentValue={timeline?.[7]?.status_id ?? null}
                                width={'w-48'}
                                disponivelAlterar={true}

                            />
                            <CardTimeLapse
                                etapa={6}
                                etapaName={'4'}
                                status={timeline?.[6]?.status_id}
                                image={iconAnalise}
                                cadastroId={imovelId}
                                itsButton={false}
                                motivo={timeline?.[6]?.motivo ?? null}
                                titleCard={'Assinatura do Contrato'}
                                itsButtonDropDown={timeline?.[6]?.status_id !== 10}
                                options={optionsAvaliacaoImovel}
                                subTitleCard={timeline?.[6]?.updated_at ?? null}
                                active={timeline?.[6]?.status !== null}
                                setStateOption={setDataOption}
                                setStateEtapa={setEtapaAtual}
                                currentValue={timeline?.[6]?.status_id ?? null}
                                width={'w-48'}
                                disponivelAlterar={true}
                            />
                            <CardTimeLapse
                                etapa={8}
                                etapaName={'5'}
                                status={timeline?.[8]?.status_id}
                                image={iconAnalise}
                                itsButton={false}
                                cadastroId={imovelId}
                                linkImovel={timeline?.[8]?.motivo ?? comentario}
                                itsButtonDropDown={timeline?.[8]?.status_id !== 10}
                                options={optionsAvaliacaoImovel}
                                titleCard={'Imóvel Publicado'}
                                subTitleCard={timeline?.[8]?.updated_at ?? null}
                                active={timeline?.[8]?.status !== null}
                                setStateOption={setDataOption}
                                setStateEtapa={setEtapaAtual}
                                currentValue={timeline?.[8]?.status_id ?? null}
                                itsInput={timeline?.[8]?.status !== null}
                                setStateLinkImovel={setStateLinkImovel}
                                width={'w-48'}
                                disponivelAlterar={true}
                            />
                        </div>
                    </div>
                </AccordionTimeLine>
            </div>
        </Fragment>
    );
}