import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ButtonGroup from 'src/components/Basicos/ButtonGroup';
import Title from 'src/components/Basicos/Form/Title';

const BarChartHorizontal = ({ title, buttons = false, data = [] }) => {
  const [period, setPeriod] = useState(0);

  const handleOnClick = (event) => {
    setPeriod(event.target.value);
  };

  return (
    <div className='flex flex-col h-full'>
      <div className="flex w-full justify-between pr-5 py-5">
        <Title
          className={`text-2xl font-bold font-mont-bold text-gray-system-mega-imoveis`}
          content={title} />
        {buttons ? <ButtonGroup
          buttons={["Últimos 7 dias", "Últimos 30 dias", "Últimos 12 meses"]}
          doSomethingAfterClick={handleOnClick}
        /> : ''}

      </div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={600}
          height={300}
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend iconType='circle' />
          <Bar dataKey="atendimento" name='Em atendimento' stackId="a" fill="#6271FF" barSize={100} />
          <Bar dataKey="pendente" name='Pendentes' stackId="a" fill="#FF7E62" barSize={100} />
        </BarChart>
      </ResponsiveContainer>
    </div>

  );
}

export default BarChartHorizontal;
