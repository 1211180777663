import React from 'react';

type Props = {
    campo10?: any,
    campo20?: any,
    campo11?: any,
    campo21?: any,
    campo22?: any,
    valor10?: any,
    valor20?: any,
    valor11?: any,
    valor21?: any,
    valor22?: any,
    isEndereco1?: any,
    isEndereco2?: any
};

const Linha = ({
    campo10,
    campo20,
    campo11,
    campo21,
    campo22,
    valor10,
    valor11,
    valor20,
    valor21,
    valor22,
    isEndereco1,
    isEndereco2
}: Props) => {
    return (
        <div className='flex linha-completa-pdf'>
            {isEndereco1 && (
                <div className='campo-completo-pdf flex'>
                    <div className='campo-completo-endereco-pdf'>
                        <h1 className='mb-2'>{campo10}</h1>
                        <div className='campo-pdf'>
                            <h1 className='ml-2'>{valor10}</h1>
                        </div>
                    </div>
                    {campo11 != undefined && (
                        <div className='campo-completo-numero-pdf'>
                            <h1 className='mb-2'>{campo11}</h1>
                            <div className='campo-pdf'>
                                <h1 className='ml-2'>{valor11}</h1>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {!isEndereco1 && campo11 == undefined && campo10 != undefined && (
                <div className='campo-completo-pdf'>
                    <h1 className='mb-2'>{campo10}</h1>
                    <div className='campo-pdf'>
                        <h1 className='ml-2'>{valor10}</h1>
                    </div>
                </div>
            )}

            {!isEndereco1 && campo11 != undefined && campo10 != undefined && (
                <div className='campo-completo-pdf flex'>
                    <div className='campo-completo-pdf'>
                        <h1 className='mb-2'>{campo10}</h1>
                        <div className='campo-pdf'>
                            <h1 className='ml-2'>{valor10}</h1>
                        </div>
                    </div>
                    <div className='campo-completo-pdf'>
                        <h1 className='mb-2'>{campo11}</h1>
                        <div className='campo-pdf'>
                            <h1 className='ml-2'>{valor11}</h1>
                        </div>
                    </div>
                </div>
            )}

            {isEndereco2 && (
                <div className='campo-completo-pdf flex'>
                    <div className='campo-completo-endereco-pdf'>
                        <h1 className='mb-2'>{campo20}</h1>
                        <div className='campo-pdf'>
                            <h1 className='ml-2'>{valor20}</h1>
                        </div>
                    </div>
                    {campo21 != undefined && (
                        <div className='campo-completo-numero-pdf'>
                            <h1 className='mb-2'>{campo21}</h1>
                            <div className='campo-pdf'>
                                <h1 className='ml-2'>{valor21}</h1>
                            </div>
                        </div>
                    )}
                    {campo22 != undefined && (
                        <div className='campo-completo-3-pdf'>
                            <h1 className='mb-2'>{campo22}</h1>
                            <div className='campo-pdf'>
                                <h1 className='ml-2'>{valor22}</h1>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {!isEndereco2 && campo22 != undefined && campo20 != undefined && campo21 != undefined && (
                <div className='campo-completo-pdf flex'>
                    <div className='campo-completo-3-pdf'>
                        <h1 className='mb-2'>{campo20}</h1>
                        <div className='campo-pdf'>
                            <h1 className='ml-2'>{valor20}</h1>
                        </div>
                    </div>
                    <div className='campo-completo-3-pdf'>
                        <h1 className='mb-2'>{campo21}</h1>
                        <div className='campo-pdf'>
                            <h1 className='ml-2'>{valor21}</h1>
                        </div>
                    </div>
                    <div className='campo-completo-3-pdf'>
                        <h1 className='mb-2'>{campo22}</h1>
                        <div className='campo-pdf'>
                            <h1 className='ml-2'>{valor22}</h1>
                        </div>
                    </div>
                </div>
            )}

            {!isEndereco2 && campo20 == undefined && campo10 == undefined && campo11 == undefined && campo21 == undefined && (
                <></>
            )}

            {!isEndereco2 && campo20 != undefined && campo21 == undefined && (
                <div className='campo-completo-pdf'>
                    <h1 className='mb-2'>{campo20}</h1>
                    <div className='campo-pdf'>
                        <h1 className='ml-2'>{valor20}</h1>
                    </div>
                </div>
            )}

            {!isEndereco2 && campo20 != undefined && campo21 != undefined && campo22 == undefined && (
                <div className='campo-completo-pdf flex'>
                    <div className='campo-completo-pdf'>
                        <h1 className='mb-2'>{campo20}</h1>
                        <div className='campo-pdf'>
                            <h1 className='ml-2'>{valor20}</h1>
                        </div>
                    </div>
                    <div className='campo-completo-pdf'>
                        <h1 className='mb-2'>{campo21}</h1>
                        <div className='campo-pdf'>
                            <h1 className='ml-2'>{valor21}</h1>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Linha;