import React, { useEffect, useState } from 'react';
import { axiosApi } from 'src/services/axiosInstance';
import { Accordion, AccordionItem } from 'react-accessible-accordion';
import './style.css';
import { FiEye } from 'react-icons/fi';
import Loader from 'src/components/Basicos/Loader';
import { MdDeleteOutline } from 'react-icons/md';
import SlideButton from 'src/components/SlideButton';
import ModalDelete from 'src/components/Funcionalidades/ModalDelete';

type Grupo = {
    id: any,
    descricao: String,
    status: boolean
}

const GruposUsuarios: React.FunctionComponent = () => {
    const [grupos, setGrupos] = useState<any>();
    const [loader, setLoader] = useState<boolean>(true);
    const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
    const [id, setId] = useState<string | number | undefined>();

    const getGrupos = async () => {
        try {
            const { data } = await axiosApi.get('grupos');
            setGrupos(data.data)
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    useEffect(() => {
        getGrupos();
    }, []);

    const onChange = async (event: any) => {
        grupos.find((element: any) => element.id == event.target.id).status = event.target.checked
        var status = {
            status: event.target.checked == true ? 1 : 0
        }
        setLoader(true);
        await axiosApi.put(`grupos/${event.target.id}`, status)
        setLoader(false);
        setGrupos(() => setGrupos(grupos))
    }

    const closeModal = (): void => {
        setOpenModalDelete(false);
        setId(undefined);
    }

    const onClickDelete = (id: any) => {
        setOpenModalDelete(true);
        setId(id);
    }

    return (
        <Loader isActive={loader}>
            <ModalDelete
                modalId={id}
                open={openModalDelete}
                titulo={`Tem certeza que deseja excluir?`}
                nameRouter={'grupos'}
                isClose={closeModal}
                requestData={getGrupos}
            />
            <Accordion>
                <div className='accordion'>
                    {grupos?.map((grupo: Grupo) => {
                        return (
                            <div className='item-accordion'>
                                <AccordionItem>
                                    <div className='title-accordion estilo-fonte-negrito'>
                                        {grupo?.descricao}
                                    </div>
                                    <div className='icon-accordion'>
                                        <a className="flex h-10 w-10 items-center justify-center text-gray-system-mega-imoveis bg-gray-ligth-system-mega-imoveis p-2 focus:outline-none rounded-xl" href={`/auxiliares/grupos-usuarios/permissoes?${grupo?.id}`}>
                                            <FiEye size={'1.3rem'} className={`text-gray-system-mega-imoveis`} />
                                        </a>
                                        <a id={grupo?.id} className="flex h-10 w-10 items-center justify-center text-gray-system-mega-imoveis bg-gray-ligth-system-mega-imoveis p-2 focus:outline-none rounded-xl cursor-pointer" onClick={() => onClickDelete(grupo?.id)}>
                                            <MdDeleteOutline id={grupo?.id} size={'1.3rem'} className={`text-gray-system-mega-imoveis event-none`} />
                                        </a>
                                        <SlideButton
                                            id={grupo?.id}
                                            name=""
                                            onChange={onChange}
                                            checked={grupo?.status} />
                                    </div>
                                </AccordionItem>
                            </div>
                        )
                    })}
                </div>
            </Accordion>
        </Loader>
    );
}

export default GruposUsuarios;