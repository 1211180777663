import React, { Fragment, useEffect, useState } from "react";
// import { BiChevronDown } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import iconAnalise from "src/assets/icones/icon-analise.svg"
import iconAprovado from "src/assets/icones/icon-aprovado.svg"
import iconReprovado from "src/assets/icones/icon-reprovado.svg"
import iconPositive from "src/assets/icones/icon-positive.svg"
import iconNegative from "src/assets/icones/icon-negative.svg"
import iconReloadApi from "src/assets/icones/icon-reload-api.svg"
import iconReloadApiCinza from "src/assets/icones/refresh-ccw.png"
import ModalAnaliseFinanceira from "src/components/Funcionalidades/ModalAnaliseFinanceira";
import ButtonWithDropDownCmp from "../../DropDown";
import { converterDataBr } from "../../funcoes";
import './style.css';
import { useControlContextAnaliseFinanceira } from "src/contexts/AnaliseFinanceiraContext";
import { alertMsg } from "src/helpers/functions";
import ModalApi from "src/components/Funcionalidades/ModalApi";
import { useControlContextMobile } from "src/contexts/MobileContext";
import ModalAnaliseFinanceiraMobile from "src/components/Funcionalidades/ModalAnaliseFinanceira/indexMobile";
import ModalApiMobile from "src/components/Funcionalidades/ModalApi/indexMobile";
import CheckBox from "../../Form/CheckBox";
import { axiosApi } from "src/services/axiosInstance";


export default function CardTimeLapse({
    etapa,
    cadastroId,
    subTitleCard,
    titleCard,
    active = false,
    buttonTitle,
    isIcon = true,
    messageWarning,
    message = false,
    motivo = null,
    itsButton = true,
    status,
    nextPathName = '',
    linkTo = null,
    options = [],
    itsButtonDropDown = false,
    setStateOption,
    setStateEtapa,
    currentValue,
    linkImovel = null,
    itsInput = false,
    setStateLinkImovel,
    statusTimeline = true,
    conjugeId,
    locatario,
    estiloCard,
    estiloCardConjuge,
    analiseFinanceira,
    fiador,
    timeline,
    disponivelAlterar,
    width = "w-40",
    minHeight = "17rem",
    etapaName = null,
    cancelado
}: any): JSX.Element {
    const history = useHistory();
    const [modal, setModal] = useState<boolean>(false);
    const [modalMobile, setModalMobile] = useState<boolean>(false);
    const [modalApi, setModalApi] = useState<boolean>(false);
    const [modalApiMobile, setModalApiMobile] = useState<boolean>(false);
    const { setAnaliseFinanceira, setAnaliseFinanceiraConjuge } = useControlContextAnaliseFinanceira();
    const { mobile } = useControlContextMobile();
    const [usuario, setUsuario] = useState<any>();
    const [checkCancelado, setCheckCancelado] = useState<boolean>(false);

    const onchange = (e: any) => {
        const { value } = e.target;
        setStateLinkImovel(value);
    };

    const changeCancelado = async () => {
        if (!checkCancelado && usuario?.grupo_id == 1 || usuario?.grupo_id == 3) {
            setCheckCancelado(!checkCancelado);
            await axiosApi.put(`revisado-diretoria/${locatario.id}`);
        }
    }

    useEffect(() => {
        var usuarioLogado = localStorage.getItem('usuario');
        var user = typeof usuarioLogado == 'string' ? JSON.parse(usuarioLogado) : 'nada';
        setCheckCancelado(timeline?.[4]?.revisado_diretoria);
        setUsuario(user)
    }, []);

    // 1 - Pendente
    // 2 - Aguadando Reenvio
    // 3 - Reenviado 
    // 4 - Aprovado 
    // 5 - Reprovado
    // 9 - Em análise
    const statusCard = (status: number) => {
        switch (status) {
            case 1:
                return {
                    title: 'Pendente',
                    color: active ? '#FFD80E' : '#E6E6E6',
                    icon: iconAnalise
                }
            case 2:
                return {
                    title: 'Aguardando reenvio',
                    color: active ? '#FFC189' : '#E6E6E6',
                    icon: iconAnalise
                }
            case 3:
                return {
                    title: 'Reenviado',
                    color: active ? '#FF820E' : '#E6E6E6',
                    icon: iconAnalise
                }
            case 4:
                return {
                    title: 'Aprovado',
                    color: active ? '#0CB75A' : '#E6E6E6',
                    icon: iconAprovado
                }
            case 5:
                return {
                    title: 'Reprovado',
                    color: active ? '#FF3B3B' : '#E6E6E6',
                    icon: iconReprovado
                }
            case 7:
                return {
                    title: 'Cadastro pré-aprovado',
                    color: active ? '#0CB75A' : '#E6E6E6',
                    icon: iconAprovado
                }
            case 9:
                return {
                    title: 'Em análise',
                    color: active ? '#0A21FF' : '#E6E6E6',
                    icon: iconAnalise
                }
            case 10:
                return {
                    title: 'Realizado',
                    color: active ? '#0CB75A' : '#E6E6E6',
                    icon: iconAprovado
                }
            case 11:
                return {
                    title: 'Não Realizado',
                    color: active ? '#FF3B3B' : '#E6E6E6',
                    icon: iconAnalise
                }
            case 12:
                return {
                    title: 'Cancelado',
                    color: '#a6a2a2',
                    icon: iconAnalise
                }

            default:
                return {
                    title: 'Pendente',
                    color: '#E6E6E6',
                    icon: iconAnalise
                }
        }
    }

    const openModalAnalise = () => {
        if (analiseFinanceira == undefined)
            alertMsg("error", "Locatário com dados incompletos!");
        else {
            mobile ? setModalMobile(true) : setModal(true)
        }
    }

    const closeModalAnalise = () => {
        setAnaliseFinanceira(undefined)
        setAnaliseFinanceiraConjuge(undefined)
        setModal(false)
        setModalMobile(false)
    }

    const openModalApi = () => {
        if (mobile)
            setModalApiMobile(true)
        else
            setModalApi(true)
    }

    const closeModalApi = () => {
        if (mobile)
            setModalApiMobile(false)
        else
            setModalApi(false)
    }

    return (
        <Fragment key={etapa}>
            <ModalAnaliseFinanceira
                open={modal}
                locatario={locatario}
                fiador={fiador}
                analiseFinanceira={analiseFinanceira}
                timeline={timeline?.[2]}
                isClose={closeModalAnalise}
            />
            <ModalAnaliseFinanceiraMobile
                open={modalMobile}
                locatario={locatario}
                fiador={fiador}
                analiseFinanceira={analiseFinanceira}
                timeline={timeline?.[2]}
                isClose={closeModalAnalise}
            />
            <ModalApi
                open={modalApi}
                locatario={locatario}
                isClose={closeModalApi}
                fiador={fiador}
            />
            <ModalApiMobile
                isOpen={modalApiMobile}
                isClose={closeModalApi}
                closeOnOutsideClick={true}
                lockScroll={false}
                locatario={locatario}
                fiador={fiador}
            />
            <div className={`flex flex-col ${width} items-center`}>
                <span className={`font-mont-bold text-base mb-4 ${active ? 'text-blue-mega-imoveis' : 'text-gray-system-mega-imoveis'} ${statusTimeline ? '' : 'hidden'}`}>Etapa {etapaName ?? etapa}</span>
                <div className={`border w-full rounded-lg flex flex-col items-center py-2 p-2 ${statusTimeline ? 'shadow-sm bg-white' : 'shadow-md bg-gray-ligth-system-mega-imoveis'} ${itsButton && etapa != 3 ? 'justify-between' : etapa === 2 ? 'justify-between' : 'justify-start'}`} style={{ minHeight: `${minHeight}` }}>
                    <div className={`flex flex-col w-full mb-1 px-4 text-center items-center ${active ? 'text-black' : 'text-gray-ligth-system-mega-imoveis'}`}>
                        <span className={`font-mont-bold text-base ${statusTimeline ? '' : 'text-gray-system-mega-imoveis'}`}>{titleCard}</span>
                        <span className={`font-medium text-center text-xs w-4/5 ${statusTimeline ? '' : 'text-gray-system-mega-imoveis'}`}>{converterDataBr(subTitleCard)}</span>
                    </div>

                    {isIcon ? <div className={`flex flex-col w-full mb-1 text-center items-center rounded-lg border p-2 ${active ? '' : 'hidden'}`}>
                        <div className="flex flex-row w-full items-center justify-center">
                            <div className={`flex justify-center items-center rounded-full h-16 w-16`} style={{ backgroundColor: statusCard(status).color }}>
                                <img className="inset-0 h-10 w-10 object-center" alt="logo_analise" src={statusCard(status).icon} />
                            </div>
                            {disponivelAlterar == true ?
                                itsButtonDropDown && active && <div className="flex w-1 mr-2">
                                    <ButtonWithDropDownCmp
                                        optionsData={options.filter((option: any) => { return option?.value !== currentValue })}
                                        setStateOption={setStateOption}
                                        setStateEtapa={setStateEtapa}
                                        etapa={etapa}
                                    />
                                </div> : ""}
                        </div>
                        <div className={`text-center font-mont-bold text-lg mb-2`} style={{ color: statusCard(status).color }}>
                            <span>{statusCard(status).title}</span>
                        </div>
                    </div> : ''}

                    {timeline?.[4]?.id == 9 && etapa == 4 ?
                        <CheckBox
                            checked={usuario?.grupo_id == 1 || usuario?.grupo_id == 3 ? checkCancelado : timeline?.[4].revisado_diretoria}
                            funcao={changeCancelado}
                            label={'Revisado pela Diretoria'}
                            style={'mt-2'}
                        /> :
                        ""}

                    <div className={`flex flex-col w-full ${motivo === null ? 'hidden' : ''} text-gray-system-mega-imoveis items-start`}>
                        <span className="font-bold text-base text-left">Motivo</span>
                        <div className="scroll-motivo w-full flex-wrap overflow-y-auto bg-gray-ligth-system-mega-imoveis text-left p-3 text-gray-system-mega-imoveis rounded-xl" style={{ minHeight: '35px', maxHeight: '70px' }}>
                            {motivo}
                        </div>
                    </div>
                    <div className={`flex flex-col w-full ${itsInput ? '' : 'hidden'} text-gray-system-mega-imoveis items-start`}>
                        <span className="font-bold text-sm text-left">Link do Imóvel*</span>
                        <textarea
                            name={'link_imovel'}
                            wrap="soft"
                            onChange={onchange}
                            placeholder={'Insira o link do imóvel'}
                            value={linkImovel}
                            disabled={status === 10}
                            className="scroll-motivo flex w-full focus:outline-none resize-none bg-gray-ligth-system-mega-imoveis text-sm text-left p-3 text-gray-system-mega-imoveis rounded-xl"
                            style={{ minHeight: '35px', maxHeight: '80px' }} />
                    </div>

                    {timeline?.[2]?.status != null ?
                        etapa == 2 ?
                            <>
                                {estiloCard?.estilo1 == false ?
                                    <div className={`${timeline?.[2]?.menos_20_minutos ? 'locatario-analise-4' : 'locatario-analise-5'}`} onClick={() => timeline?.[2]?.menos_20_minutos ? null : openModalApi()}>
                                        {
                                            timeline?.[2]?.menos_20_minutos
                                                ?
                                                <>
                                                    <span className="text-base cor-api-nao-responde-2 estilo-fonte-negrito">Aguarde nova consulta</span>
                                                    <img className="icon-positive" alt="icon_crown" src={iconReloadApiCinza} />
                                                </>
                                                :
                                                <>
                                                    <span className="text-base cor-api-nao-responde estilo-fonte-negrito">Consultar Novamente</span>
                                                    <img className="icon-positive" alt="icon_crown" src={iconReloadApi} />
                                                </>
                                        }
                                    </div>
                                    :
                                    <>
                                        <div className={`locatario-analise-${estiloCard?.estilo2}`} onClick={openModalAnalise}>
                                            <span className="text-base">{estiloCard?.titulo}</span>
                                            {estiloCard?.estilo2 == '1'
                                                ? <img className="icon-positive" alt="icon_crown" src={iconPositive} />
                                                : <img className="icon-positive" alt="icon_crown" src={iconNegative} />
                                            }
                                        </div>
                                        {conjugeId != null ?
                                            <div className={`locatario-analise-${estiloCardConjuge?.estilo2}`} onClick={openModalAnalise}>
                                                <span className="text-base">Conjuge</span>
                                                {estiloCardConjuge?.estilo2 == '1'
                                                    ? <img className="icon-positive" alt="icon_crown" src={iconPositive} />
                                                    : <img className="icon-positive" alt="icon_crown" src={iconNegative} />
                                                }
                                            </div> : ''} </>
                                }
                            </> :
                            <div className={`flex w-full mb-1 px-4 text-center ${!message ? 'hidden' : ''} items-center`}>
                                <span className={`font-normal text-base`} style={{ color: statusCard(status).color }}>{messageWarning}</span>
                            </div> : ''
                    }

                    {etapa == 2
                        ? <a className="underline link-tjce" target="_blank" href={`https://consultaprocesso.tjce.jus.br/scpu-web/pages/administracao/consultaProcessual.jsf;jsessionid=rPhBxq3JyHHz7ymwuHUvPcDfM9cABYP8Mfq-jHwO.tjcpp02`}>Consultar TJ-CE</a>
                        : linkTo === null && active && etapa != 3 ?
                            <button onClick={() => cancelado == 2 && usuario?.grupo_id != 1 ? '' : history.push(`${cadastroId}/${nextPathName}`)}
                                className={`flex h-12 w-full p-2 ${cancelado == 2 && usuario?.grupo_id != 1 ? 'botao-bloqueado' : 'bg-blue-mega-imoveis text-white cursor-pointer'} ${!itsButton ? 'hidden' : ''} focus:outline-none mt-2 uppercase font-medium text-xs rounded-lg justify-center items-center`}
                            >
                                {buttonTitle}
                            </button> : disponivelAlterar == true && etapa == 3 || etapa == 3 && etapaName == 2
                                ? <button onClick={() => cancelado == 2 ? '' : history.push(`${cadastroId}/${nextPathName}`)}
                                    className={`flex h-12 w-full p-2 ${cancelado == 2 ? 'botao-bloqueado' : 'bg-blue-mega-imoveis text-white cursor-pointer'} ${!itsButton ? 'hidden' : ''} focus:outline-none mt-2 uppercase font-medium text-xs rounded-lg justify-center items-center`}
                                >
                                    {buttonTitle}
                                </button> : ''}

                    {linkTo !== null ? <a
                        href={linkTo}
                        target={'_blank'}
                        rel="noopener noreferrer"
                        className={`flex h-12 w-full p-2 ${cancelado == 2 ? 'botao-bloqueado' : 'bg-blue-mega-imoveis text-white cursor-pointer'} ${!itsButton ? 'hidden' : ''} focus:outline-none mt-2 uppercase font-medium text-xs rounded-lg justify-center items-center`}
                    >
                        {buttonTitle}
                    </a> : ''}
                </div>
            </div>
        </Fragment>
    );
}
