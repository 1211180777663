import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ButtonGroup from 'src/components/Basicos/ButtonGroup';
import ButtonGroupMobile from 'src/components/Basicos/ButtonGroup/indexMobile';
import Title from 'src/components/Basicos/Form/Title';
import './style.css'

const BarChartHorizontalMobile = ({ title, buttons = false, data = [] }) => {
  const [period, setPeriod] = useState(0);

  const handleOnClick = (event) => {
    setPeriod(event.target.value);
  };

  return (
    <div className='flex flex-col h-full'>
      <div className="flex w-full justify-between pr-5 py-5">
        <Title
          className={`text-2xl font-bold font-mont-bold text-gray-system-mega-imoveis`}
          content={title} />
      </div>
      {buttons ? <ButtonGroupMobile
        buttons={["Últimos 7 dias", "Últimos 30 dias", "Últimos 12 meses"]}
        doSomethingAfterClick={handleOnClick}
      /> : ''}
      <ResponsiveContainer className="container-mobile">
        <BarChart
          width={600}
          height={300}
          data={data[period]}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend iconType='circle' />
          <Bar dataKey="analise_diretoria" name='Análise pela Diretoria' stackId="a" fill="#00CFC3" barSize={50} />
          <Bar dataKey="analise_gerencia" name='Análise pela Gerência' stackId="a" fill="#6271FF" barSize={50} />
          <Bar dataKey="analise_aprovada" name='Aprovados' stackId="a" fill="#FF7E62" barSize={50} />
        </BarChart>
      </ResponsiveContainer>
    </div>

  );
}

export default BarChartHorizontalMobile;
