import React, { Fragment } from "react";

import "./style.css";
import { ClassicProps } from "src/@types/types";
import Sidebar from 'src/components/Funcionalidades/Sidebar';
import Header from "src/components/Basicos/Header";
import HeaderMobile from "src/components/Basicos/Header/indexMobile";
import { useControlContextMobile } from "src/contexts/MobileContext";
import SidebarMobile from "src/components/Funcionalidades/Sidebar/indexMobile";

type Props = {};
export default function HomeLayout({
  children,
}: Props & ClassicProps): JSX.Element {
  const { mobile } = useControlContextMobile();

  return (
    <Fragment>
      {
        mobile ?
          <>
            <SidebarMobile />
            <HeaderMobile>
              {children}
            </HeaderMobile>
          </>
          :
          <Sidebar>
            <Header>
              {children}
            </Header>
          </Sidebar>
      }
    </Fragment>
  );
}
