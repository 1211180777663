import React, { Fragment } from "react";
import Popup from "reactjs-popup";
import { Props } from "./types";
import "./style.css";
import { AiFillCloseCircle } from "react-icons/ai";

export default function Modal({
  isOpen,
  isClose,
  closeOnOutsideClick = false,
  modalWidth,
  modalStyle = "modal-style bg-white rounded-lg",
  headerStyle = "text-left",
  modalId,
  children,
  closeBtnStyle = "text-blue-mega-imoveis bg-white",
  isPreTitle = true,
  isCloseBtn = false,
  lockScroll,
  widthModal = "50%",
}: Props): JSX.Element {
  const modalWidthContent = { width: widthModal };
  return (
    <Popup
      modal
      open={isOpen}
      onClose={isClose}
      closeOnDocumentClick={closeOnOutsideClick}
      contentStyle={modalWidth || modalWidthContent}
      lockScroll={lockScroll}
    >
      <Fragment>
        <div className={`${modalStyle}`}>
        {headerStyle !== '' ? <div
            className={`pl-5 pt-5 pb-3 text-base md:text-3xl font-bold truncate ${headerStyle}`}
          >
          </div> : '' }
          
          <div className="content">{children}</div>
          {isCloseBtn ? (
            <div
              className={`rounded-full cursor-pointer close-btn`}
              onClick={isClose}
            >
              <AiFillCloseCircle
                size="2.5rem"
                className={`${closeBtnStyle} rounded-full`}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </Fragment>
    </Popup>
  );
}
