import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import Modal from '../ModalNoticia';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { error as errorToast } from "../../../helpers/functions";
import './style.css';
import { axiosApi } from 'src/services/axiosInstance';
import { jsonToFormData } from 'src/components/Basicos/funcoes';
import Button from 'src/components/Button';
import { MdOutlineModeEdit } from 'react-icons/md';
import CamIcon from "src/assets/icones/cam-new-photo.svg"

type Props = {
    open: boolean;
    isClose?: any;
    usuario?: any;
    setUsuario?: any;
}

const ModalInfoUsuario: React.FC<Props> = ({
    isClose,
    open,
    usuario,
    setUsuario
}: Props): JSX.Element => {
    const [usuarioModal, setUsuarioModal] = useState<any>();
    const [isRevealPassword, setIsRevealPassword] = useState<Boolean>(false);
    const [isRevealPasswordConfirmacao, setIsRevealPasswordConfirmacao] = useState<Boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [image, setImage] = useState(
        {
            preview: "",
            raw: ""
        }
    );

    const togglePassword = (): void => {
        setIsRevealPassword(!isRevealPassword);
    }

    const togglePasswordConfirmacao = (): void => {
        setIsRevealPasswordConfirmacao(!isRevealPasswordConfirmacao);
    }

    useEffect(() => {
        setUsuarioModal(usuario)
    }, [open])


    const onChange = (event: any) => {
        const { name, value } = event.target;
        setUsuarioModal({
            ...usuarioModal,
            [name]: value
        })
    }

    const onImageChange = (event: any) => {
        const { files } = event.target;
        setImage({
            ...image,
            preview: URL.createObjectURL(files[0]),
            raw: files[0],
        });
        setUsuarioModal(({
            ...usuarioModal,
            imagem: files[0]
        }))
    };

    const onSubmit = async () => {
        if (usuarioModal?.password == '' || usuarioModal?.password == undefined) delete usuarioModal.password
        if (usuarioModal?.senha_confirmacao == '' || usuarioModal?.senha_confirmacao == undefined) delete usuarioModal.senha_confirmacao
        if (usuarioModal?.password == usuarioModal?.senha_confirmacao || (usuarioModal?.password == undefined && usuarioModal?.senha_confirmacao == undefined)) {
            setLoader(true)
            try {
                const dadosFrom = jsonToFormData({ ...usuarioModal }, "PUT");
                const response = await axiosApi(
                    {
                        method: 'POST',
                        headers: { "Content-Type": "multipart/form-data;" },
                        url: 'usuario',
                        data: dadosFrom
                    }
                );
                localStorage.setItem(
                    "usuario",
                    JSON.stringify(response.data.data)
                );
                setUsuario(response.data.data);
            } catch (error) {
                console.log(error);
            } finally {
                setLoader(false)
                isClose();
            }
        } else {
            errorToast('Senhas não idênticas. Confira os dados inseridos');
        }
    }

    return (
        <Modal
            isOpen={open}
            isClose={() => isClose()}
            headerStyle={''}
            closeOnOutsideClick={true}
            widthModal={`35%`}
            modalStyle={`modal-style bg-white rounded-lg py-5 px-10 modal-usuario`}
        >
            <div className="modal-editar-usuario">
                {usuarioModal?.arquivo?.caminho || image?.preview != '' ?
                    <>
                        <input type="file" className="teste-file" accept="image/png, image/jpg, image/jpeg" onChange={onImageChange} />
                        <MdOutlineModeEdit size={'1.3rem'} pointerEvents={'none'} className={`icon-modal-editar-usuario`} />
                        <img src={image?.preview == '' ? usuarioModal?.arquivo?.caminho : image?.preview} width='150' height='150' alt='logo Mega Imoveis' className="foto-perfil mb-2" />
                    </>
                    :
                    <>
                        <input type="file" className="teste-file" accept="image/png, image/jpg, image/jpeg" onChange={onImageChange} />
                        <div className='foto-nova'>
                            <img className="icon-camera-nova-foto" alt="icon_group" src={CamIcon} />
                        </div>
                    </>
                }
                <h1>{usuario?.nome} {usuario?.sobrenome}</h1>
                <div className='form-editar-usuario mb-4'>
                    <h3 className='label-usuario'>Nome*</h3>
                    <input
                        type='text'
                        name='nome'
                        onChange={onChange}
                        value={usuarioModal?.nome ?? ""}
                        className="modal-input p-1 input-editar-usuario"
                    />
                </div>
                <div className='form-editar-usuario mb-4'>
                    <h3 className='label-usuario'>Email*</h3>
                    <input
                        type='text'
                        name='email'
                        onChange={onChange}
                        value={usuarioModal?.email ?? ""}
                        className="modal-input p-1 input-editar-usuario"
                    />
                </div>
                <div className='flex space-x-10'>
                    <div className='form-editar-usuario mb-4 cargo-grupo'>
                        <h3 className='label-usuario'>Cargo</h3>
                        <input
                            type='text'
                            name='descricao'
                            disabled={true}
                            value={usuarioModal?.cargo ?? ""}
                            className="modal-input p-1 input-editar-usuario disabled-true"
                        />
                    </div>
                    <div className='form-editar-usuario mb-4 cargo-grupo'>
                        <h3 className='label-usuario'>Grupo de Usuário</h3>
                        <input
                            type='text'
                            name='descricao'
                            disabled={true}
                            value={usuarioModal?.grupo?.descricao ?? ""}
                            className="modal-input p-1 input-editar-usuario disabled-true"
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <h3 className='label-usuario text-left'>Sedes</h3>
                    <div className='flex'>
                        {usuarioModal?.sedes?.map((sede: any) => {
                            return (
                                <input
                                    type='text'
                                    name='descricao'
                                    disabled={true}
                                    value={sede?.sede?.nome ?? ""}
                                    className="modal-input p-1 input-editar-usuario disabled-true sedes-usuario-modal"
                                />
                            )
                        })}
                    </div>
                </div>
                <div className='form-editar-usuario'>
                    <h3 className='label-usuario'>Senha*</h3>
                    <input
                        type={isRevealPassword ? "text" : "password"}
                        name='password'
                        onChange={onChange}
                        value={usuarioModal?.password ?? ""}
                        className="modal-input p-1 input-editar-usuario"
                    />
                    <span onClick={togglePassword} className="senha">
                        {isRevealPassword ?
                            <FontAwesomeIcon icon={faEye} className="customIcon" /> :
                            <FontAwesomeIcon icon={faEyeSlash} className="customIcon" />
                        }
                    </span>
                </div>
                <div className='form-editar-usuario'>
                    <h3 className='label-usuario'>Confirmar Senha*</h3>
                    <input
                        type={isRevealPasswordConfirmacao ? "text" : "password"}
                        name='senha_confirmacao'
                        onChange={onChange}
                        value={usuarioModal?.senha_confirmacao ?? ""}
                        className="modal-input p-1 input-editar-usuario"
                    />
                    <span onClick={togglePasswordConfirmacao} className="senha">
                        {isRevealPasswordConfirmacao ?
                            <FontAwesomeIcon icon={faEye} className="customIcon" /> :
                            <FontAwesomeIcon icon={faEyeSlash} className="customIcon" />
                        }
                    </span>
                </div>
                <Button
                    type={`submit`}
                    className={`button-modal-usuario`}
                    id={``}
                    name={``}
                    content={`SALVAR ALTERAÇÕES`}
                    onClick={onSubmit}
                    loader={loader} />
            </div>
        </Modal>
    );
}

export default ModalInfoUsuario;