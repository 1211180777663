import React from 'react';
import Header from '../Universal/HeaderPDF';
import Footer from '../Universal/FooterPDF';
import Linha from '../Universal/Linha';
import { mask } from 'src/helpers/functions';
import { formatarValor } from 'src/components/Basicos/funcoes';
import OutroModelo1 from './OutrosModelos/OutroModelo1';

interface ModeloProps {
    fiador?: any
}

const Modelo1: React.FC<ModeloProps> = ({ fiador }) => {
    return (
        <>
            {/* Página 1 */}
            <div className='pagina-pdf'>
                {console.log(fiador)}
                <Header
                    tipo='Fiador'
                    nome={fiador?.informacoes_basicas?.nome_cliente}
                    tipo_garantia={fiador?.garantia?.descricao}
                    status={fiador?.ultimo_historico?.etapa_historico?.descricao}
                />
                <div className='body-pdf'>
                    <h1 className='sub-title estilo-fonte-negrito'>
                        Identificação
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <Linha
                        campo10='CPF*' valor10={mask(fiador?.informacoes_basicas?.cpf, "cpf_cnpj")}
                        campo20='E-mail*' valor20={fiador?.informacoes_basicas?.email} />
                    <Linha
                        campo10='Nome*' valor10={fiador?.informacoes_basicas?.nome_cliente}
                        campo20='Estado Civil*' valor20={fiador?.informacoes_basicas?.estado_civil?.descricao}
                        campo21='N° de Dependentes*' valor21={fiador?.informacoes_basicas?.qtd_dependentes} />
                    <h1 className='text-xs'>Documento de Identificação Oficial com Foto*</h1>
                    <Linha
                        campo10='RG Frente*' valor10={fiador?.informacoes_basicas?.rg_cnh_frente?.nome}
                        campo20='A Locação é por procuração?*' valor20={fiador?.procurador_id != null ? 'Sim' : 'Não'} />
                    <Linha
                        campo10='RG Verso*' valor10={fiador?.informacoes_basicas?.rg_cnh_verso?.nome} />
                    <Linha
                        campo10='Telefone 1*' valor10={mask(fiador?.informacoes_basicas?.telefone1, "telefone")}
                        campo11='Telefone 2' valor11={mask(fiador?.informacoes_basicas?.telefone2, "telefone")} />

                    <div className='borda-principal-pdf mt-4'></div>

                    <h1 className='sub-title estilo-fonte-negrito'>
                        Cônjuge
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <Linha
                        campo10='Nome*' valor10={fiador?.conjuge?.informacoes_basicas?.nome_cliente}
                        campo20='CPF*' valor20={mask(fiador?.conjuge?.informacoes_basicas?.cpf, "cpf_cnpj")} />
                    <Linha
                        campo10='Telefone 1*' valor10={fiador?.conjuge?.informacoes_basicas?.telefone1}
                        campo11='Telefone 2' valor11={fiador?.conjuge?.informacoes_basicas?.telefone2}
                        campo20='E-mail*' valor20={fiador?.conjuge?.informacoes_basicas?.email} />
                    <h1 className='text-xs'>Documento de Identificação Oficial com Foto*</h1>
                    <Linha
                        campo10='RG Frente*' valor10={fiador?.conjuge?.informacoes_basicas?.rg_cnh_frente?.nome} />
                    <Linha
                        campo10='RG Verso*' valor10={fiador?.conjuge?.informacoes_basicas?.rg_cnh_verso?.nome} />
                    {fiador?.conjuge?.atividade_profissional.tipo_atividade_id == 5 ?
                        <>
                            <Linha campo10='Tipo de Atividade Profissional*' valor10={fiador?.conjuge?.atividade_profissional?.tipo_atividade?.descricao} campo11='Renda Mensal*' valor11={formatarValor(fiador?.conjuge?.atividade_profissional?.renda_mensal)} campo20='Tempo de Empresa' valor20={fiador?.conjuge?.atividade_profissional?.tempo_empresa?.descricao} campo21='Cargo Atual' valor21={fiador?.conjuge?.atividade_profissional?.cargo_atual} />
                            <Linha campo10='Nome da Empresa' valor10={fiador?.conjuge?.atividade_profissional?.nome_empresa} />
                        </>
                        : fiador?.conjuge?.atividade_profissional.tipo_atividade_id != 1 ?
                            <>
                                <Linha campo10='Tipo de Atividade Profissional*' valor10={fiador?.conjuge?.atividade_profissional?.tipo_atividade?.descricao} campo11='Renda Mensal*' valor11={formatarValor(fiador?.conjuge?.atividade_profissional?.renda_mensal)} isEndereco2={true} campo20='Logradouro' valor20={fiador?.conjuge?.atividade_profissional?.endereco?.logradouro ?? null} campo21='Número' valor21={fiador?.conjuge?.atividade_profissional?.numero ?? null} />
                                <Linha campo10='Nome da Empresa' valor10={fiador?.conjuge?.atividade_profissional?.nome_empresa} campo20='Complemento' valor20={fiador?.conjuge?.atividade_profissional?.endereco?.complemento ?? null} />
                            </>
                            :
                            <>
                                <Linha campo10='Tipo de Atividade Profissional*' valor10={fiador?.conjuge?.atividade_profissional?.tipo_atividade?.descricao} campo11='Renda Mensal*' valor11={formatarValor(fiador?.conjuge?.atividade_profissional?.renda_mensal)} />
                                <h1 className='text-xs'>Documento de Identificação Oficial com Foto*</h1>
                                <Linha campo10='Imposto de Renda' valor10={fiador?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.nome} campo20='Extrato Bancário' valor20={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.nome} />
                            </>
                    }
                </div>

                <div className='footer-pdf'>
                    <Footer />
                </div>
            </div>

            {fiador?.conjuge?.atividade_profissional.tipo_atividade_id == 5 ?
                <>
                    <OutroModelo1 fiador={fiador} />
                </>
                :
                <>
                    {/* Página 2 */}
                    <div className='pagina-pdf'>
                        <Header
                            tipo='Fiador'
                            nome={fiador?.informacoes_basicas?.nome_cliente}
                            tipo_garantia={fiador?.garantia?.descricao}
                            status={fiador?.ultimo_historico?.etapa_historico?.descricao}
                        />
                        <div className='body-pdf'>
                            {fiador?.conjuge?.atividade_profissional.tipo_atividade_id != 1 ?
                                <>
                                    <Linha campo10='Tempo de Empresa' valor10={fiador?.conjuge?.atividade_profissional?.tempo_empresa?.descricao} campo11='Cargo Atual' valor11={fiador?.conjuge?.atividade_profissional?.cargo_atual} campo20='Bairro' valor20={fiador?.conjuge?.atividade_profissional?.endereco?.bairro} />
                                    <Linha campo10='CEP' valor10={mask(fiador?.conjuge?.atividade_profissional?.endereco?.cep, "cep")} campo20='Cidade' valor20={fiador?.conjuge?.atividade_profissional?.endereco?.cidade} campo21='Estado' valor21={fiador?.conjuge?.atividade_profissional?.endereco?.estado} />
                                    <Linha campo10='Último Extrato do Cartão de Crédito*' valor10={fiador?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome} />
                                    <h1 className='text-xs'>Documento de Identificação Oficial com Foto*</h1>
                                    <Linha campo10='Contracheque' valor10={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.nome} campo20='Imposto de Renda' valor20={fiador?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.nome} />
                                    <Linha campo10='CTPS' valor10={fiador?.conjuge?.atividade_profissional?.arquivo_ctps?.nome} campo20='Extrato Bancário' valor20={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.nome} />
                                </> : ''}

                            <div className='borda-principal-pdf mt-4'></div>

                            <h1 className='sub-title estilo-fonte-negrito'>
                                Residência Atual
                            </h1>
                            <div className='borda-pdf mb-3 mt-3'></div>
                            <Linha campo10='Sua Residência é*' valor10={fiador?.residencia_atual?.tipo_residencia?.descricao} campo20='Complemento' valor20={fiador?.endereco?.complemento} />
                            <Linha campo10='CEP' valor10={mask(fiador?.endereco?.cep, "cep")} campo20='Bairro*' valor20={fiador?.endereco?.bairro} />
                            <Linha campo10='Comprovante de Endereço' valor10={fiador?.residencia_atual?.arquivo?.nome} campo20='Cidade' valor20={fiador?.endereco?.cidade} campo21='Estado' valor21={fiador?.endereco?.estado} />
                            <Linha isEndereco1={true} campo10='Logradouro*' valor10={fiador?.endereco?.logradouro} campo11='Numero*' valor11={fiador?.endereco?.numero} />
                            <div className='borda-principal-pdf mt-4'></div>

                            <h1 className='sub-title estilo-fonte-negrito'>
                                Atividade Profissional
                            </h1>
                            <div className='borda-pdf mb-3 mt-3'></div>
                            {fiador?.atividade_profissional.tipo_atividade_id != 1 && fiador?.conjuge?.atividade_profissional.tipo_atividade_id != 1 ?
                                fiador?.atividade_profissional.tipo_atividade_id == 2 ?
                                    <>
                                        <Linha
                                            campo10='Tipo de Atividade Profissional*' valor10={fiador?.atividade_profissional?.tipo_atividade?.descricao}
                                            campo11='Renda Mensal*' valor11={formatarValor(fiador?.atividade_profissional?.renda_mensal, "dinheiro") ?? null} isEndereco2={true}
                                            campo20='Ramo da Atividade' valor20={fiador?.atividade_profissional?.ramo_atividade ?? null} />
                                        <Linha
                                            campo10='Imposto de Renda' valor10={fiador?.atividade_profissional?.arquivo_imposto_renda?.nome ?? null}
                                            campo20='Extrato Bancário' valor20={fiador?.atividade_profissional?.arquivo_extrato_bancario?.nome ?? null} />
                                        <Linha
                                            campo10='Extrato Bancário 2' valor10={fiador?.atividade_profissional?.arquivo_extrato_bancario2?.nome ?? null}
                                            campo20='Extrato Bancário 3' valor20={fiador?.atividade_profissional?.arquivo_extrato_bancario3?.nome ?? null} />
                                    </>
                                    : <>
                                        <Linha
                                            campo10='Tipo de Atividade Profissional*' valor10={fiador?.atividade_profissional?.tipo_atividade?.descricao}
                                            campo11='Renda Mensal*' valor11={formatarValor(fiador?.atividade_profissional?.renda_mensal, "dinheiro") ?? null} isEndereco2={true}
                                            campo20='Logradouro' valor20={fiador?.atividade_profissional?.endereco?.logradouro ?? null}
                                            campo21='Número' valor21={fiador?.atividade_profissional?.endereco?.numero ?? null} />
                                        <Linha
                                            campo10='Nome da Empresa' valor10={fiador?.atividade_profissional?.nome_empresa}
                                            campo20='Complemento' valor20={fiador?.atividade_profissional?.endereco?.complemento ?? null} />
                                        <Linha
                                            campo10='Tempo de Empresa' valor10={fiador?.atividade_profissional?.tempo_empresa?.descricao ?? null}
                                            campo11='Cargo Atual' valor11={fiador?.atividade_profissional?.cargo_atual ?? null}
                                            campo20='Bairro' valor20={fiador?.atividade_profissional?.endereco?.bairro ?? null} />
                                    </>
                                : fiador?.atividade_profissional.tipo_atividade_id != 1 && fiador?.conjuge?.atividade_profissional.tipo_atividade_id == 1 ?
                                    <>
                                        <Linha
                                            campo10='Tipo de Atividade Profissional*' valor10={fiador?.atividade_profissional?.tipo_atividade?.descricao}
                                            campo11='Renda Mensal*' valor11={formatarValor(fiador?.atividade_profissional?.renda_mensal, "dinheiro") ?? null} isEndereco2={true}
                                            campo20='Logradouro' valor20={fiador?.atividade_profissional?.endereco?.logradouro ?? null}
                                            campo21='Número' valor21={fiador?.atividade_profissional?.endereco?.numero ?? null} />
                                        <Linha
                                            campo10='Nome da Empresa' valor10={fiador?.atividade_profissional?.nome_empresa}
                                            campo20='Complemento' valor20={fiador?.atividade_profissional?.endereco?.complemento ?? null} />
                                        <Linha
                                            campo10='Tempo de Empresa' valor10={fiador?.atividade_profissional?.tempo_empresa?.descricao ?? null}
                                            campo11='Cargo Atual' valor11={fiador?.atividade_profissional?.cargo_atual ?? null}
                                            campo20='Bairro' valor20={fiador?.atividade_profissional?.endereco?.bairro ?? null} />
                                        <Linha
                                            campo10='CEP' valor10={mask(fiador?.atividade_profissional?.endereco?.cep, "cep") ?? null}
                                            campo20='Cidade' valor20={fiador?.atividade_profissional?.endereco?.cidade ?? null}
                                            campo21='Estado' valor21={fiador?.atividade_profissional?.endereco?.estado ?? null} />
                                        <Linha
                                            campo10='Último Extrato do Cartão de Crédito*' valor10={fiador?.atividade_profissional?.arquivo_extrato_bancario?.nome} />
                                    </>
                                    :
                                    <>
                                        <Linha
                                            campo10='Tipo de Atividade Profissional*' valor10={fiador?.atividade_profissional?.tipo_atividade?.descricao}
                                            campo11='Renda Mensal*' valor11={formatarValor(fiador?.atividade_profissional?.renda_mensal, "dinheiro") ?? null} />
                                        <Linha
                                            campo10='Último Extrato do Cartão de Crédito*' valor10={fiador?.atividade_profissional?.arquivo_extrato_bancario?.nome} />
                                    </>
                            }
                        </div>

                        <div className='footer-pdf'>
                            <Footer />
                        </div>
                    </div>

                    {/* Página 3 */}
                    {fiador?.atividade_profissional.tipo_atividade_id != 1 && fiador?.conjuge?.atividade_profissional.tipo_atividade_id != 1 ?
                        <div className='pagina-pdf'>
                            <Header
                                tipo='Fiador'
                                nome={fiador?.informacoes_basicas?.nome_cliente}
                                tipo_garantia={fiador?.garantia?.descricao}
                                status={fiador?.ultimo_historico?.etapa_historico?.descricao}
                            />
                            <div className='body-pdf'>
                                {fiador?.atividade_profissional.tipo_atividade_id == 2 ?
                                    <>
                                        <h1 className='sub-title estilo-fonte-negrito'>
                                            Imóvel em Garantia
                                        </h1>
                                        <div className='borda-pdf mb-3 mt-3'></div>
                                        <Linha campo10='Matrícula do Imóvel Quitado e Sem Ônus' valor10={fiador?.imovel_garantia?.matricula_imovel} campo20='Certidão de Matrícula Atualizada' valor20={fiador?.imovel_garantia?.certidao_matricula?.nome} />
                                    </> :
                                    <>
                                        <Linha campo10='CEP' valor10={mask(fiador?.atividade_profissional?.endereco?.cep, "cep") ?? null} campo20='Cidade' valor20={fiador?.atividade_profissional?.endereco?.cidade ?? null} campo21='Estado' valor21={fiador?.atividade_profissional?.endereco?.estado ?? null} />
                                        <Linha campo10='Último Extrato do Cartão de Crédito*' valor10={fiador?.atividade_profissional?.arquivo_extrato_bancario?.nome} />

                                        <div className='borda-principal-pdf mt-4'></div>

                                        <h1 className='sub-title estilo-fonte-negrito'>
                                            Imóvel em Garantia
                                        </h1>
                                        <div className='borda-pdf mb-3 mt-3'></div>
                                        <Linha campo10='Matrícula do Imóvel Quitado e Sem Ônus' valor10={fiador?.imovel_garantia?.matricula_imovel} campo20='Certidão de Matrícula Atualizada' valor20={fiador?.imovel_garantia?.certidao_matricula?.nome} />
                                    </>
                                }


                            </div>

                            <div className='footer-pdf'>
                                <Footer />
                            </div>
                        </div>
                        :
                        ''}
                </>}
        </>
    );
};

export default Modelo1;