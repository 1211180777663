import React, { useState, useEffect } from 'react';
import { consultas } from "./data-columns.json"
import './styles.css'
import Loader from 'src/components/Basicos/Loader';
import { axiosApi } from 'src/services/axiosInstance';
import SelectForm from 'src/components/Basicos/Form/SelectForm';
import { transformDataToOptionsSelectForm } from 'src/components/Basicos/funcoes';
import Button from 'src/components/Button';
import Inputlabel from 'src/components/Basicos/Form/Input/InputLabel';
import { dateAmerican, dateToPtBr } from 'src/helpers/functions';
import { useHistory } from 'react-router';
import CheckBox from 'src/components/Basicos/Form/CheckBox';

function Accordion({ consulta, sedes, garantias, status, tipos }: any) {
    const [expanded, setExpanded] = useState(false);
    const [cancelados, setCancelados] = useState(false);
    const toggleExpanded = () => setExpanded((current) => !current);
    const [filtro, setFiltro] = useState<any>();
    const [typeInicio, setTypeInicio] = useState("text");
    const [typeFim, setTypeFim] = useState("text");
    const history = useHistory();

    const onChange = (event: any) => {
        const { name, value } = event.target;
        setFiltro({
            ...filtro,
            [name]: dateToPtBr(value)
        })
    };

    const changeCancelados = () => {
        setCancelados(!cancelados)
    }

    const onChangeEventSelect = (name: string, value: number): void => {
        setFiltro((prevState: any) => ({ ...prevState, [name]: value }));
    }

    const onSubmit = () => {
        var url = `tipo-consulta=${consulta.accessor}`
        url = filtro?.sedes && filtro?.sedes != 0 ? `${url}&sedes=${filtro?.sedes}` : url;
        url = filtro?.data_inicio ? `${url}&data_inicio=${filtro?.data_inicio}` : url;
        url = filtro?.data_fim ? `${url}&data_fim=${filtro?.data_fim}` : url;
        url = filtro?.garantia && filtro?.garantia != 0 ? `${url}&garantia=${filtro?.garantia}` : url;
        url = filtro?.tipos && filtro?.tipos != 0 ? `${url}&tipos=${filtro?.tipos}` : url;
        url = filtro?.status && filtro?.status != 0 ? `${url}&status=${filtro?.status}` : url;
        url = `${url}&cancelados=${cancelados}`
        history.push(`/consulta?${url}`)
    }

    return (
        <div className={`${expanded ? 'consulta-grande-expandido' : 'consulta-nao-expandido'}`}>
            <div className={`colapse-consulta`} onClick={toggleExpanded}>
                <h1 className='text-white estilo-fonte-negrito ml-6 text-xl'>{consulta.Header}</h1>
            </div>
            <div className={`${expanded ? 'consulta-expandido' : 'consulta-none'}`}>
                <div className='form-consulta'>
                    <SelectForm
                        name={'sedes'}
                        options={sedes}
                        label={'Sedes'}
                        value={sedes?.find((element: any) => element?.label === filtro?.sede)}
                        onChange={onChangeEventSelect}
                        placeholder={"Selecione"}
                    />
                </div>
                <div className="flex w-full lg:w-3/3 mb-12 lg:mb-0 flex-col lg:flex-row gap-2 mt-2">
                    <div className='w-1/2'>
                        <h3 className='label-usuario'>Data Início</h3>
                        <Inputlabel
                            type={typeInicio}
                            className={`modal-input p-1 input-editar-sede`}
                            colClassname={`w-full`}
                            name={"data_inicio"}
                            placeholder={`Data Início`}
                            onFocus={() => setTypeInicio("date")}
                            value={dateAmerican(filtro?.data_inicio) ?? ""}
                            onChange={onChange}
                            titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
                            required={true}
                        />
                    </div>
                    <div className='w-1/2'>
                        <h3 className='label-usuario'>Data Fim</h3>
                        <Inputlabel
                            type={typeFim}
                            className={`modal-input p-1 input-editar-sede`}
                            colClassname={`w-full`}
                            name={"data_fim"}
                            placeholder={`Data Fim`}
                            onFocus={() => setTypeFim("date")}
                            value={dateAmerican(filtro?.data_fim) ?? ""}
                            onChange={onChange}
                            titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
                            required={true}
                        />
                    </div>
                </div>
                <div className='form-consulta mt-2'>
                    {consulta?.accessor == 'visitas-imoveis' ? '' :
                        consulta?.accessor == 'locatarios-interessados' ||
                            consulta?.accessor == 'proprietarios-interessados' ||
                            consulta?.accessor == 'solicitacoes-priprietarios' ||
                            consulta?.accessor == 'ouvidoria' ||
                            consulta?.accessor == 'imoveis-alugados' || consulta?.accessor == 'indicacao-corretor' ?
                            <SelectForm
                                name={'tipos'}
                                options={tipos}
                                label={'Tipos'}
                                value={tipos?.find((element: any) => element?.label === element?.label === filtro?.tipos)}
                                onChange={onChangeEventSelect}
                                placeholder={"Selecione"}
                            />
                            :
                            <SelectForm
                                name={'garantia'}
                                options={garantias}
                                label={'Tipo de Garantia'}
                                value={garantias?.find((element: any) => element?.label === element?.label === filtro?.garantia)}
                                onChange={onChangeEventSelect}
                                placeholder={"Selecione"}
                            />
                    }
                </div>
                <div className={`form-consulta mt-2 ${consulta?.accessor == "cadastro-locacao" ? 'flex status-cancelados' : '' }`}>
                    {
                        consulta?.accessor == "cadastro-locacao" ?
                            <>
                                <div className='form-consulta-status'>
                                    <SelectForm
                                        name={'status'}
                                        options={status}
                                        label={'Status'}
                                        value={status?.find((element: any) => element?.label === filtro?.status)}
                                        onChange={onChangeEventSelect}
                                        placeholder={"Selecione"}
                                    />
                                </div>
                                <CheckBox
                                    checked={cancelados}
                                    funcao={changeCancelados}
                                    label={'Incluir Cancelados'}
                                    style={'mt-5'}
                                />
                            </> :
                            <SelectForm
                                name={'status'}
                                options={status}
                                label={'Status'}
                                value={status?.find((element: any) => element?.label === filtro?.status)}
                                onChange={onChangeEventSelect}
                                placeholder={"Selecione"}
                            />
                    }
                </div>
                <div className='mt-4 mb-4 box-buttom'>
                    <Button
                        type={`submit`}
                        className={`button-modal-usuario`}
                        id={``}
                        name={``}
                        content={`CONSULTAR`}
                        onClick={onSubmit}
                        loader={false} />
                </div>
            </div>
        </div>
    )
}

const Consultas: React.FunctionComponent = () => {
    const [consultasPermitidas, setConsultasPermitidas] = useState<any>();
    const [sedes, setSedes] = useState<any>();
    const [garantias, setGarantias] = useState<any>();
    const [status, setStatus] = useState<any>();
    const [tipoImoveisAlugados, setTipoImoveisAlugados] = useState<any>();
    const [tipoLocatarioInteressado, setTipoLocatarioInteressado] = useState<any>();
    const [statusVisitaImovel, setStatusVisitaImovel] = useState<any>();
    const [tipoProprietarioInteressado, setTipoProprietarioInteressado] = useState<any>();
    const [tipoSolicitacaoProprietario, setTipoSolicitacaoProprietario] = useState<any>();
    const [tipoOuvidoria, setTipoOuvidoria] = useState<any>();
    const [tipoIndicacaoCorretor, setTipoIndicacaoCorretor] = useState<any>();
    const [statusIndicacao, setStatusIndicacao] = useState<any>();
    const [statusSolicitacao, setStatusSolicitacao] = useState<any>();
    const [loader, setLoader] = useState<boolean>(true);

    const loadInfos = async () => {
        try {
            setLoader(true);
            const dataSedes = await axiosApi.get(`/loja`);
            const dataGarantia = await axiosApi.get('/tipos-garantia');
            const dataStatus = await axiosApi.get('/etapas-historico');
            const dataPermissoes = await axiosApi.get('/consultas-disponiveis')

            const dataFiltroConsultas = await axiosApi.get('/filtros-consulta');
            var opcaoDefault = { id: 0, nome: "Selecione" }

            const opcaoDefaultTipoImoveis = [opcaoDefault].concat(dataFiltroConsultas?.data.dataImoveisAlugados)
            const opcaoDefaultLocatarioInteressado = [opcaoDefault].concat(dataFiltroConsultas?.data.dataLocatarioInteressado)
            const opcaoDefaultStatusVisitaImovel = [opcaoDefault].concat(dataFiltroConsultas?.data.dataStatusVisitaImovel)
            const opcaoDefaultProprietarioInteressado = [opcaoDefault].concat(dataFiltroConsultas?.data.dataProprietarioInteressado)
            const opcaoDefaultSolicitacaoProprietario = [opcaoDefault].concat(dataFiltroConsultas?.data.dataSolicitacaoProprietario)
            const opcaoDefaultOuvidoria = [opcaoDefault].concat(dataFiltroConsultas?.data.dataOuvidoria)
            const opcaoDefaultIndicacaoCorretor = [opcaoDefault].concat(dataFiltroConsultas?.data.dataIndicacaoCorretor)
            const opcaoDefaultStatusIndicacao = [opcaoDefault].concat(dataFiltroConsultas?.data.dataStatusIndicacao)
            const opcaoDefaultStatusSolicitacao = [opcaoDefault].concat(dataFiltroConsultas?.data.dataStatusSolicitacao)
            const opcaoDefaultSedes = [opcaoDefault].concat(dataSedes?.data.data)
            const opcaoDefaultGarantia = [opcaoDefault].concat(dataGarantia?.data.data)
            const opcaoDefaultStatus = [opcaoDefault].concat(dataStatus?.data.data)

            setConsultasPermitidas(dataPermissoes?.data.data);
            setTipoImoveisAlugados(transformDataToOptionsSelectForm(opcaoDefaultTipoImoveis))
            setTipoLocatarioInteressado(transformDataToOptionsSelectForm(opcaoDefaultLocatarioInteressado))
            setStatusVisitaImovel(transformDataToOptionsSelectForm(opcaoDefaultStatusVisitaImovel))
            setTipoProprietarioInteressado(transformDataToOptionsSelectForm(opcaoDefaultProprietarioInteressado))
            setTipoSolicitacaoProprietario(transformDataToOptionsSelectForm(opcaoDefaultSolicitacaoProprietario))
            setTipoOuvidoria(transformDataToOptionsSelectForm(opcaoDefaultOuvidoria))
            setTipoIndicacaoCorretor(transformDataToOptionsSelectForm(opcaoDefaultIndicacaoCorretor))
            setStatusIndicacao(transformDataToOptionsSelectForm(opcaoDefaultStatusIndicacao))
            setStatusSolicitacao(transformDataToOptionsSelectForm(opcaoDefaultStatusSolicitacao))
            setSedes(transformDataToOptionsSelectForm(opcaoDefaultSedes));
            setGarantias(transformDataToOptionsSelectForm(opcaoDefaultGarantia));
            setStatus(transformDataToOptionsSelectForm(opcaoDefaultStatus));
            setLoader(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadInfos();
    }, []);

    return (
        <Loader isActive={loader}>
            {consultasPermitidas?.length == 0 ?
                <div className='sem-consultas'>
                    <div>Acesso não autorizado!</div> <div>Entre em contato com o Administrador da Plataforma.</div>
                </div>
                :
                <div className='p-10 grid grid-cols-2 gap-4'>
                    {
                        consultas?.map((consulta: any) => {
                            return (
                                consultasPermitidas?.includes(consulta?.accessor) ?
                                    <Accordion
                                        consulta={consulta}
                                        sedes={sedes}
                                        garantias={garantias}
                                        status={consulta?.accessor == 'visitas-imoveis' ? statusVisitaImovel :
                                            consulta?.accessor == 'locatarios-interessados' || consulta?.accessor == 'proprietarios-interessados'
                                                || consulta?.accessor == 'solicitacoes-priprietarios' || consulta?.accessor == 'ouvidoria'
                                                || consulta?.accessor == 'imoveis-alugados' ? statusSolicitacao
                                                : consulta?.accessor == 'indicacao-corretor' ? statusIndicacao : status}
                                        tipos={consulta?.accessor == 'locatarios-interessados' ? tipoLocatarioInteressado :
                                            consulta?.accessor == 'proprietarios-interessados' ? tipoProprietarioInteressado :
                                                consulta?.accessor == 'solicitacoes-priprietarios' ? tipoSolicitacaoProprietario :
                                                    consulta?.accessor == 'ouvidoria' ? tipoOuvidoria :
                                                        consulta?.accessor == 'imoveis-alugados' ? tipoImoveisAlugados :
                                                            tipoIndicacaoCorretor}
                                    /> : ""
                            )
                        })
                    }
                </div>}
        </Loader >
    );
}

export default Consultas;