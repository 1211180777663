import React, { Fragment, useEffect } from 'react';

import Loader from 'src/components/Basicos/Loader';
import { agendamentoVisita } from "./data-columns.json";
import TableWithGlobalSearch from 'src/components/Basicos/TableWithGlobalSearch';
import ModalAdicionarEditar from 'src/components/Funcionalidades/Modal';
import { useControlContextSolicitacao } from 'src/contexts/SolicitacaoControlContext';
import { useLocation } from 'react-router-dom';
import SolicitacaoAgendamento from 'src/pages/FormsAuxiliar/_Solicitacao';


const AnuncieImovel: React.FunctionComponent = () => {
    const location = useLocation();
    const typeRequests = location.pathname.split('/')[2];
    const {
        loader,
        setRequest,
        filterSolicitacaoByDate,
        dateActive,
        openModal,
        setDataSolicitacao,
        open,
        id,
        estilo,
        verifyStatus,
        getSolicitacaoPeloIndice,
        closeModal,
        filterSede
    } = useControlContextSolicitacao();

    useEffect(() => {
        setDataSolicitacao([]);
        setRequest(typeRequests);
    }, []);

    useEffect(() => {
        verifyStatus(getSolicitacaoPeloIndice(id)?.status_solicitacao_id);
    }, [id]);

    return (
        <Fragment>

            <Loader
                isActive={loader}
            >
                <ModalAdicionarEditar
                    open={open}
                    closeModal={closeModal}
                    dados={id ?? undefined}
                    click={false}
                >
                    <SolicitacaoAgendamento
                        titulo={`Anuncie seu Imóvel - Detalhes da Solicitação`}
                        dadosSolicitacao={id !== undefined ? getSolicitacaoPeloIndice(id) : undefined}
                        styleStatus={estilo}
                        closeModal={closeModal}
                        dataArray={filterSolicitacaoByDate(dateActive?.dateInicial, dateActive?.dateFinal)}
                        formComplete={true}
                        loadAgendamentoVisita={() => window.location.reload()}
                        opcoesStatus={[1,2,3]}
                    />
                </ModalAdicionarEditar>
                <div className='row-auto' style={classes.container}>
                    <div className="card-content">
                        <div className="grid flex-col p-6">
                            <TableWithGlobalSearch
                                columns={agendamentoVisita.columns}
                                data={filterSede(filterSolicitacaoByDate(dateActive?.dateInicial, dateActive?.dateFinal).sort((a: any, b: any) => Number(b.id) - Number(a.id)), true)}
                                actions={{
                                    show: (id: number) => {
                                        getSolicitacaoPeloIndice(id);
                                        openModal(id);
                                    }
                                }}
                                withModal={true}
                                itsCount={true}
                                isButtons={true}
                                requestClose={null}
                            />
                        </div>
                    </div>
                </div>
            </Loader>
        </Fragment>
    );
}

const classes = {
    container: {
        marginTop: '3.5rem',
        marginButtom: '3.5rem',
        marginLeft: '2rem',
        marginRight: '2rem',
    },
};
export default AnuncieImovel;