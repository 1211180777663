import React from 'react';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import Modelo1 from '../../../../components/PDF/ModelosProprietario/Modelo1';
import Modelo2 from '../../../../components/PDF/ModelosProprietario/Modelo2';
import Modelo3 from '../../../../components/PDF/ModelosProprietario/Modelo3';
import Modelo4 from '../../../../components/PDF/ModelosProprietario/Modelo4';
import './styles.css';

interface ComponenteProps {
    proprietario?: any
}

const ComponentToPDF: React.FC<ComponenteProps> = ({ proprietario }) => {
    const rgOrCnh = React.useMemo(() => {
        return proprietario?.informacoes_basicas?.nacionalidade === null &&
            proprietario?.informacoes_basicas?.naturalidade === null
            ? 'RG'
            : 'CNH';
    }, [proprietario]);

    return (
        <>
            {proprietario?.conjuge_id != null && proprietario?.procurador != null ? <Modelo1 proprietario={proprietario} tipo_proprietario={rgOrCnh} nome={proprietario?.informacoes_basicas?.nome_cliente} tipo_header="Proprietário"/> :
                proprietario?.conjuge_id == null && proprietario?.procurador != null ? <Modelo2 proprietario={proprietario} tipo_proprietario={rgOrCnh} nome={proprietario?.informacoes_basicas?.nome_cliente} tipo_header="Proprietário" /> :
                    proprietario?.conjuge_id != null && proprietario?.procurador == null ? <Modelo3 proprietario={proprietario} tipo_proprietario={rgOrCnh} nome={proprietario?.informacoes_basicas?.nome_cliente} tipo_header="Proprietário" /> :
                        <Modelo4 proprietario={proprietario} tipo_proprietario={rgOrCnh} nome={proprietario?.informacoes_basicas?.nome_cliente} tipo_header="Proprietário" />
            }
        </>
    );
};

export default ComponentToPDF;