import React, { useEffect, useState } from 'react';
import Modal from 'src/components/Funcionalidades/ModalNoticia';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import './style.css'

type Props = {
    open: boolean;
    isClose?: any;
    usuario?: any;
    setUsuario?: any;
    imgs: any;
    indexImg: any;
}

const ModalImg: React.FC<Props> = ({
    isClose,
    open,
    imgs,
    indexImg
}: Props): JSX.Element => {
    const [img, setImg] = useState<any>();

    useEffect(() => {
        setImg(indexImg)
    }, [open]);

    const onChange = (index: number): any => {
        setImg(index)
    }

    const close = () => {
        setImg(undefined);
        isClose();
    }

    const voltarImg = () => {
        var i = img - 1;
        if (imgs[i] == undefined)
            i = imgs.length - 1
        setImg(i)
    }

    const frenteImg = () => {
        var i = img + 1;
        if (imgs[i] == undefined)
            i = 0
        setImg(i)
    }

    return (
        <Modal
            isOpen={open}
            isClose={() => close()}
            headerStyle={''}
            closeOnOutsideClick={false}
            widthModal={`60%`}
            isCloseBtn={true}
            modalStyle={`modal-style bg-white rounded-lg py-5 px-10 modal-img`}
        >
            {
                img != undefined ?
                    <>
                        <div className='img-principal'>
                            {/* <img className='seta-img' onClick={() => voltarImg()} src={iconVoltar}/> */}
                            <FaChevronLeft className='icon-img' onClick={() => voltarImg()} />
                            <img className='img-central' src={`${imgs[img]?.imagem?.caminho}`} />
                            <FaChevronRight className='icon-img' onClick={() => frenteImg()} />
                            {/* <img className='seta-img' onClick={() => frenteImg()} src={iconFrente}/> */}
                        </div>
                        <div className='img-secundaria mt-4 mb-4'>
                            {
                                imgs?.map((imagem: any, index: any) => (
                                    <img onClick={() => onChange(index)} className={`${img == index ? 'img-selecionada ml-2 mr-2 mb-4' : 'ml-2 mr-2 mb-4'}`} src={`${imagem?.imagem?.caminho}`} />
                                ))
                            }
                        </div>
                    </>
                    : <></>
            }

        </Modal>
    );
}

export default ModalImg;