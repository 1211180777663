import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
// import Header from "src/components/Basicos/Header";
import Loader from "src/components/Basicos/Loader";
import Modal from "src/components/Funcionalidades/ModalNoticia";
import { axiosApi } from "src/services/axiosInstance";
import FormularioCadastroProprietarioPessoaFisica from "./PessoaFisica";
import FormularioCadastroProprietarioPessoaJuridica from "./PessoaJuridica";
import { useControlContextProprietario } from "src/contexts/ProprietarioContext";

const FormularioValidateCadastroProprietario: React.FunctionComponent = () => {
    const { proprietarioId }: any = useParams();
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const [motivo, setMotivo] = useState<any>();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    const itsView = location.pathname.split('/').includes('view');
    const {
        setPropPDF
    } = useControlContextProprietario();

    const [valueForm, setValueForm] = useState<any>({
        nome_proprietario: null,
        telefone_proprietario: null,
        telefone2_proprietario: null,
        rg_cnh_proprietario: null,
        nacionalidade_proprietario: null,
        naturalidade_proprietario: null,
        cpf_proprietario: null,
        email_proprietario: null,
        estado_civil_proprietario: null,
        numb_dependentes_proprietario: null,

        nome_conjuge: null,
        telefone_conjuge: null,
        telefone2_conjuge: null,
        rg_conjuge: null,
        cpf_conjuge: null,
        email_conjuge: null,
        profissao_conjuge: null,
        naturalidade_conjuge: null,
        nacionalidade_conjuge: null,

        comprovante_endereco_residencia_atual: null,
        cep_residencia_atual: null,
        logradouro_residencia_atual: null,
        numero_residencia_atual: null,
        bairro_residencia_atual: null,
        cidade_residencia_atual: null,
        estado_residencia_atual: null,
        complemento_residencia_atual: null,
        valor_aluguel_residencia_atual: null,
        tempo_locacao_residencia_atual: null,


        finalidade_imovel: null,
        tipo_imovel: null,
        matricula_imovel: null,
        vl_locacao_imovel: null,
        vl_venda_imovel: null,
        matricula_escritura_contrato: null,
        cep_imovel: null,
        logradouro_imovel: null,
        numero_imovel: null,
        complemento_imovel: null,
        bairro_imovel: null,
        cidade_imovel: null,
        estado_imovel: null,
        iptu_imovel: null,


        dormitorio_imovel: null,
        suites_imovel: null,
        area_imovel: null,
        vagas_imovel: null,
        nome_condominio_imovel: null,
        outras_caracteristicas_imovel: null,
        vl_condominio: null,
        arquivo_agua_imovel: null,
        arquivo_gas_imovel: null,
        arquivo_condominio_imovel: null,
        arquivo_iptu_imovel: null,
        arquivo_energia_imovel: null,


        recebimento_pagamento: null,
        tipo_conta: null,
        banco: null,
        agencia: null,
        numero_conta: null,
        favorecido: null,
        operacao: null
    });

    const [proprietarioValue, setProprietarioValue] = useState<any>();

    const [sections, setSections] = useState<any>({
        identificacao: null,
        conjuge: null,
        residencia_atual: null,
        forma_recebimento: null,
        procurador: null
    });

    let nulos = [null, undefined];

    const handleClick = (name: string, value: boolean) => {
        setValueForm({
            ...valueForm,
            [name]: value
        });
    };

    const handleRejected = (value: boolean) => {
        setOpen(value);
    };

    const onchange = (e: any) => {
        const { name, value } = e.target;
        setProprietarioValue({
            ...proprietarioValue,
            [name]: value
        });
        if (name === 'motivoReprovacao') {
            setMotivo(value);
        }
    };

    const onSubmitReprovacao = async (e: any) => {
        try {
            e.preventDefault();

            if (proprietarioId !== null && motivo !== '') {
                setLoader(true)
                const data = {
                    proprietario_id: proprietarioId,
                    etapa_id: 7,
                    comentario: motivo ?? null,
                    imovel_proprietario_id: proprietarioValue?.imovel_proprietario?.id ?? null,
                }
                await axiosApi.post(`proprietario/status/reprovado`, data);
                setOpen(false);
                history.goBack();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const getProprietario = async () => {
        try {
            if (proprietarioId !== null) {
                setLoader(true)
                const { data } = await axiosApi.get(`/proprietario/${proprietarioId}`);
                setPropPDF(data?.data)
                setProprietarioValue(data?.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const getValidacao = async () => {
        try {
            if (proprietarioId !== null) {
                setLoader(true)
                const { data } = await axiosApi.get(`/validacao/proprietario/${proprietarioId}`);
                const validacoes = data?.data;
                setValueForm({ ...validacoes });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }


    const selectOneSecao = (secao: number, value: boolean) => {

        switch (secao) {
            case 0:
                const identificacaoPessoaFisicaArray = [];
                identificacaoPessoaFisicaArray.push(
                    { [`cpf_proprietario`]: value },
                    { [`nome_proprietario`]: value },
                    { [`rg_cnh_frente_proprietario`]: value },
                    { [`rg_cnh_verso_proprietario`]: value },
                    { [`telefone_proprietario`]: value },
                    { [`telefone2_proprietario`]: value },
                    { [`email_proprietario`]: value },
                    { [`estado_civil_proprietario`]: value },
                    { [`numb_dependentes_proprietario`]: value },
                );
                if (!nulos.includes(proprietarioValue?.informacoes_basicas?.profissao)) {
                    identificacaoPessoaFisicaArray.push({ [`profissao_proprietario`]: value });
                }
                if (!nulos.includes(proprietarioValue?.informacoes_basicas?.naturalidade)) {
                    identificacaoPessoaFisicaArray.push({ [`nacionalidade_proprietario`]: value });
                }
                if (!nulos.includes(proprietarioValue?.informacoes_basicas?.nacionalidade)) {
                    identificacaoPessoaFisicaArray.push({ [`naturalidade_proprietario`]: value });
                }

                const mergeObjectIdentificacaoPessoaFisica = identificacaoPessoaFisicaArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectIdentificacaoPessoaFisica });

            case 1:
                const atividadeProfissionalConjugeArray = [];
                atividadeProfissionalConjugeArray.push(
                    { [`nome_conjuge`]: value },
                    { [`telefone_conjuge`]: value },
                    { [`telefone2_conjuge`]: value },
                    { [`rg_cnh_frente_conjuge`]: value },
                    { [`rg_cnh_verso_conjuge`]: value },
                    { [`cpf_conjuge`]: value },
                    { [`email_conjuge`]: value },
                    { [`tipo_atividade_profissional_conjuge`]: value },
                    { [`renda_mensal_conjuge`]: value }
                );

                if (!nulos.includes(proprietarioValue?.conjuge?.informacoes_basicas.naturalidade)) {
                    atividadeProfissionalConjugeArray.push({ [`naturalidade_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.informacoes_basicas.nacionalidade)) {
                    atividadeProfissionalConjugeArray.push({ [`nacionalidade_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.nome_empresa)) {
                    atividadeProfissionalConjugeArray.push({ [`nome_empresa_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.informacoes_basicas?.profissao)) {
                    atividadeProfissionalConjugeArray.push({ [`profissao_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.tempo_empresa?.descricao)) {
                    atividadeProfissionalConjugeArray.push({ [`tempo_empresa_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.cargo_atual)) {
                    atividadeProfissionalConjugeArray.push({ [`cargo_atual_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`extrato_cartao_credito_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`contracheque_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.arquivo_ctps?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`carteira_trabalho_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`imposto_renda_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`extrato_bancario_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.arquivo_outro?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`outro_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.endereco?.cep)) {
                    atividadeProfissionalConjugeArray.push({ [`cep_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.endereco?.logradouro)) {
                    atividadeProfissionalConjugeArray.push({ [`logradouro_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.endereco?.numero)) {
                    atividadeProfissionalConjugeArray.push({ [`numero_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.endereco?.bairro)) {
                    atividadeProfissionalConjugeArray.push({ [`bairro_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.endereco?.cidade)) {
                    atividadeProfissionalConjugeArray.push({ [`cidade_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.endereco?.estado)) {
                    atividadeProfissionalConjugeArray.push({ [`estado_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(proprietarioValue?.conjuge?.atividade_profissional?.endereco?.complemento)) {
                    atividadeProfissionalConjugeArray.push({ [`complemento_empresa_atual_conjuge`]: value });
                }

                const mergeObjectAtividadeProfissionalConjuge = atividadeProfissionalConjugeArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectAtividadeProfissionalConjuge });
            case 2:
                const enderecoArray = [];
                enderecoArray.push(
                    { [`comprovante_endereco_residencia_atual`]: value },
                    { [`cep_residencia_atual`]: value },
                    { [`logradouro_residencia_atual`]: value },
                    { [`numero_residencia_atual`]: value },
                    { [`bairro_residencia_atual`]: value },
                    { [`cidade_residencia_atual`]: value },
                    { [`estado_residencia_atual`]: value }
                );

                if (!nulos.includes(proprietarioValue?.endereco?.complemento)) {
                    enderecoArray.push({ [`complemento_residencia_atual`]: value });

                }
                if (!nulos.includes(proprietarioValue?.residencia_atual?.valor_aluguel)) {
                    enderecoArray.push({ [`valor_aluguel_residencia_atual`]: value });

                }
                if (!nulos.includes(proprietarioValue?.residencia_atual?.tempo_locacao)) {
                    enderecoArray.push({ [`tempo_locacao_residencia_atual`]: value });

                }

                const mergeObjectEndereco = enderecoArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectEndereco });
            case 3:
                const formaRecebimentoArray = [];
                if (!nulos.includes(proprietarioValue?.forma_recebimento?.descricao)) {
                    formaRecebimentoArray.push({ [`recebimento_pagamento`]: value });
                }
                if (!nulos.includes(proprietarioValue?.dados_bancarios?.tipo_conta?.descricao)) {
                    formaRecebimentoArray.push({ [`tipo_conta`]: value });
                }
                if (!nulos.includes(proprietarioValue?.dados_bancarios?.favorecido)) {
                    formaRecebimentoArray.push({ [`outra_pessoa_titular_conta`]: value });
                }
                if (!nulos.includes(proprietarioValue?.dados_bancarios?.banco)) {
                    formaRecebimentoArray.push({ [`banco`]: value });
                }
                if (!nulos.includes(proprietarioValue?.dados_bancarios?.favorecido)) {
                    formaRecebimentoArray.push({ [`favorecido`]: value });
                }
                if (!nulos.includes(proprietarioValue?.dados_bancarios?.cpf_favorecido)) {
                    formaRecebimentoArray.push({ [`cpf_favorecido`]: value });
                }
                if (!nulos.includes(proprietarioValue?.dados_bancarios?.agencia)) {
                    formaRecebimentoArray.push({ [`agencia`]: value });
                }
                if (!nulos.includes(proprietarioValue?.dados_bancarios?.operacao)) {
                    formaRecebimentoArray.push({ [`operacao`]: value });
                }
                if (!nulos.includes(proprietarioValue?.dados_bancarios?.conta)) {
                    formaRecebimentoArray.push({ [`numero_conta`]: value });
                }

                const mergeObject = formaRecebimentoArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObject });
            case 4:
                const procuradorArray = [];
                procuradorArray.push(
                    { [`nome_procurador`]: value },
                    { [`telefone_procurador`]: value },
                    { [`telefone2_procurador`]: value },
                    { [`rg_cnh_frente_procurador`]: value },
                    { [`rg_cnh_verso_procurador`]: value },
                    { [`cpf_procurador`]: value },
                    { [`email_procurador`]: value },
                    { [`estado_civil_procurador`]: value },
                    { [`cep_procurador`]: value },
                    { [`logradouro_procurador`]: value },
                    { [`numero_procurador`]: value },
                    { [`bairro_procurador`]: value },
                    { [`cidade_procurador`]: value },
                    { [`estado_procurador`]: value },
                    { [`documento_procurador`]: value },
                );

                if (!nulos.includes(proprietarioValue?.procurador?.informacao_basica?.naturalidade)) {
                    procuradorArray.push({ [`naturalidade_procurador`]: value });
                }
                if (!nulos.includes(proprietarioValue?.procurador?.informacao_basica?.nacionalidade)) {
                    procuradorArray.push({ [`nacionalidade_procurador`]: value });
                }
                if (!nulos.includes(proprietarioValue?.procurador?.informacao_basica?.profissao)) {
                    procuradorArray.push({ [`profissao_procurador`]: value });
                }
                if (!nulos.includes(proprietarioValue?.procurador?.endereco?.complemento)) {
                    procuradorArray.push({ [`complemento_procurador`]: value });
                }

                const mergeObjectProcurador = procuradorArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectProcurador });
            case 5:
                const identificacaoPessoaJuridicaArray = [];
                identificacaoPessoaJuridicaArray.push(
                    { [`razao_social_proprietario`]: value },
                    { [`nome_fantasia_proprietario`]: value },
                    { [`cnpj_proprietario`]: value },
                    { [`email_proprietario`]: value },
                    { [`telefone_proprietario`]: value },
                    { [`telefone2_proprietario`]: value },
                    { [`cep`]: value },
                    { [`logradouro`]: value },
                    { [`numero`]: value },
                    { [`bairro`]: value },
                    { [`cidade`]: value },
                    { [`estado`]: value },
                );

                if (!nulos.includes(proprietarioValue?.endereco?.complemento)) {
                    identificacaoPessoaJuridicaArray.push({ [`complemento`]: value });
                }
                if (!nulos.includes(proprietarioValue?.arquivos_empresa?.arquivo_inscricao_cnpj?.nome)) {
                    identificacaoPessoaJuridicaArray.push({ [`cartao_inscricao_cnpj`]: value });
                }
                if (!nulos.includes(proprietarioValue?.arquivos_empresa?.arquivo_contrato_social?.nome)) {
                    identificacaoPessoaJuridicaArray.push({ [`contrato_social_proprietario`]: value });
                }
                if (!nulos.includes(proprietarioValue?.arquivos_empresa?.arquivo_ultimo_aditivo_contrato?.nome)) {
                    identificacaoPessoaJuridicaArray.push({ [`ultimo_aditivo_contrato`]: value });
                }
                if (!nulos.includes(proprietarioValue?.arquivos_empresa?.imposto_renda_pj?.nome)) {
                    identificacaoPessoaJuridicaArray.push({ [`imposto_renda_pj`]: value });
                }

                const mergeObjectIdentificacaoPessoaJuridica = identificacaoPessoaJuridicaArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectIdentificacaoPessoaJuridica });
            case 6:
                const representantes = [] as any;
                const representantesArray = Object.keys(proprietarioValue?.representantes).map((item, index) => {
                    const numberRepresentante = index + 1;
                    representantes.push(
                        { [`nome_representante${numberRepresentante}`]: value },
                        { [`email_representante${numberRepresentante}`]: value },
                        { [`telefone1_representante${numberRepresentante}`]: value },
                        { [`telefone2_representante${numberRepresentante}`]: value },
                        { [`rg_cnh_frente_representante${numberRepresentante}`]: value },
                        { [`rg_cnh_verso_representante${numberRepresentante}`]: value },
                        { [`cpf_representante${numberRepresentante}`]: value },
                        { [`estado_civil_representante${numberRepresentante}`]: value },
                        { [`cep_representante${numberRepresentante}`]: value },
                        { [`logradouro_representante${numberRepresentante}`]: value },
                        { [`numero_representante${numberRepresentante}`]: value },
                        { [`complemento_representante${numberRepresentante}`]: value },
                        { [`bairro_representante${numberRepresentante}`]: value },
                        { [`cidade_representante${numberRepresentante}`]: value },
                        { [`estado_representante${numberRepresentante}`]: value },
                        { [`comprovante_residencia_representante${numberRepresentante}`]: value },
                        { [`imposto_renda_pj_representante${numberRepresentante}`]: value },
                        { [`extrato_bancario_representante${numberRepresentante}`]: value },
                        { [`outros_documentos_representante${numberRepresentante}`]: value },
                        { [`naturalidade_representante${numberRepresentante}`]: value },
                        { [`nacionalidade_representante${numberRepresentante}`]: value },
                    );

                    const mergeObjectRepresentante = representantes.reduce(function (result: any, current: any) {
                        return Object.assign(result, current);
                    }, {});

                    return mergeObjectRepresentante;

                });
                const lastElem = representantesArray[representantesArray.length - 1];
                return setValueForm({ ...valueForm, ...lastElem });
        }
    }

    const labelsRepresentantes = () => {
        const labelsRepresentantes = [] as any;
        Object.keys(proprietarioValue?.representantes ?? []).map((item, index) => {
            const numberRepresentante = index + 1;
            return labelsRepresentantes.push(
                `nome_representante${numberRepresentante}`,
                `email_representante${numberRepresentante}`,
                `telefone1_representante${numberRepresentante}`,
                `telefone2_representante${numberRepresentante}`,
                `rg_cnh_frente_representante${numberRepresentante}`,
                `rg_cnh_verso_representante${numberRepresentante}`,
                `cpf_representante${numberRepresentante}`,
                `estado_civil_representante${numberRepresentante}`,
                `cep_representante${numberRepresentante}`,
                `logradouro_representante${numberRepresentante}`,
                `numero_representante${numberRepresentante}`,
                `complemento_representante${numberRepresentante}`,
                `bairro_representante${numberRepresentante}`,
                `cidade_representante${numberRepresentante}`,
                `estado_representante${numberRepresentante}`,
                `comprovante_residencia_representante${numberRepresentante}`,
                `imposto_renda_pj_representante${numberRepresentante}`,
                `extrato_bancario_representante${numberRepresentante}`,
                `outros_documentos_representante${numberRepresentante}`,
                `nacionalidade_representante${numberRepresentante}`,
                `naturalidade_representante${numberRepresentante}`,
            );
        });
        return labelsRepresentantes;
    }

    const secaoItsFull = (secao: number) => {

        const values = [
            [],
            [],
            [],
            [],
            [],
            // pessoa juridica
            //05
            [
                "razao_social_proprietario",
                "nome_fantasia_proprietario",
                "cnpj_proprietario",
                "email_proprietario",
                "telefone_proprietario",
                "telefone2_proprietario",
                "cep",
                "logradouro",
                "numero",
                "bairro",
                "cidade",
                "estado"
            ],
            //06
            labelsRepresentantes(),
        ];


        if (!nulos.includes(proprietarioValue?.informacoes_basicas?.cpf)) {
            values[0].push("cpf_proprietario");
        }
        if (!nulos.includes(proprietarioValue?.informacoes_basicas?.nome_cliente)) {
            values[0].push("nome_proprietario");
        }
        if (!nulos.includes(proprietarioValue?.informacoes_basicas?.rg_cnh_frente?.nome)) {
            values[0].push("rg_cnh_frente_proprietario");
        }
        if (!nulos.includes(proprietarioValue?.informacoes_basicas?.rg_cnh_verso?.nome)) {
            values[0].push("rg_cnh_verso_proprietario");
        }
        if (!nulos.includes(proprietarioValue?.informacoes_basicas?.telefone1)) {
            values[0].push("telefone_proprietario");
        }
        if (!nulos.includes(proprietarioValue?.informacoes_basicas?.telefone2)) {
            values[0].push("telefone2_proprietario");
        }
        if (!nulos.includes(proprietarioValue?.informacoes_basicas?.email)) {
            values[0].push("email_proprietario");
        }
        if (!nulos.includes(proprietarioValue?.informacoes_basicas?.estado_civil?.descricao)) {
            values[0].push("estado_civil_proprietario");
        }
        if (!nulos.includes(proprietarioValue?.informacoes_basicas?.qtd_dependentes)) {
            values[0].push("numb_dependentes_proprietario");
        }
        if (!nulos.includes(proprietarioValue?.informacoes_basicas?.nacionalidade)) {
            values[0].push("nacionalidade_proprietario");
        }
        if (!nulos.includes(proprietarioValue?.informacoes_basicas?.naturalidade)) {
            values[0].push("naturalidade_proprietario");
        }
        if (!nulos.includes(proprietarioValue?.informacoes_basicas?.profissao)) {
            values[0].push("profissao_proprietario");
        }


        if (!nulos.includes(proprietarioValue?.conjuge?.informacoes_basicas?.nome_cliente)) {
            values[1].push("nome_conjuge");
        }
        if (!nulos.includes(proprietarioValue?.conjuge?.informacoes_basicas?.email)) {
            values[1].push("email_conjuge");
        }
        if (!nulos.includes(proprietarioValue?.conjuge?.informacoes_basicas?.telefone2)) {
            values[1].push("telefone_conjuge");
        }
        if (!nulos.includes(proprietarioValue?.conjuge?.informacoes_basicas?.telefone2)) {
            values[1].push("telefone2_conjuge");
        }
        if (!nulos.includes(proprietarioValue?.conjuge?.informacoes_basicas?.rg_cnh_frente?.nome)) {
            values[1].push("rg_cnh_frente_conjuge");
        }
        if (!nulos.includes(proprietarioValue?.conjuge?.informacoes_basicas?.rg_cnh_verso?.nome)) {
            values[1].push("rg_cnh_verso_conjuge");
        }
        if (!nulos.includes(proprietarioValue?.conjuge?.informacoes_basicas?.cpf)) {
            values[1].push("cpf_conjuge");
        }
        if (!nulos.includes(proprietarioValue?.conjuge?.informacoes_basicas?.naturalidade)) {
            values[1].push("naturalidade_conjuge");
        }
        if (!nulos.includes(proprietarioValue?.conjuge?.informacoes_basicas?.nacionalidade)) {
            values[1].push("nacionalidade_conjuge");
        }
        if (!nulos.includes(proprietarioValue?.conjuge?.informacoes_basicas?.profissao)) {
            values[1].push("profissao_conjuge");
        }


        if (!nulos.includes(proprietarioValue?.residencia_atual?.arquivo?.nome)) {
            values[2].push("comprovante_endereco_residencia_atual");
        }
        if (!nulos.includes(proprietarioValue?.endereco?.cep)) {
            values[2].push("cep_residencia_atual");
        }
        if (!nulos.includes(proprietarioValue?.endereco?.logradouro)) {
            values[2].push("logradouro_residencia_atual");
        }
        if (!nulos.includes(proprietarioValue?.endereco?.numero)) {
            values[2].push("numero_residencia_atual");
        }
        if (!nulos.includes(proprietarioValue?.endereco?.complemento)) {
            values[2].push("complemento_residencia_atual");
        }
        if (!nulos.includes(proprietarioValue?.endereco?.bairro)) {
            values[2].push("bairro_residencia_atual");
        }
        if (!nulos.includes(proprietarioValue?.endereco?.cidade)) {
            values[2].push("cidade_residencia_atual");
        }
        if (!nulos.includes(proprietarioValue?.endereco?.estado)) {
            values[2].push("estado_residencia_atual");
        }
        if (!nulos.includes(proprietarioValue?.residencia_atual?.valor_aluguel)) {
            values[2].push("valor_aluguel_residencia_atual");
        }
        if (!nulos.includes(proprietarioValue?.residencia_atual?.tempo_locacao)) {
            values[2].push("tempo_locacao_residencia_atual");
        }


        if (!nulos.includes(proprietarioValue?.forma_recebimento?.descricao)) {
            values[3].push("recebimento_pagamento");
        }
        if (!nulos.includes(proprietarioValue?.dados_bancarios?.tipo_conta?.descricao)) {
            values[3].push("tipo_conta");
        }
        if (!nulos.includes(proprietarioValue?.dados_bancarios?.titular_conta)) {
            values[3].push("outra_pessoa_titular_conta");
        }
        if (!nulos.includes(proprietarioValue?.dados_bancarios?.banco)) {
            values[3].push("banco");
        }
        if (!nulos.includes(proprietarioValue?.dados_bancarios?.favorecido)) {
            values[3].push("favorecido");
        }
        if (!nulos.includes(proprietarioValue?.dados_bancarios?.cpf_favorecido)) {
            values[3].push("cpf_favorecido");
        }
        if (!nulos.includes(proprietarioValue?.dados_bancarios?.agencia)) {
            values[3].push("agencia");
        }
        if (!nulos.includes(proprietarioValue?.dados_bancarios?.operacao)) {
            values[3].push("operacao");
        }
        if (!nulos.includes(proprietarioValue?.dados_bancarios?.conta)) {
            values[3].push("numero_conta");
        }


        if (!nulos.includes(proprietarioValue?.procurador?.informacao_basica?.nome_cliente)) {
            values[4].push("nome_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.informacao_basica?.telefone1)) {
            values[4].push("telefone_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.informacao_basica?.telefone2)) {
            values[4].push("telefone2_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.informacao_basica?.rg_cnh_frente?.nome)) {
            values[4].push("rg_cnh_frente_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.informacao_basica?.rg_cnh_verso?.nome)) {
            values[4].push("rg_cnh_verso_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.informacao_basica?.naturalidade)) {
            values[4].push("naturalidade_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.informacao_basica?.nacionalidade)) {
            values[4].push("nacionalidade_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.informacao_basica?.profissao)) {
            values[4].push("profissao_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.informacao_basica?.cpf)) {
            values[4].push("cpf_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.informacao_basica?.email)) {
            values[4].push("email_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.informacao_basica?.estado_civil?.descricao)) {
            values[4].push("estado_civil_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.documento_procuracao?.nome)) {
            values[4].push("documento_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.endereco?.cep)) {
            values[4].push("cep_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.endereco?.logradouro)) {
            values[4].push("logradouro_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.endereco?.numero)) {
            values[4].push("numero_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.endereco?.complemento)) {
            values[4].push("complemento_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.endereco?.bairro)) {
            values[4].push("bairro_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.endereco?.cidade)) {
            values[4].push("cidade_procurador");
        }
        if (!nulos.includes(proprietarioValue?.procurador?.endereco?.estado)) {
            values[4].push("estado_procurador");
        }

        if (!nulos.includes(proprietarioValue?.arquivos_empresa?.arquivo_inscricao_cnpj?.nome)) {
            values[5].push("cartao_inscricao_cnpj");
        }
        if (!nulos.includes(proprietarioValue?.arquivos_empresa?.arquivo_contrato_social?.nome)) {
            values[5].push("contrato_social_proprietario");
        }
        if (!nulos.includes(proprietarioValue?.arquivos_empresa?.arquivo_ultimo_aditivo_contrato?.nome)) {
            values[5].push("ultimo_aditivo_contrato");
        }
        if (!nulos.includes(proprietarioValue?.arquivos_empresa?.imposto_renda_pj?.nome)) {
            values[5].push("imposto_renda_pj");
        }


        const isFullTrue = Object.values(values[secao]).every(item => valueForm[`${item}`] === true);
        const isFullFalse = Object.values(values[secao]).every(item => valueForm[`${item}`] === false);

        return isFullTrue && !isFullFalse ? isFullTrue : !isFullTrue && isFullFalse ? !isFullFalse : null;
    }

    const ComponentFormulario = (pessoaId: number) => {
        switch (pessoaId) {
            case 1:
                return (
                    <FormularioCadastroProprietarioPessoaFisica
                        proprietario={proprietarioValue}
                        campos={valueForm}
                        handleClick={handleClick}
                        selectSection={selectOneSecao}
                        sections={sections}
                        onChange={onchange}
                        openModal={handleRejected}
                        itsView={itsView}
                    />);
            case 2:
                return (
                    <FormularioCadastroProprietarioPessoaJuridica
                        proprietario={proprietarioValue}
                        campos={valueForm}
                        handleClick={handleClick}
                        selectSection={selectOneSecao}
                        sections={sections}
                        onChange={onchange}
                        openModal={handleRejected}
                        itsView={itsView}
                    />);
            default:
                return null;
        }
    }

    const onTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        getValidacao();
        getProprietario();
        onTop();
    }, []);

    useEffect(() => {
        setSections({
            ...sections,
            identificacao: secaoItsFull(0),
            conjuge: secaoItsFull(1),
            residencia_atual: secaoItsFull(2),
            forma_recebimento: secaoItsFull(3),
            procurador: secaoItsFull(4),
            identificacao_pessoa_juridica: secaoItsFull(5),
            representantes_legal: secaoItsFull(6),
        });
    }, [valueForm]);


    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
                <div>
                    <Modal
                        isOpen={open}
                        isClose={() => setOpen(false)}
                        headerStyle={''}
                        closeOnOutsideClick={true}
                        widthModal={`40%`}
                        modalStyle={`modal-style bg-white rounded-lg py-5 px-10`}
                    >
                        <div>
                            <form onSubmit={onSubmitReprovacao} className={`flex flex-col items-center`}>
                                <div className="flex">
                                    <p className="font-extrabold text-center text-2xl mb-4 font-mont-bold" style={{ color: "#081E52" }}>Adicione o motivo da reprovação</p>
                                </div>
                                <div className="flex w-full">
                                    <textarea
                                        rows={2}
                                        name={`motivoReprovacao`}
                                        value={motivo ?? null}
                                        style={{ maxHeight: "15rem", minHeight: "3.5rem" }}
                                        className="block p-4 w-full h-20 mt-1 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                                        placeholder={'Dê detalhes do porquê o cadastro foi reprovado'}
                                        required={true}
                                        onChange={onchange}
                                    />
                                </div>
                                <div className="flex w-full justify-center">
                                    <button
                                        type="submit"
                                        className={`flex w-full h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs bg-blue-mega-imoveis text-white cursor-pointer rounded-lg justify-center items-center`}
                                    >
                                        Enviar para o Proprietário
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Modal>
                    {ComponentFormulario(proprietarioValue?.tipo_pessoa_id)}
                </div>

            </Loader>

        </Fragment>
    );
}

export default FormularioValidateCadastroProprietario;