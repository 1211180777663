import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardTable from "src/components/Basicos/Cards/CardTable";
import { ajustUserAgent, converterDataBr } from "src/components/Basicos/funcoes";
import Loader from "src/components/Basicos/Loader";
import TableDetails from "src/components/Basicos/TableDetails";
import TimelimeCadastroImovel from "src/components/Basicos/Timeline/TimelineCadastroImovel";
// import TimelimeCadastroImovel from "src/components/Basicos/Timeline/TimelineCadastroImovel";
import TimelimeCadastroProprietario from "src/components/Basicos/Timeline/TimelineCadastroProprietario";
import { alertMsg, mask } from "src/helpers/functions";
import { axiosApi } from "src/services/axiosInstance";
import Procurador from "./Procurador";

type SedesResponsaveis = {
    id: number;
    nome: string | number;
};

const DetalhesCadastroProprietario: React.FunctionComponent = () => {
    const [proprietarioData, setProprietarioData] = useState<any>();
    const [sedesResponsavel, setSedesResponsavel] = useState<any>();
    const [historico, setHistorico] = useState<any>([]);
    const [imoveis, setImoveis] = useState<any>([]);
    const [etapaAtual, setEtapaAtual] = useState(0);
    const [linkImovel, setLinkImovel] = useState<any>();
    const [timeline, setTimeline] = useState<any>([]);
    const [loader, setLoader] = useState(false);
    const [valueOption, setValueOption] = useState();
    const [procuradores, setProcuradores] = useState<any>([]);

    let { proprietarioId }: any = useParams();

    const onTop = () => {
        window.scrollTo(0, 0);
    }

    const getProprietario = async () => {
        try {
            if (proprietarioId !== null) {
                setLoader(true)
                const { data } = await axiosApi.get(`/proprietario/${proprietarioId}`);
                setProprietarioData(data?.data);
                setHistorico(data?.data?.historico);
                setProcuradores(data.data.procuradores)
                getImoveis();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const getImoveis = async () => {
        try {
            const { data } = await axiosApi.get(`/linha-tempo/adm/proprietario/${proprietarioId}`);
            setImoveis(data.data.imoveis_proprietario_timeline);
        } catch (error) {
            console.log(error);
        }
    }

    const getLinhaTempo = async () => {
        try {
            if (proprietarioId !== null) {
                setLoader(true)
                const { data } = await axiosApi.get(`/linha-tempo/proprietario/${proprietarioId}`);
                setTimeline(data?.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const getOptionSedesResponsavel = async () => {
        try {
            setLoader(true);
            const { data } = await axiosApi.get('/sede-responsavel');
            const sedeResponsavel = data.data.map((el: SedesResponsaveis) => ({
                label: el.nome,
                value: el.id,
            }));
            setSedesResponsavel(sedeResponsavel);
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    const onChangeSelect = (item: any, action: any) => {
        setProprietarioData({
            ...proprietarioData,
            [action.name]: item.value
        })
    }

    const statusTimeLine = async (status_id: number) => {
        try {
            if (proprietarioId !== null && etapaAtual !== 0) {
                setLoader(true)

                const dataForm = {
                    proprietario_id: Number(proprietarioId),
                    imovel_proprietario_id: proprietarioData?.imovel_proprietario?.id,
                    etapa_id: etapaAtual,
                    comentario: linkImovel ?? null
                }
                const pathName = { '10': 'realizado', '11': 'nao-realizado' }
                await axiosApi.post(`/proprietario/status/${pathName[status_id]}`, dataForm);
            }

        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
            setEtapaAtual(0);
            getLinhaTempo();
            getProprietario();
        }
    }

    const imovel = [
        { header: "Código do Imóvel", value: proprietarioData?.locatario?.imovel_pretendido?.imovel?.codigo_imovel },
        { header: "Locatário", value: proprietarioData?.locatario?.informacoes_basicas?.nome_cliente },
        { header: "Garantia", value: proprietarioData?.locatario?.garantia?.descricao },
        { header: "Quem cadastrou?", value: proprietarioData?.locatario?.cadastrou },
    ];

    const proprietario = [
        { header: "Nome", value: proprietarioData?.informacoes_basicas?.nome_cliente === null ? proprietarioData?.informacoes_basicas?.razao_social : proprietarioData?.informacoes_basicas?.nome_cliente },
        { header: "Email", value: proprietarioData?.informacoes_basicas?.email },
        { header: "Telefone", value: mask(proprietarioData?.informacoes_basicas?.telefone1, 'telefone') },
        { header: "Data Solicitação", value: converterDataBr(proprietarioData?.created_at) },
        { header: "Sede", type: 'button', value: '' },
    ];

    const origemCadastro = [
        { header: "Data e Hora", value: converterDataBr(proprietarioData?.informacoes_dispositivo?.created_at) },
        { header: "Dispositivo", value: proprietarioData?.informacoes_dispositivo?.device_type },
        { header: "Versão", value: ajustUserAgent(proprietarioData?.informacoes_dispositivo?.user_agent) },
        { header: "Navegador", value: proprietarioData?.informacoes_dispositivo?.browser_name },
        { header: "Versão do Navegador", value: proprietarioData?.informacoes_dispositivo?.browser_version },
        { header: "Sistema Operacional", value: proprietarioData?.informacoes_dispositivo?.os_name }
    ];

    const InformacaoProcessoIsStack = proprietarioData?.locatario?.imovel_pretendido?.imovel?.codigo_imovel ||
        proprietarioData?.locatario?.informacoes_basicas?.nome_cliente ||
        proprietarioData?.locatario?.garantia?.descricao ||
        proprietarioData?.locatario?.cadastrou;

    const condition = (linkImovel === null || linkImovel === "" || linkImovel === " " || linkImovel === undefined);

    // console.log("****");
    const imovelSection = (imovel: any) => {
        return (
            <div>
                {imovel !== undefined ?
                    <TimelimeCadastroImovel
                        imovelId={imovel?.id}
                        timeline={imovel?.timeline}
                        setEtapaAtual={setEtapaAtual}
                        etapaAtual={0}
                        imovel={imovel}
                    /> : ""}
            </div>
        );
    }

    const procuradorSection = (procurador: any) => {
        return (
            <div>
                {procurador !== undefined ?
                    <Procurador
                        procurador={procurador}
                    /> : ""}
            </div>
        );
    }

    useEffect(() => {
        getProprietario();
        getLinhaTempo();
    }, []);

    useEffect(() => {
        onTop();
        getOptionSedesResponsavel();
    }, []);

    useEffect(() => {
        if (valueOption !== undefined) {
            if (etapaAtual === 8 && condition && valueOption === 10) {
                alertMsg("error", "Adicione o link do imóvel publicado");
            } else {
                statusTimeLine(valueOption);
            }
        }
    }, [valueOption]);

    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
                <div className="p-6">
                    <div className="bg-white p-10 flex flex-col space-y-20 rounded-lg border-gray-100 border h-full w-full" style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>
                        <CardTable
                            data={proprietario}
                            title={`Informações do Proprietário`}
                            key={1}
                            dataOptions={sedesResponsavel}
                            onChange={onChangeSelect}
                            dataForm={proprietarioData}
                        />
                        <CardTable
                            data={origemCadastro}
                            title={`Origem do Cadastro`}
                            key={2}
                        />

                        {InformacaoProcessoIsStack !== undefined ? <CardTable
                            data={imovel}
                            title={`Informações do Processo`}
                            key={3}
                        /> : ""}

                        <TimelimeCadastroProprietario
                            proprietarioId={proprietarioId}
                            timeline={timeline}
                            setStatusTimeLine={statusTimeLine}
                            setEtapaAtual={setEtapaAtual}
                            etapaAtual={etapaAtual}
                            setStateLinkImovel={setLinkImovel}
                            comentario={linkImovel}
                            setValueOption={setValueOption}
                            isPrincipal={true}
                        />

                        {/* <div className="mt-5">
                            <p className="font-light text-lg text-gray-system-mega-imoveis">Comentário</p>
                            <textarea
                                rows={2}
                                name={`comentario_fiador`}
                                style={{ maxHeight: "6rem", minHeight: "3.5rem" }}
                                className="block p-4 w-full h-32 mt-2 text-sm border-2 border-opacity-100 text-gray-system-mega-imoveis border-gray-system-mega-imoveis rounded-lg focus:outline-none placeholder-gray-700 "
                                placeholder={'Adicione um comentário'}
                            />
                        </div> */}

                        <div className="flex flex-col">
                            {imoveis?.length > 0 ? <div className="text-left font-extrabold text-xl mb-4 font-mont-bold">
                                <span>Imóveis</span>
                            </div> : ""}
                            {imoveis?.map((item: any) => {
                                return (imovelSection(item))
                            })}
                            {procuradores?.length > 0 ? <div className="text-left font-extrabold text-xl mb-4 font-mont-bold">
                                <span>Procuradores</span>
                            </div> : ""}
                            {procuradores?.map((item: any) => {
                                return (procuradorSection(item))
                            })}
                        </div>

                        <TableDetails
                            title="Histórico de Alterações"
                            historico={historico}
                        />

                    </div>
                </div>
            </Loader>
        </Fragment>
    );
}

export default DetalhesCadastroProprietario;