import React, { Fragment } from "react";
import { FaCheck, FaExclamation } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import { useControlContextMobile } from "src/contexts/MobileContext";
import './style.css'

export type Props = {
    id?: string;
    value?: string;
    name?: string;
    required?: boolean;
    onClick?: any;
    file?: any;
    className?: string;
    status?: boolean | null;
    isFile?: boolean;
    title?: string;
    hidden?: boolean
    itsButton?: boolean
}

export default function InputValidate({
    id,
    value,
    name,
    required = false,
    onClick,
    className = `flex w-auto h-12`,
    status,
    file = null,
    isFile = false,
    title,
    hidden = false,
    itsButton = false
}: Props): JSX.Element {
    const { mobile } = useControlContextMobile();

    const newWindows = (link: string) => {
        return link !== null ? window.open(file ?? '#', title, 'width=550,height=600') : console.log("not found");
    }
    return (
        <Fragment>
            <div className={`flex flex-row gap-2 w-full ${hidden ? 'hidden' : ''}`}>
                {itsButton ? "" : <div className="flex flex-col gap-1 justify-end items-center mb-1">
                    <button type={`button`} onClick={() => onClick(name, true)} className={`flex justify-center focus:outline-none items-center p-1 cursor-pointer rounded-full h-5 w-5 ${status ? 'bg-green-500' : 'bg-white shadow-md'}`}>
                        <span className={`flex p-1`}>
                            <FaCheck size={'0.9rem'} style={{ color: !status ? "#0DCB64" : "#FFFFFF" }} />
                        </span>
                    </button>

                    <button type={`button`} onClick={() => onClick(name, false)} className={`flex justify-center focus:outline-none items-center p-1 cursor-pointer rounded-full h-5 w-5 ${!status && status !== null ? 'bg-yellow-500' : 'bg-white shadow-md'}`}>
                        <span className={`flex p-1`}>
                            <FaExclamation size={'0.9rem'} style={{ color: !status && status !== null ? "#FFFFFF" : "#FFD80E" }} />
                        </span>
                    </button>
                </div>}
                <div className={`${mobile ? 'mt-2' : ''} flex flex-col ${mobile && window.innerWidth <= 375 && isFile ? "campo-mobile" : 'w-full'}`}>
                    <span className="ml-1 font-medium">{title}{required ? "*" : ""}</span>
                    <input
                        id={id}
                        name={name}
                        value={value ?? ``}
                        className={`${className} bg-gray-ligth-system-mega-imoveis focus:outline-none p-4 rounded-lg text-gray-system-mega-imoveis ${status ? 'border-green-500 border-4' : status !== null ? 'border-yellow-500 border-4' : 'border'} ${mobile && window.innerWidth <= 375 && !isFile ? "input-mobile-pequeno" : ''}`}
                        required={required}
                    />
                </div>
                {
                    isFile ? <div className="justify-end items-center mt-5 flex">
                        <button type={`button`} onClick={() => newWindows(file)} className={`flex justify-center focus:outline-none cursor-pointer bg-gray-ligth-system-mega-imoveis w-8 h-8 items-center rounded-lg border border-gray-system-mega-imoveis`}>
                            <span className={`flex p-1`}>
                                <FiEye size={`1.3rem`} style={{ color: "#807E7E" }} />
                            </span>
                        </button>
                    </div> : ""
                }
            </div>
        </Fragment>

    );
}
