import React from 'react';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import './styles.css';
import Modelo1 from 'src/components/PDF/ModelosImovel/Modelo1';

interface ComponenteProps {
    imovel?: any
}

const ComponentToPDF: React.FC<ComponenteProps> = ({ imovel }) => {
    const proprietarios = React.useMemo(() => {
        var proprietarios: Array<any> = [];
        for (var i = 0; i < imovel?.proprietarios.length; i++) {
            console.log('primeiro')
            proprietarios.push(imovel?.proprietarios[i]);
        }
        var a = proprietarios.findIndex((item: any) => item?.id === imovel?.proprietario_principal?.[0]?.id);
        proprietarios.splice(a, 1);
        proprietarios.unshift(imovel?.proprietario_principal?.[0]);

        return proprietarios;
    }, [imovel]);

    return (
        <>
            <Modelo1 imovel={imovel} proprietarios={proprietarios} />
        </>
    );
};

export default ComponentToPDF;