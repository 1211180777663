import React from "react";
import "../Pagination/style.css";

type Props = {
  onClickPrev?: any;
  onClickNext?: any;
  disabledPrev?: any;
  disabledNext?: any;
  pageLinks?: any;
};

// eslint-disable-next-line no-empty-pattern
function Pagination({
  onClickPrev,
  onClickNext,
  disabledPrev,
  disabledNext,
  pageLinks,
}: Props): JSX.Element {
  return (
    <div className="pagination flex justify-center space-x-4 py-3">
      <button
        onClick={onClickPrev}
        disabled={disabledPrev}
        type="button"
        className="flex h-10 p-2 focus:outline-none uppercase font-semibold border-blue-dark-mega-imoveis bg-blue-dark-mega-imoveis text-white border-2 cursor-pointer rounded-lg justify-center items-center">
        ANTERIOR
      </button>{" "}
      <div className="flex flex-row space-x-4 justify-center">{pageLinks}</div>
      <button
        onClick={onClickNext}
        disabled={disabledNext}
        type="button"
        className="flex h-10 p-2 focus:outline-none uppercase font-semibold border-blue-dark-mega-imoveis bg-blue-dark-mega-imoveis text-white border-2 cursor-pointer rounded-lg justify-center items-center">
        PROXIMO
      </button>{" "}
    </div>
  );
}
export default Pagination;
