import React, { memo } from "react";
import "../SlideButton/style.css";
// import { alertMsg } from "src/Helpers/functions";
type Props = {
  id: any;
  name: string;
  onChange: (e: any) => void;
  checked: any;
  title?: string;
  disabled?: boolean;
  messageErro?: string;
};

// eslint-disable-next-line no-empty-pattern
function SlideButton({
  id,
  name,
  checked,
  onChange,
  title,
  disabled = false,
  messageErro = 'Opção válida apenas para Usinas Geradoras',
}: Props): JSX.Element {

  // const getInforDisabled = (disabled: boolean) => disabled ? alertMsg('warning', messageErro) : null;
  return (
    <div className="flex slide">
      <label className="switch relative">
        <input
          id={id}
          name={name}
          type="checkbox"
          defaultChecked={checked == 1 ? true : false}
          onChange={onChange}
          disabled={disabled}
        />
        <span className={`${disabled ? 'sliderDisabled' : checked == 1 ? 'sliderTrue' : 'sliderFalse'} round`}></span>
      </label>
    </div>
  );
}
export default memo(SlideButton);
