import "./style.css";
import React, { Fragment } from "react";

type Props = {
  fixed?: boolean;
  overlay?: boolean;
  children: JSX.Element | Array<JSX.Element>;
  isActive: boolean;
  hide?: string;
  auth?: boolean
};

const Loader = ({
  fixed,
  overlay,
  children,
  isActive,
  hide,
  auth = false
}: Props): JSX.Element => {
  return (
    <Fragment>
      {isActive && (
        <React.Fragment>
          <div className={`${hide}`}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              zIndex: 9999999999,
            }}
          >
            <div
              className="loader"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                zIndex: 99999999999,
              }}
            />
          </div>
          <React.Fragment>
            {children}
          </React.Fragment>
        </React.Fragment>
      )}
      {!isActive && children}
    </Fragment>
  );
};

export default Loader;
