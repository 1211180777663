import React, { Fragment, useState, useEffect } from "react";
import CardMidiaNoticia from "src/components/Basicos/Cards/CardMidiaNoticia";
import Inputlabel from "src/components/Basicos/Form/Input/InputLabel";
import InputSelect from "src/components/Basicos/Form/Input/InputSelect";
import { dateAmerican, dateToPtBr } from "src/helpers/functions";
import { axiosApi } from "src/services/axiosInstance";
import { Props, Option, Data, TipoNoticias, ListItems } from "./types";

export default function EditNoticia({
    noticiaId,
    isClose
}: Props): JSX.Element {
    const [listPreviewPhoto, setListPreviewPhoto] = useState<Array<ListItems>>([]);
    const [tiposNoticias, setTiposNoticias] = useState<Array<Option>>([]);
    const [dataForm, setDataForm] = useState<Data>({});
    const [isFiles, setIsFiles] = useState<boolean>(false);

    const Multipart = new FormData();

    const onClickDeleteMidiaCard = (id: number) => {
        setListPreviewPhoto(listPreviewPhoto.filter(function (photo) {
            return photo.id !== id
        }));
    }
    const onClickEditMidiaCard = (id: number) => {
        console.log("editar a imagem de ID: ", id);
    }

    const handleClosedModal = () => {
        setDataForm({})
        setListPreviewPhoto([{}])
        isClose()
        setIsFiles(false)
    }

    const getNoticias = async () => {
        try {
            const { data } = await axiosApi.get(`/noticias/${noticiaId}`);
            const noticia = data.data;
            setDataForm(noticia);
            if (noticia.arquivos.length > 0) {
                setIsFiles(true)
            }
            noticia.arquivos.map((item: any) => {
                listPreviewPhoto.push({
                    id: item?.arquivo_id,
                    name: item?.imagem?.nome,
                    raw: item?.imagem,
                    url_image: item?.imagem?.caminho
                });
            });

        } catch (error) {
            console.log(error);
        }
    }

    const getTiposNoticias = async () => {
        try {
            const { data } = await axiosApi.get('/tipo-noticia');
            const tipoNoticia = data.data.map((el: TipoNoticias) => ({
                label: el.descricao,
                value: el.id,
            }));
            setTiposNoticias(tipoNoticia);
        } catch (error) {
            console.log(error);
        }
    }

    // const onChangeSelect = (name: string, value: number): void => {
    //     if (name === 'tipo_noticia') {
    //         setDataForm({
    //             ...dataForm,
    //             tipo_noticia_id: value
    //         })
    //     }
    // }

    const onChangeSelect = (item: any, action: any) => {
        setDataForm({
            ...dataForm,
            [action.name]: item.value
        })
    }

    const onChangeDate = (event: any) => {
        const { name, value } = event.target;
        setDataForm({
            ...dataForm,
            [name]: dateToPtBr(String(value))
        })
    }

    const onChange = (event: any) => {
        const { name, value } = event.target;
        if (name === "video_link") {
            setDataForm({
                ...dataForm,
                url_video: value
            })
        }
        setDataForm({
            ...dataForm,
            [name]: value
        })
    };

    const onSubmit = async (e: any) => {
        e.preventDefault();
        try {
            Multipart.append('titulo', dataForm?.titulo as Blob);
            Multipart.append('sub_titulo', dataForm?.sub_titulo as Blob);
            Multipart.append('texto', dataForm?.texto as Blob);
            Multipart.append('data_inicial', dataForm?.data_inicial as Blob);
            Multipart.append('data_final', dataForm?.data_final as Blob);
            Multipart.append('tipo_noticia_id', dataForm?.tipo_noticia_id as Blob);
            Multipart.append('url_video', dataForm?.url_video as Blob);
            Multipart.append('_method', 'put');

            listPreviewPhoto.map((item, index: number) => {
                Multipart.append(`arquivos[${index}]`, item.id === String(item?.raw?.lastModified) ? item.raw : item.id);
            })

            await axiosApi.post(`/noticia/${noticiaId}`, Multipart).then((response) => {
                handleClosedModal()
                window.location.reload();
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getTiposNoticias()
        getNoticias()
    }, [])

    return (
        <Fragment>
            <form onSubmit={onSubmit}>
                <div className="w-full flex-col flex p-8 overflow-x-auto">
                    <div>
                        <Inputlabel
                            type={"text"}
                            className={`block p-4 w-full text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none`}
                            name={"titulo"}
                            placeholder={`Título`}
                            value={dataForm?.titulo ?? ""}
                            onChange={onChange}
                            required={true}
                        />
                        <Inputlabel
                            type={"text"}
                            className={`block p-4 w-full mt-2 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none`}
                            name={"sub_titulo"}
                            placeholder={`Subtítulo`}
                            value={dataForm?.sub_titulo ?? ""}
                            onChange={onChange}
                            required={true}
                        />
                    </div>
                    <div>
                        <textarea
                            rows={3}
                            name={`texto`}
                            value={dataForm?.texto ?? ""}
                            style={{ maxHeight: "20rem", minHeight: "3.5rem" }}
                            className="block p-4 w-full h-48 mt-2 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                            placeholder={'Corpo da Notícia'}
                            onChange={onChange}
                            required={true}
                        />
                    </div>
                    <div className="flex flex-col md:flex-row sm:flex-col py-3 gap-2">
                        <div className="flex w-full lg:w-3/3 mb-12 lg:mb-0 flex-col lg:flex-row gap-2">

                            <Inputlabel
                                type={`date`}
                                className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 p-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full`}
                                colClassname={`w-full`}
                                name={"data_inicial"}
                                placeholder={`Período Início Vigência`}
                                value={dateAmerican(dataForm?.data_inicial) ?? ""}
                                onChange={onChangeDate}
                                titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
                                required={true}
                            />
                            <Inputlabel
                                type={`date`}
                                className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4`}
                                colClassname={`w-full`}
                                name={"data_final"}
                                placeholder={`Período Fim Vigência`}
                                value={dateAmerican(dataForm?.data_final) ?? ""}
                                onChange={onChangeDate}
                                titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
                                required={true}
                            />
                            <InputSelect
                                name={`tipo_noticia_id`}
                                options={tiposNoticias}
                                className={`bg-white w-full text-gray-900 text-sm border-gray-300 border rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2`}
                                colClassname={`w-full`}
                                onChange={onChangeSelect}
                                placeholder={"Tipo"}
                                value={tiposNoticias?.filter((option) => (option?.value === dataForm?.tipo_noticia_id))}
                            />
                        </div>
                    </div>
                    {/* card midia */}
                    <CardMidiaNoticia
                        isFilesMidia={isFiles}
                        ArrayPhotos={listPreviewPhoto}
                        onClickDelete={onClickDeleteMidiaCard}
                        onClickEdit={onClickEditMidiaCard}
                        onChangeVideo={onChange}
                        onChangePhotos={setListPreviewPhoto}
                    />

                    <div className="flex flex-row gap-2 justify-center mt-6">
                        <button type={`button`} onClick={handleClosedModal} className="p-3 uppercase text-gray-600 font-semibold rounded-lg bg-gray-400 w-40 focus:outline-none">
                            cancelar
                        </button>
                        <button type={`submit`} className="p-3 uppercase text-white font-semibold rounded-lg bg-gray-400 w-40 bg-blue-mega-imoveis focus:outline-none" >
                            salvar
                        </button>
                    </div>
                </div>
            </form>
        </Fragment>
    );
}