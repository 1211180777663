import React from 'react';
import { ClassicProps } from 'src/@types/types';
import { formatarDataRestricao, formatarValorRestricao } from 'src/components/Basicos/funcoes';

type Props = {
    restricao?: any;
};

export default function Protestos({
    restricao
}: Props & ClassicProps): JSX.Element {

    return (
        <>
            <div className='flex'>
                <h1>Tipo:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.tipo}</h1>
                <h1 className='ml-2'>Data Ocorrência:</h1>
                <h1 className='ml-2 cor-cinza'>{formatarDataRestricao(restricao?.dataOcorrencia)}</h1>
            </div>
            <div className='flex'>
                <h1>Número Cheque:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.numerocheque}</h1>
                <h1 className='ml-2'>Avalista:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.avalista}</h1>
            </div>
            <div className='flex'>
                <h1>Tipo de Moeda:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.tipoMoeda}</h1>
                <h1 className='ml-2'>Valor:</h1>
                <h1 className='ml-2 cor-cinza'>{formatarValorRestricao(restricao?.valor)}</h1>
            </div>
            <div className='flex mb-4'>
                <h1>Cartório:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.cartorio}</h1>
                <h1 className='ml-2'>Cidade:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.cidade}</h1>
                <h1 className='ml-2'>Estado:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.uf}</h1>
            </div>
        </>
    );
}
