import * as actionTypes from "../types";

const initialState: actionTypes.AuthState = {
  access_token: {
    token: localStorage.getItem("token"),
    usuario: JSON.parse(localStorage.getItem("usuario") ?? "null"),
  },
};

export default (
  state = initialState,
  { type, payload }: actionTypes.AuthAction
) => {
  switch (type) {
    case actionTypes.UPDATE_TOKEN:
      return {
        ...state,
        access_token: {
          token: payload.access_token?.token,
        },
      };

    case actionTypes.INITIAL_STATE:
    case actionTypes.UPDATE_PERFIL:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
