import React from 'react';

interface ISelectInputProps {
    options: {
        value?: number | undefined;
        label?: string | undefined;
    }[],
}

const SelectInput: React.FC<ISelectInputProps> = ({ options }) => {

    return (
        <select className="modal-input mt-3 p-1" name="id_categoria">
            {
                options.map((option) => (
                    <option
                        value={option.value}
                        key={option.value}
                    >{option.label}</option>
                ))
            }
        </select>
    );
}

export default SelectInput;