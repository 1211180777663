import React, { useState } from "react";
import './style.css';

const ButtonGroupMobile = ({ buttons, doSomethingAfterClick }) => {
  const [clickedId, setClickedId] = useState(0);

  const handleClick = (event, id) => {
    setClickedId(id);
    doSomethingAfterClick(event);
  };

  const classNameRoundedButton = (clickedId) => {
    switch (clickedId) {
      case 0:
        return "rounded-l-xl";
      case 2:
        return "rounded-r-xl";
      default:
        return "";
    }
  };

  return (
    <div className="w-auto h-10 border rounded-xl buttons-mobile" style={{ borderColor: '#6C7AFF' }}>
      {buttons.map((buttonLabel, i) => (
        <button
          key={i}
          name={buttonLabel}
          value={i}
          onClick={(event) => handleClick(event, i)}
          className={i === clickedId ? `text-white text-sm bg-gradient-to-r from-blue-mega-imoveis to-blue-mega-imoveis w-auto h-full focus:outline-none px-3 ${classNameRoundedButton(i)}` : "text-indigo-500 w-auto h-full text-sm focus:outline-none px-3"}
        >
          {buttonLabel}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroupMobile;