import React from 'react';
import { dateLaravelToPtBr, dateToPtBr } from 'src/helpers/functions';
import './style.css';
import PendenciasFinanceiras from './RestricoesPJ/pendenciaFinanceira';
import PendenciasInternas from './RestricoesPJ/pendenciasInternas';
import ChequeSemFundo from './RestricoesPJ/chequeSemFundo';
import ConsultaSerasa from './RestricoesPJ/consultaSerasa';
import Protestos from './RestricoesPJ/protestos';

type Props = {
    infos: any;
    conjuge?: any;
    timeline?: any;
}

const ModalPJMobile: React.FC<Props> = ({
    infos,
    conjuge,
    timeline
}: Props): JSX.Element => {
    return (
        <div className={conjuge != null ? `modal-analise-financeira-filho-1` : `modal-analise-financeira-filho-2`}>
            <div className='flex header-box mt-2 mb-2'>
                <div className={`box-analise-financeira-pj-${infos?.aprovado_restricao ?? timeline?.aprovado_restricao ? '1' : '2'}`}>
                    <h1>{infos?.analise_financeira?.restricao == true ? 'Com Restrição' : 'Sem Restrição'}</h1>
                </div>
                <div className={`box-analise-financeira-pj-${infos?.aprovado_score ?? timeline?.aprovado_score ? '1' : '2'}`}>
                    <h1>Score: {infos?.analise_financeira?.score == 0 ? infos?.analise_financeira?.score + '0' : infos?.analise_financeira?.score}</h1>
                </div>
            </div>
            <div className='flex mt-5'>
                <h1>Data da Consulta:</h1>
                <h1 className='ml-2 cor-cinza'>{dateLaravelToPtBr(infos?.analise_financeira.data_consulta)}</h1>
            </div>
            <div className='flex'>
                <h1>Razão Social:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira.razao_social}</h1>
                {/* <h1 className='ml-2 cor-cinza'>{dateToPtBr(infos?.analise_financeira?.data_nascimento)}</h1> */}
            </div>
            <div className='borda-analise mb-3 mt-3'></div>
            <div className='flex'>
                <h1>Data de Fundação:</h1>
                <h1 className='ml-2 cor-cinza'>{dateLaravelToPtBr(infos?.analise_financeira.data_nascimento)}</h1>
            </div>
            <div className='flex'>
                <h1>Situação:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira.situacao}</h1>
            </div>
            <div className='flex'>
                <h1>Data Situação:</h1>
                <h1 className='ml-2 cor-cinza'>{dateLaravelToPtBr(infos?.analise_financeira.data_situacao)}</h1>
            </div>
            <div className='flex'>
                <h1>CCF Indisponível:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira.ccf_indisponivel ? "S" : "N"}</h1>
            </div>
            <div className='borda-analise mb-3 mt-3'></div>
            <div className='flex'>
                <h1 className='estilo-fonte-negrito'>Protestos</h1>
            </div>
            <div className='flex'>
                <h1>Nada Consta:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira.protesto_consta ? "S" : "N"}</h1>
            </div>
            <div className='flex'>
                <h1>Mensagem:</h1>
            </div>
            <div className='flex'>
                <textarea
                    name="mensagem"
                    readOnly={true}
                    value={infos?.analise_financeira.cheque_s_fundo_mensagem ?? ""}
                    className="modal-textarea-menor-analise-financeira-mobile"
                ></textarea>
            </div>
            <div className='borda-analise mb-3 mt-3'></div>
            <div className='flex'>
                <h1 className='estilo-fonte-negrito'>Cheque Sem Fundo</h1>
            </div>
            <div className='flex'>
                <h1>Nada Consta:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira.cheque_s_fundo_consta ? "S" : "N"}</h1>
            </div>
            <div className='flex'>
                <h1>Mensagem:</h1>
            </div>
            <div className='flex'>
                <textarea
                    name="mensagem"
                    readOnly={true}
                    value={infos?.analise_financeira.cheque_s_fundo_mensagem ?? ""}
                    className="modal-textarea-menor-analise-financeira-mobile"
                ></textarea>
                {/* <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira.cheque_s_fundo_mensagem}</h1> */}
            </div>
            <div className='borda-analise mb-3 mt-3'></div>
            <div className='flex'>
                <h1 className='estilo-fonte-negrito'>Consulta Serasa</h1>
            </div>
            <div className='flex'>
                <h1 className='estilo-fonte-negrito'>Consultas Sem Cheques</h1>
            </div>
            <div className='flex'>
                <h1>Quantidade Consulta 15:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira.qtd_consulta_15}</h1>
            </div>
            <div className='flex'>
                <h1>Quantidade Consulta 30:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira.qtd_consulta_30}</h1>
            </div>
            <div className='flex'>
                <h1>Quantidade Consulta 60:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira.qtd_consulta_60}</h1>
            </div>
            <div className='flex'>
                <h1>Quantidade Consulta 90:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira.qtd_consulta_90}</h1>
            </div>
            <div className='borda-analise mb-3 mt-3'></div>
            <div className='flex'>
                <h1 className='estilo-fonte-negrito'>Score Empresas Positivo</h1>
            </div>
            <div className='flex'>
                <h1>Data da Consulta:</h1>
                <h1 className='ml-2 cor-cinza'>{dateToPtBr(infos?.analise_financeira.score_empresa_data_consulta)}</h1>
            </div>
            <div className='flex'>
                <h1>Hora da Consulta:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira.score_empresa_hora_consulta}</h1>
            </div>
            <div className='flex'>
                <h1>Prinad:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira.score_empresa_prinad}</h1>
            </div>
            {infos?.analise_financeira?.restricoes_pj != undefined && infos?.analise_financeira?.restricoes_pj != 0 ?
                <>
                    <div className='borda-analise mb-3 mt-3'></div>
                    <h1 className='label-restricao estilo-fonte-negrito'>Restrições</h1>
                    {
                        Object.keys(infos?.analise_financeira?.restricoes_pj).map((item, index) => (
                            infos?.analise_financeira?.restricoes_pj?.[item].map((restricao: any, restricaoIndex: any) => (
                                item == "pendenciasFinanceiras" ?
                                    <>
                                        {restricaoIndex == 0 ? <h1 className='estilo-fonte-negrito'>Pendências Financeiras</h1> : ""}
                                        <PendenciasFinanceiras restricao={restricao} />
                                    </> : item == "pendenciasInternas" ?
                                        <>
                                            {restricaoIndex == 0 ? <h1 className='estilo-fonte-negrito'>Pendências Internas</h1> : ""}
                                            <PendenciasInternas restricao={restricao} />
                                        </> : item == "protestos" ?
                                            <>
                                                {restricaoIndex == 0 ? <h1 className='estilo-fonte-negrito'>Protestos</h1> : ""}
                                                <Protestos restricao={restricao} />
                                            </> : item == "chequesSemFundo" ?
                                                <>
                                                    {restricaoIndex == 0 ? <h1 className='estilo-fonte-negrito'>Cheque Sem Fundo</h1> : ""}
                                                    <ChequeSemFundo restricao={restricao} />
                                                </> : item == "consultaSerasa" ?
                                                    <>
                                                        {restricaoIndex == 0 ? <h1 className='estilo-fonte-negrito'>Consulta Serasa</h1> : ""}
                                                        <ConsultaSerasa restricao={restricao} />
                                                    </> : ""
                            ))
                        ))
                    }
                </>
                : <div className='borda-analise mb-3 mt-3'></div>}
            <div>
                <h1>Mensagem</h1>
                <textarea
                    name="mensagem"
                    readOnly={true}
                    value={infos?.analise_financeira.score_empresa_mensagem ?? ""}
                    className="modal-textarea-analise-financeira"
                ></textarea>
            </div>
        </div>
    );
}

export default ModalPJMobile;