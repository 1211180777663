import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CardMidiaNoticia from "src/components/Basicos/Cards/CardMidiaNoticia";
import Inputlabel from "src/components/Basicos/Form/Input/InputLabel";
import InputSelect from "src/components/Basicos/Form/Input/InputSelect";
// import Header from "src/components/Basicos/Header";
import { dateAmerican, dateToPtBr } from "src/helpers/functions";
import { axiosApi } from "src/services/axiosInstance";

export type ListItems = {
    id?: any,
    name?: string,
    url_image?: string,
    raw?: any
};

export type Option = {
    value: Number | any,
    label: string | any
}

export type tipoNoticias = {
    label: string;
    value: string | number;
};

export type TipoNoticias = {
    id: number;
    descricao: string | number;
};

export type IDataVigencia = {
    periodo_inicio_vigencia?: string | undefined | Blob,
    periodo_fim_vigencia?: string | undefined | Blob,
};

export type IDataForm = {
    tipoNoticia?: Option,
    videoLink?: string | Blob,
    dataVigencia?: IDataVigencia,
    titulo_noticia?: String | undefined | Blob,
    sub_titulo_noticia?: String | undefined | Blob,
    corpo_noticia?: any
}

const NovaNoticia: React.FunctionComponent = () => {

    const [typeInicio, setTypeInicio] = useState("text");
    const [typeFim, setTypeFim] = useState("text");
    const [listPreviewPhoto, setListPreviewPhoto] = useState<Array<ListItems>>([]);
    const [tiposNoticias, setTiposNoticias] = useState<Array<Option>>([]);
    const [dataForm, setDataForm] = useState<IDataForm>({});

    const history = useHistory();
    const Multipart = new FormData();

    const onClickDeleteMidiaCard = (id: number) => {
        setListPreviewPhoto(listPreviewPhoto.filter(function (photo) {
            return photo.id !== id
        }));
    }

    const onChange = (event: any) => {
        const { name, value } = event.target;
        if (name === "video_link") {
            setDataForm({
                ...dataForm,
                videoLink: value
            })
        } else if (name === "periodo_inicio_vigencia" || name === "periodo_fim_vigencia") {
            setDataForm({
                ...dataForm,
                dataVigencia: {
                    ...dataForm?.dataVigencia,
                    [name]: dateToPtBr(value)
                }
            })
        } else {
            setDataForm({
                ...dataForm,
                [name]: value
            })
        }

    };

    const getTiposNoticias = async () => {
        try {
            const { data } = await axiosApi.get('/tipo-noticia');
            const tipoNoticia = data.data.map((el: TipoNoticias) => ({
                label: el.descricao,
                value: el.id,
            }));
            setTiposNoticias(tipoNoticia);
        } catch (error) {
            console.log(error);
        }
    }

    const onChangeSelect = (item: any, action: any) => {
        if (action.name === 'tipo_noticia') {
            setDataForm({
                ...dataForm,
                tipoNoticia: {
                    ...dataForm?.tipoNoticia,
                    label: action.name,
                    value: item.value
                }
            })
        }
    }

    // const onChangeSelect = (name: string, value: number): void => {
    //     if (name === 'tipo_noticia') {
    //         setDataForm({
    //             ...dataForm,
    //             tipoNoticia: {
    //                 ...dataForm?.tipoNoticia,
    //                 label: name,
    //                 value: value
    //             }
    //         })
    //     }
    // }

    const onSubmit = async (e: any) => {
        e.preventDefault();
        try {
            Multipart.append('titulo', dataForm?.titulo_noticia as Blob);
            Multipart.append('sub_titulo', dataForm?.sub_titulo_noticia as Blob);
            Multipart.append('texto', dataForm?.corpo_noticia as Blob);
            Multipart.append('data_inicial', dataForm.dataVigencia?.periodo_inicio_vigencia as Blob);
            Multipart.append('data_final', dataForm.dataVigencia?.periodo_fim_vigencia as Blob);
            Multipart.append('tipo_noticia_id', dataForm.tipoNoticia?.value);
            Multipart.append('url_video', dataForm.videoLink as Blob);

            listPreviewPhoto.map((item, index: number) => {
                Multipart.append(`arquivos[${index}]`, item?.raw);
            })
            await axiosApi.post('noticias', Multipart).then((response) => {
                history.goBack();
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getTiposNoticias()
        setDataForm({});
    }, [])

    return (
        <Fragment>
            <form onSubmit={onSubmit}>
                <div className="w-full flex-col flex p-8">
                    <div>
                        <Inputlabel
                            type={"text"}
                            className={`block p-4 w-full text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none`}
                            name={"titulo_noticia"}
                            placeholder={`Título`}
                            value={dataForm?.titulo_noticia ?? ""}
                            onChange={onChange}
                            required={true}
                        />
                        <Inputlabel
                            type={"text"}
                            className={`block p-4 w-full mt-2 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none`}
                            name={"sub_titulo_noticia"}
                            placeholder={`Subtítulo`}
                            value={dataForm?.sub_titulo_noticia ?? ""}
                            onChange={onChange}
                            required={true}
                        />
                    </div>
                    <div>
                        <textarea
                            rows={3}
                            name={`corpo_noticia`}
                            value={dataForm?.corpo_noticia ?? ""}
                            style={{ maxHeight: "20rem", minHeight: "3.5rem" }}
                            className="block p-4 w-full h-48 mt-2 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                            placeholder={'Corpo da Notícia'}
                            onChange={onChange}
                            required={true}
                        />

                        {/* <input className="block p-4 w-full h-48 mt-2 text-sm bg-gray-300 text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 focus:outline-none" placeholder={'Corpo da Notícia'} /> */}
                    </div>
                    <div className="flex flex-col md:flex-row sm:flex-col py-3 gap-2">
                        <div className="flex w-full lg:w-3/3 mb-12 lg:mb-0 flex-col lg:flex-row gap-2">
                            <Inputlabel
                                type={typeInicio}
                                className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 p-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full`}
                                colClassname={`w-full`}
                                name={"periodo_inicio_vigencia"}
                                placeholder={`Período Início Vigência`}
                                onFocus={() => setTypeInicio("date")}
                                value={dateAmerican(dataForm?.dataVigencia?.periodo_inicio_vigencia) ?? ""}
                                onChange={onChange}
                                titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
                                required={true}
                            />
                            <Inputlabel
                                type={typeFim}
                                className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4`}
                                colClassname={`w-full`}
                                name={"periodo_fim_vigencia"}
                                placeholder={`Período Fim Vigência`}
                                onFocus={() => setTypeFim("date")}
                                value={dateAmerican(dataForm?.dataVigencia?.periodo_fim_vigencia) ?? ""}
                                onChange={onChange}
                                titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
                                required={true}
                            />
                            <InputSelect
                                name={`tipo_noticia`}
                                options={tiposNoticias}
                                className={`bg-white w-full text-gray-900 text-sm border-gray-300 border rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2`}
                                colClassname={`w-full`}
                                onChange={onChangeSelect}
                                placeholder={"Tipo"}
                                value={tiposNoticias?.filter((option) => (option?.value === dataForm?.tipoNoticia?.value))}
                                />
                        </div>
                    </div>
                    {/* card midia */}
                    <CardMidiaNoticia
                        ArrayPhotos={listPreviewPhoto}
                        onClickDelete={onClickDeleteMidiaCard}
                        onClickEdit={() => console.log("edit")}
                        onChangeVideo={onChange}
                        onChangePhotos={setListPreviewPhoto}
                    />
                    <div className="flex flex-row gap-2 justify-center mt-6">
                        <button type={`button`} onClick={() => console.log("=> cancelando <=")} className="p-3 uppercase text-gray-600 font-semibold rounded-lg bg-gray-400 w-40 focus:outline-none">
                            cancelar
                        </button>
                        <button type={`submit`} className="p-3 uppercase text-white font-semibold rounded-lg bg-gray-400 w-40 bg-blue-mega-imoveis focus:outline-none" >
                            publicar
                        </button>
                    </div>
                </div>
            </form>

        </Fragment>
    );
}

export default NovaNoticia;