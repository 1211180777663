import React, { createContext, useContext, useState } from 'react';


const ControllMenuContext = createContext({});

interface IControllContextMenuProps {
    children: React.ReactNode
}

const ControllMenuProvider: React.FC<IControllContextMenuProps> = React.memo(({ children }) => {
    const [menuActive, setMenuActive] = useState("home");
    const [subMenuActive, setSubMenuActive] = useState("home");
    const [active, setActive] = useState(false);
    const solicitacoes = [
        "agendamento-visita",
        "informacao-imovel",
        "solicitacao-imovel",
        "avaliacao-imovel",
        "cotacao-seguro",
        "anuncie-seu-imovel",
        "declaracao-ir",
        "solicitacao-de-chamado",
        "fale-conosco",
        "indicacao-corretor"];

    const cadastros = [
        "locatario",
        "fiador",
        "proprietario"
    ];

    const auxiliares = [
        "sedes",
        "grupos-usuarios",
        "usuarios",
        "parametros",
    ];

    const setMenu = (menu: string): void => {
        setActive(!active);
        if (menu === menuActive) {
            if (menu !== "home") {
                setMenuActive("");
            }
        } else {
            if (solicitacoes.includes(menu)) {
                setMenuActive("solicitacoes");
                setSubMenuActive(menu);
            } else if (cadastros.includes(menu)) {
                setMenuActive("cadastro");
                setSubMenuActive(menu);
            } else if (auxiliares.includes(menu)) {
                setMenuActive("auxiliares");
                setSubMenuActive(menu);
            } else if (menu === "nova-noticia") {
                setMenuActive("noticias");
            } else if (menu === "login") {
                setMenuActive("home");
            } else {
                setMenuActive(menu);
            }
        }
    }

    const setSubMenu = (subMenu: string): void => {
        if (subMenu === subMenuActive) {
            setSubMenuActive("");
        } else {
            setSubMenuActive(subMenu);
        }
    }

    return (
        <ControllMenuContext.Provider value={{
            setMenu,
            setSubMenu,
            menuActive,
            subMenuActive,
            active
        }}>
            {children}
        </ControllMenuContext.Provider>
    );
});

function useControlContextMenu(): any {
    const context = useContext(ControllMenuContext);
    if (!context) {
        throw new Error("useControll must a be used with ControllMenuProvider");
    }
    return context;
}

export { ControllMenuProvider, useControlContextMenu };
