import React from "react";
import { AiFillPlusCircle, AiOutlinePlus } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import TailSpin from "react-loader-spinner/dist/loader/TailSpin";
// import Audi from "react-loader-spinner"
import "./style.css";

type Props = {
  className: string;
  divClassName?: string;
  id?: string;
  name?: string;
  type?: any;
  content?: string;
  onClick?: (e?: any) => void;
  onSubmit?: (e?: any) => void;
  loader?: boolean;
  loaderType?: any;
  styled?: Object;
  icon?: string | Array<JSX.Element> | JSX.Element;
  iconStyle?: string;
  iconSize?: number;
  disabled?:boolean;
  colorLoader?:string;
  styleSpan?:string;
};

export default function Button({
  id,
  name,
  className,
  divClassName = '',
  type,
  content,
  onClick,
  onSubmit,
  loader = false,
  loaderType = "TailSpin",
  styled,
  icon,
  iconStyle,
  iconSize = 1,
  disabled,
  colorLoader="white",
  styleSpan=''
}: Props): JSX.Element {

  return (
    <div className={divClassName}>
      <button
        className={`btn-effect cursor-pointer ${className} flex justify-center`}
        onClick={onClick}
        onSubmit={onSubmit}
        typeof={type}
        type={type === `button` ? `button` : `submit`}
        style={styled}
        disabled={disabled}
      >
        {loader && (
          <TailSpin
            // type={loaderType}
            color={colorLoader}
            // visible={true}
            height={24}
            width={20}
          />
        )}
        <span className={`flex space-x-1 ${loader == true ? styleSpan : ''}`}>
          <div>
            {(icon === "rounded-new" && (
              <AiFillPlusCircle
                className={` ${iconStyle}`}
                size={`${iconSize}rem`}
              />
            )) ||
              (icon === "new" && (
                <AiOutlinePlus
                  className={`${iconStyle}`}
                  size={`${iconSize}rem`}
                />
              )) ||
              (icon === "edit" && (
                <BiEdit className={`${iconStyle}`} size={`${iconSize}rem`} />
              )) ||
              icon}
          </div>
          <div>{content}</div>
        </span>
      </button>
    </div>
  );
}
