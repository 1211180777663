import React, { createContext, useContext, useState } from 'react';
import { axiosApi } from 'src/services/axiosInstance';

const ControlAnaliseFinanceiraContext = createContext({});

interface AnaliseProps {
    id: number;
    nome: string;
    nome_fantasia: string;
    razao_social: string;
    email: string;
    inscricao_estadual: number;
    data_nascimento: Date;
    idade: number;
    sexo: string;
    pessoa_estrangeira: boolean;
    regiao: string;
    situacao: string;
    data_situacao: Date;
    telefone_ddd: number;
    numero_celular: number;
    restricao: false;
    data_consulta: Date;
    quantidade_dias_consultados: number;
    classe: string;
    horizonte: number;
    mesagem_interpretativa: string;
    probabilidade: number;
    score: number;
    tipo_score: string;
    endereco_id: number;
    usuario_id: number;
    endereco: EnderecoProps;
    analise_financeira: AnaliseFinanceiraProps;
}

interface AnaliseFinanceiraProps {
    id: number;
    renda_mensal: string;
    locatario_id: number
}

interface EnderecoProps {
    id: number,
    complemento: string,
    cep: number,
    bairro: string,
    cidade: string,
    estado: string,
    logradouro: string,
    numero: string,
}

interface IControlContextAnaliseFinanceiraProps {
    children: React.ReactNode
}

const AnaliseFinanceiraProvider: React.FC<IControlContextAnaliseFinanceiraProps> = React.memo(({ children }) => {
    const [analiseFinanceira, setAnaliseFinanceira] = useState<AnaliseProps>();
    const [analiseFinanceiraConjuge, setAnaliseFinanceiraConjuge] = useState<AnaliseProps>();
    const [estiloBox, setEstiloBox] = useState<any>('3');

    const loadAnaliseFinanceira = async (locatario: any) => {
        try {
            const { data } = await axiosApi.get(`/analise-financeira/${locatario}`);
            setAnaliseFinanceira(data?.dados?.locatario);
            setAnaliseFinanceiraConjuge(data?.dados?.conjuge);
        } catch (error) {
            console.log(error);
        }
    }

    const getEstilo = (data: any, locatario: any) => {
        var pacoteLocacao = Number(locatario?.imovel_pretendido?.imovel?.preco_locacao) + Number(locatario?.imovel_pretendido?.imovel?.condominio);
        var rendaTotal = data.dados.conjuge != null ? Number(data.dados?.locatario?.analise_financeira?.renda_mensal) + Number(data.dados?.conjuge?.analise_financeira?.renda_mensal) : Number(data.dados?.locatario?.analise_financeira?.renda_mensal);
        var score = data.dados.locatario.score;
        if (rendaTotal > 3 * pacoteLocacao && data.dados.locatario.restricao == false) {
            if (pacoteLocacao <= 700 && score >= 301)
                setEstiloBox('1')
            else if (700.01 <= pacoteLocacao && pacoteLocacao <= 1500 && score >= 501)
                setEstiloBox('1')
            else if (1500.01 <= pacoteLocacao && pacoteLocacao <= 3000 && score >= 701)
                setEstiloBox('1')
            else if (3000.01 <= pacoteLocacao && score >= 701)
                setEstiloBox('1')
            else
                setEstiloBox('2')
        }
        else
            setEstiloBox('2')
    }

    return (
        <ControlAnaliseFinanceiraContext.Provider value={{
            analiseFinanceira,
            analiseFinanceiraConjuge,
            estiloBox,
            getEstilo,
            setEstiloBox,
            loadAnaliseFinanceira,
            setAnaliseFinanceira,
            setAnaliseFinanceiraConjuge
        }}>
            {children}
        </ControlAnaliseFinanceiraContext.Provider>
    );
});

function useControlContextAnaliseFinanceira(): any {
    const context = useContext(ControlAnaliseFinanceiraContext);
    if (!context) {
        throw new Error("useControll must a be used with PermissoesProvider");
    }
    return context;
}

export { AnaliseFinanceiraProvider, useControlContextAnaliseFinanceira };
