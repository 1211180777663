import React, { Fragment } from "react";
import { MdDeleteOutline, MdOutlineModeEdit } from "react-icons/md";
import { dateToPtBr } from "src/helpers/functions";

export type tipoNoticias = {
    id: number;
    descricao: string | number;
};

export type Imagem = {
    id?: string,
    nome?: string,
    caminho?: string,
    tipo_mime?: string,
    status?: string,
}

export type Arquivos = {
    id?: string,
    noticia_id?: string,
    arquivo_id?: string,
    imagem?: Imagem,
}

export type noticiaProps = {
    id?: string | number;
    titulo?: string;
    sub_titulo?: string;
    texto?: string;
    data_inicial?: string;
    data_final?: string;
    url_video?: string;
    tipo_noticia?: tipoNoticias;
    arquivos?: Array<Arquivos | undefined>;
};

type Props = {
    data?: noticiaProps,
    onClick?: () => void,
    onClickEdit?: () => void,
    onClickDelete?: () => void
};

export default function CardNoticia({
    data,
    onClick,
    onClickEdit,
    onClickDelete
}: Props): JSX.Element {
    return (
        <Fragment key={data?.id}>
            <div className="flex bg-white flex-col gap-1 overflow-hidden border rounded-lg mt-4 w-100">
                {data?.arquivos?.[0]?.imagem?.caminho === undefined ? '' : <div className="h-40 w-auto md:h-16 md:w-32 px-6 pt-4 rounded-xl" onClick={onClick}>
                    <img className="inset-0 h-full w-full rounded-xl object-cover object-center" alt="imagem" src={data?.arquivos[0].imagem.caminho} />
                </div>}
                <div className={`${data?.arquivos?.[0]?.imagem?.caminho === undefined ? 'w-full' : 'w-full'} h:40 md:h-auto py-4 px-6 text-gray-800 flex flex-col justify-between`}>
                    <div className="flex flex-col gap-0">
                        <p className="font-bold text-lg leading-tight sm:truncate">{data?.titulo}</p>
                        <p className="truncate">{data?.sub_titulo}</p>
                    </div>
                    <div className="flex">
                        <p className="text-xs text-gray-700 tracking-wide font-semibold mt-5">
                            Início Vigência {dateToPtBr(data?.data_inicial)} - Fim Vigência {dateToPtBr(data?.data_final)}
                        </p>
                        <button className="flex ml-auto h-10 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl" onClick={onClickEdit}>
                            <MdOutlineModeEdit size={'1.3rem'} className={`text-gray-system-mega-imoveis`} />
                        </button>
                        <button className="flex ml-3 h-10 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl" onClick={onClickDelete}>
                            <MdDeleteOutline size={'1.3rem'} className={`text-gray-system-mega-imoveis`} />
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>

    );
}
