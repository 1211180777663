import React, { Fragment, useMemo, useState, memo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    useAsyncDebounce,
    usePagination,
    useTable,
    useGlobalFilter,
} from "react-table";
import { FiEdit, FiEye, FiFileText } from "react-icons/fi";
import iconSingle from "src/assets/icones/icon-single.svg"
import iconDouble from "src/assets/icones/icon-double.svg"
import iconGroup from "src/assets/icones/icon-group.svg"
import SearchBar from "../SearchBar";
import Pagination from "../Pagination";
import './style.css';
import { converterDataBr } from "../funcoes";
import { mask } from "src/helpers/functions";
import { useControlContextCadastro } from "src/contexts/CadastroControlContext";
import EmptyPlaceholder from "../EmptyPlaceholder";
import StageBar from "../StageBar";
import StatusBar from "../StatusBar";
import { useControlContextSolicitacao } from "src/contexts/SolicitacaoControlContext";
import SelectForm from "../Form/SelectForm";
import SelectInput from "../Form/SelectSimpleInput";
import { useControlContextUsuarios } from "src/contexts/UsuariosContext";
import ThreeDotsIcon from "src/assets/icones/three-dots.svg"
import ModalDelete from "src/components/Funcionalidades/ModalDelete";
import CheckBox from "../Form/CheckBox";
import Inputlabel from "../Form/Input/InputLabel";
import ModalRedefinirSenha from "src/components/Funcionalidades/ModalRedefinirSenha";
import { alertMsg } from "src/helpers/functions";
import SubStageBar from "../SubStageBar";

function GlobalFilter({
    globalFilter,
    setGlobalFilter,
    placeholder,
    actions
}) {
    const [value, setValue] = useState(globalFilter);
    const { etapaActive, setEtapa, sedeActive, changeCancelado, checkCancelado, setDateFilter, dateActive, setSedesOptions, tiposSedesCadastro, setSedeCadastro, setPaginaFiltro, statusEtapaActive, setStatusEtapa } = useControlContextCadastro();
    const { statusActive, setStatusSolicitacao, statusActiveIndicacao, setStatusIndicacao, tiposSedes, setSede, sedeActiveSolicitacao } = useControlContextSolicitacao();
    const { tiposSedesUsuario, tiposGrupos, setSedeUsuarioActive, sedeActiveUsuario, setGrupo, grupoActive } = useControlContextUsuarios();

    const [typeInicio, setTypeInicio] = useState("text");
    const [typeFim, setTypeFim] = useState("text");
    const location = useLocation();
    const [sedeId, setSedeId] = useState();

    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    const [dataForm, setDataForm] = useState({
        dateInicial: null,
        dateFinal: null
    });

    useEffect(() => {
        setDateFilter(dataForm.dateInicial, dataForm.dateFinal);
    }, [dataForm]);

    const onChangeDate = (event) => {
        const { name, value } = event.target;
        setDataForm({
            ...dataForm,
            [name]: value
        })
    };

    const onChangeEventSelect = (name, value) => {
        setSede(value);
    }

    const onChangeSedeCadastro = (name, value) => {
        setSedeCadastro(value);
    }

    const onChangeEventSelectUsuario = (name, value) => {
        setSedeUsuarioActive(value);
    }

    const onChangeEventSelectGrupo = (name, value) => {
        setGrupo(value)
    }

    const onChangeEtapa = (etapa) => {
        setEtapa(etapa);
        setStatusEtapa(0);
        setPaginaFiltro(0);
    }

    useEffect(() => {
        setSedeId(sedeActiveSolicitacao);
    }, [sedeActiveSolicitacao]);

    useEffect(() => {
        setSedeId(sedeActive);
    }, [sedeActive]);

    const pathNamesAllowedForSearchBar = [
        '/cadastro/locatario',
        '/cadastro/fiador',
        '/cadastro/proprietario',
        '/cadastro/imovel',
        '/solicitacoes/agendamento-visita',
        '/solicitacoes/informacao-imovel',
        '/solicitacoes/solicitacao-imovel',
        '/solicitacoes/avaliacao-imovel',
        '/solicitacoes/cotacao-seguro',
        '/solicitacoes/fale-conosco',
        '/solicitacoes/declaracao-ir',
        '/solicitacoes/anuncie-seu-imovel',
        '/solicitacoes/solicitacao-de-chamado',
        '/solicitacoes/indicacao-corretor',
        "/solicitacoes/manutencao-imovel",
        "/solicitacoes/devolucao-imovel",
        "/solicitacoes/renovacao-contrato",
        "/solicitacoes/avaliacao-atendimento",
        "/auxiliares/colaboradores",
        "/auxiliares/clientes",
        "/auxiliares/sedes",
    ];

    const pathNamesAllowedForEtapa = [
        '/cadastro/locatario',
        '/cadastro/fiador',
        '/cadastro/imovel',
        '/cadastro/proprietario'
    ];

    const pathNamesAllowedForStatusEtapa = [
        '/cadastro/locatario',
        '/cadastro/fiador',
    ];

    const pathNamesAllowedGrupo = [
        "/auxiliares/colaboradores",
    ]

    const pathNamesAllowedForSelectSede = [
        '/cadastro/locatario',
        '/cadastro/imovel'
    ];

    const pathNamesAllowedForSedeData = [
        '/cadastro/proprietario'
    ]

    const pathNamesAllowedForStatus = [
        '/solicitacoes/agendamento-visita',
        '/solicitacoes/informacao-imovel',
        '/solicitacoes/solicitacao-imovel',
        '/solicitacoes/avaliacao-imovel',
        '/solicitacoes/cotacao-seguro',
        '/solicitacoes/fale-conosco',
        '/solicitacoes/declaracao-ir',
        '/solicitacoes/anuncie-seu-imovel',
        '/solicitacoes/solicitacao-de-chamado',
        '/solicitacoes/indicacao-corretor',
        "/solicitacoes/manutencao-imovel",
        "/solicitacoes/devolucao-imovel",
        "/solicitacoes/renovacao-contrato",
        "/solicitacoes/avaliacao-atendimento",
    ];

    const pathNamesAllowedForSelectCancelado = [
        '/cadastro/locatario',
        '/cadastro/proprietario',
        '/cadastro/imovel',
        '/cadastro/fiador',
    ]

    return (
        <div className={`w-full flex flex-col`}>
            {pathNamesAllowedForSearchBar.includes(location.pathname) ?
                <>
                    <div className={`col-span-2 flex flex-col h-auto lg:pr-6 xl:flex-row justify-between items-center pt-2`}>
                        <SearchBar
                            isButton={false}
                            value={value}
                            setValue={setValue}
                            onChange={onChange}
                            placeholder={placeholder}
                            inputTitle={'Filtros'}
                            dates={location.pathname == "/auxiliares/colaboradores" || location.pathname == "/auxiliares/clientes" || location.pathname == "/cadastro/proprietario" || location.pathname == "/auxiliares/sedes" ? false : !location?.pathname?.split('/')?.includes('solicitacoes')}
                            proprietario={location.pathname == "/cadastro/proprietario" ? true : false}
                            itsSelectSede={pathNamesAllowedForSelectSede.includes(location.pathname)}
                        />
                        {pathNamesAllowedForStatus.includes(location.pathname) ?
                            <div className="select-sede">
                                <SelectForm
                                    name={'sede_id'}
                                    options={tiposSedes}
                                    label={'Sede'}
                                    value={tiposSedes?.find((element) => element.value == sedeId)}
                                    onChange={onChangeEventSelect}
                                    placeholder={"Selecione"}
                                    className={"sede-responsavel"}
                                />
                            </div>
                            : ''}
                        {pathNamesAllowedGrupo.includes(location.pathname) ?
                            <>
                                <div className="select-sede">
                                    <SelectForm
                                        name={'sede_id'}
                                        options={tiposSedesUsuario}
                                        label={'Sede'}
                                        value={tiposSedesUsuario?.find((element) => element.value == sedeActiveUsuario)}
                                        onChange={onChangeEventSelectUsuario}
                                        placeholder={"Selecione"}
                                        className={"sede-responsavel"}
                                    />
                                </div>
                                <div className="select-sede">
                                    <SelectForm
                                        name={'grupo_id'}
                                        options={tiposGrupos}
                                        label={'Grupo de Usuários'}
                                        value={tiposGrupos?.find((element) => element.value == grupoActive)}
                                        onChange={onChangeEventSelectGrupo}
                                        placeholder={"Selecione"}
                                        className={"sede-responsavel"}
                                    />
                                </div>
                            </>
                            : ''}
                    </div>
                </> : ''}
            {pathNamesAllowedForEtapa.includes(location.pathname) ?
                pathNamesAllowedForSelectCancelado.includes(location.pathname) ?
                    <>
                        <div className="stagebar-checkbox">
                            <StageBar
                                etapaActive={etapaActive}
                                onClick={onChangeEtapa}
                                location={location.pathname}
                            />
                            <CheckBox
                                checked={checkCancelado}
                                funcao={changeCancelado}
                                label={'Cancelado'}
                                style={'mb-10'}
                                id={1}
                            />
                        </div>

                        {pathNamesAllowedForStatusEtapa.includes(location.pathname) ?
                            etapaActive == 0 || etapaActive == 1 || etapaActive == 2
                                ? ""
                                : <div className="substagebar-checkbox">
                                    <SubStageBar
                                        statusActive={statusEtapaActive}
                                        etapaActive={etapaActive}
                                        onClick={setStatusEtapa}
                                        location={location.pathname}
                                    />
                                </div>
                            : ""}
                    </>
                    : <StageBar
                        etapaActive={etapaActive}
                        onClick={onChangeEtapa}
                        location={location.pathname}
                    /> : ''
            }
            {pathNamesAllowedForSedeData.includes(location.pathname) ?
                <div className="barra-proprietario">
                    <div className="select-sede-proprietario">
                        <SelectForm
                            name={'sede_id'}
                            options={tiposSedesCadastro}
                            label={'Sede'}
                            value={tiposSedesCadastro?.find((element) => element.value == sedeActive)}
                            onChange={onChangeSedeCadastro}
                            placeholder={"Selecione"}
                            className={"sede-responsavel"}
                        />
                    </div>
                    <div className="select-sede-proprietario">
                        <SelectForm
                            name={'sede_id'}
                            options={tiposSedesUsuario}
                            label={'Usuário Responsável'}
                            value={tiposSedesUsuario?.find((element) => element.value == sedeActiveUsuario)}
                            onChange={onChangeEventSelectUsuario}
                            placeholder={"Selecione"}
                            className={"sede-responsavel"}
                        />
                    </div>
                    <Inputlabel
                        type={typeInicio}
                        className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 p-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full`}
                        colClassname={`data-proprietario`}
                        name={"dateInicial"}
                        placeholder={`Período Início Vigência`}
                        onFocus={() => setTypeInicio("date")}
                        value={dateActive?.dateInicial ?? ""}
                        onChange={onChangeDate}
                        inputTitle={`Data Cadastro Inicial`}
                        titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
                    />
                    <Inputlabel
                        type={typeFim}
                        className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4`}
                        colClassname={`data-proprietario`}
                        name={"dateFinal"}
                        placeholder={`Período Fim Vigência`}
                        onFocus={() => setTypeFim("date")}
                        value={dateActive?.dateFinal ?? ""}
                        onChange={onChangeDate}
                        inputTitle={`Data Cadastro Final`}
                        titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
                    />
                </div>
                : ''}

            {pathNamesAllowedForStatus.includes(location.pathname) ?
                <StatusBar
                    onClick={setStatusSolicitacao}
                    onClickIndicacao={setStatusIndicacao}
                    statusActive={statusActive}
                    statusActiveIndicacao={statusActiveIndicacao}
                    statusColor={statusColorSolicitacao}
                    statusColorIndicacao={statusColorIndicacaoCorretor}
                    location={location.pathname}
                />
                : ''}
        </div>
    );
}

const statusColor = (status_id) => {
    switch (status_id) {
        case 1:
            return '#FFD80E';
        case 2:
            return '#FFC189';
        case 3:
            return '#FF820E';
        case 4:
            return '#0CB75A';
        case 5:
            return '#FF3B3B';
        case 7:
            return '#0CB75A';
        //status para proprietario
        case 8:
            return '#FFD80E';
        case 9:
            return '#0A21FF';
        case 10:
            return '#0CB75A';
        case 11:
            return '#FF3B3B';
        default:
            return '#0CB75A';
    }
}

const statusColorSolicitacao = (status_id) => {
    switch (status_id) {
        case 1:
            return '#0A21FF';
        case 2:
            return '#0DCB64';
        case 3:
            return '#FFD80E';
        case 4:
            return '#FF3B3B';
        case 7:
            return '#0A21FF';
        case 8:
            return '#0CB75A';
        case 9:
            return '#FFD80E';
        case 10:
            return '#0A21FF';
        case 11:
            return '#FF3B3B';
        case 12:
            return '#0CB75A';
        default:
            return '#919191';
    }
}


const statusColorIndicacaoCorretor = (status_id) => {
    switch (status_id) {
        case 9:
            return '#FFD80E';
        case 10:
            return '#0A21FF';
        case 11:
            return '#FF3B3B';
        case 12:
            return '#0CB75A';
        default:
            return '#919191';
    }
}

const iconLocatario = (qtdLocatario) => {
    switch (qtdLocatario) {
        case 1:
            return iconSingle;
        case 2:
            return iconDouble;
        default:
            return iconGroup;
    }
}

function TableWithGlobalSearch({
    columns,
    data,
    actions,
    placeholder = "Pesquise por nome de usuário, e-mail, telefone e Sede",
    isButtons = true,
    valor = true,
    pageSize = 5,
    maxHeigth = false,
    pathName = 'home',
    withModal = false,
    itsCount = false,
    requestClose
}) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columnsMemo = useMemo(() => columns, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dataMemo = useMemo(() => data, [data]);
    const [infoModalDelete, setInfoModalDelete] = useState([]);
    const [infoModalRedefinirSenha, setInfoModalRedefinirSenha] = useState([]);
    const [modalDelete, setModalDelete] = useState(false);
    const [modalRedefinirSenha, setModalRedefinirSenha] = useState(false);
    const { paginaFiltro, setPaginaFiltro } = useControlContextCadastro();

    const openModalDelete = (id, rota, titulo) => {
        if (rota == 'cancelar-locatario') {
            var locatario = data?.find((element) => element.id === id);
            if (locatario.ultimo_historico.etapa_historico_validacao_id == 6 && locatario.ultimo_historico.status_validacao_id == 10) {
                alertMsg("error", "Locatário com Imóvel contratado!");
            } else {
                setInfoModalDelete({ id: id, rota: rota, titulo: titulo })
                setModalDelete(true);
            }
        } else if (rota == 'cancelar-imovel') {
            var imovel = data?.find((element) => element.id === id);
            if (imovel.ultimo_historico.etapa_historico_validacao_id == 8 && imovel.ultimo_historico.status_validacao_id == 10) {
                alertMsg("error", "Imóvel já publicado. Não pode ser cancelado!");
            } else {
                setInfoModalDelete({ id: id, rota: rota, titulo: titulo })
                setModalDelete(true);
            }
        } else {
            setInfoModalDelete({ id: id, rota: rota, titulo: titulo })
            setModalDelete(true);
        }
    }

    const openModalRedefinirSenha = (id, rota, titulo) => {
        setInfoModalRedefinirSenha({ id: id, rota: rota, titulo: titulo })
        setModalRedefinirSenha(true);
    }

    const closeModalDelete = () => {
        setModalDelete(false);
    }

    const closeModalRedefinirSenha = () => {
        setModalRedefinirSenha(false);
    }

    const changePage = (index) => {
        gotoPage(index)
        setPaginaFiltro(index)
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        gotoPage,
        pageCount,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        rows,

        state: { pageIndex, filteredData },
    } = useTable(
        {
            columns: columnsMemo,
            data: dataMemo,
            initialState: { pageIndex: paginaFiltro == undefined ? 0 : paginaFiltro, pageSize: pageSize },
        },
        useGlobalFilter,
        usePagination
    );

    const generatePagesLinks = () => {
        const pages = [];
        const range = 3;
        const numberOfPages = pageCount;
        const currentPage = pageIndex;
        let toIndex =
            numberOfPages > range * 2 ? currentPage + range : numberOfPages;
        let fromIndex = numberOfPages > range * 2 ? currentPage - range : 1;
        if (toIndex > numberOfPages) {
            fromIndex = numberOfPages - range * 2;
            toIndex = numberOfPages;
        } else if (fromIndex < 1) {
            fromIndex = 1;
            toIndex = 1 + range * 2;
        }

        for (let index = fromIndex; index <= toIndex; index++) {
            const active = index - 1 === pageIndex ? "bg-blue-dark-mega-imoveis text-white" : "bg-white text-blue-dark-mega-imoveis";
            pages.push(
                <div
                    onClick={() => changePage(index - 1)}
                    key={index}
                    className={`p-2 border-blue-dark-mega-imoveis border-2 font-semibold cursor-pointer rounded-lg w-10 h-10 flex justify-center items-center ${active}`}
                >
                    {index}
                </div>
            );
        }

        return pages;
    };

    return (
        <Fragment>
            <ModalDelete
                modalId={infoModalDelete?.id}
                nameRouter={infoModalDelete?.rota}
                titulo={infoModalDelete?.titulo}
                open={modalDelete}
                isClose={closeModalDelete}
                requestData={requestClose}
            />
            <ModalRedefinirSenha
                modalId={infoModalRedefinirSenha?.id}
                nameRouter={infoModalRedefinirSenha?.rota}
                titulo={infoModalRedefinirSenha?.titulo}
                open={modalRedefinirSenha}
                isClose={closeModalRedefinirSenha}
            />
            <GlobalFilter
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                placeholder={placeholder}
                actions={actions}
            />
            <div className={`px-0 md:px-6 mx-3 md:mx-0 overflow-x-auto TableWithGlobalSearch`} style={{ maxHeight: `${maxHeigth ? "261px" : "none"}` }}>
                {itsCount ?
                    <div className="text-right"><span className="py-2 text-blue-600">{rows.length} Resultados</span></div> : ''}
                {page.length === 0 ? (
                    <EmptyPlaceholder content={"Dados não Encontrados!"} />
                ) : (
                    <table
                        {...getTableProps()}
                        className="min-w-max w-full table-auto mb-5 select-text"
                    >
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr
                                    {...headerGroup.getHeaderGroupProps()}
                                    className="text-base leading-normal border-b-2 border-t-2 border-black"
                                >
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps()}
                                            className="py-3 px-6 text-center font-medium"
                                            key={column.id}
                                        >
                                            {column.render(`Header`)}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody
                            {...getTableBodyProps()}
                            className="text-gray-system-mega-imoveis text-base font-normal"
                        >
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className={`border-t ${row.original.status == 0 || row.original.status == 2 ? 'cor-desativado' : ''}`}
                                    >
                                        {row.cells.map((cell, index) => {
                                            switch (cell.column.type) {
                                                case "status":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-1 text-center whitespace-nowrap"
                                                        >
                                                            <div
                                                                className="h-full w-2 py-8"
                                                                style={{ 'backgroundColor': statusColor(cell.value) }} />

                                                        </td>
                                                    );
                                                case "status-solicitacao":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-1 text-center whitespace-nowrap"
                                                        >
                                                            <div
                                                                className="h-full w-2 py-8"
                                                                style={{ 'backgroundColor': statusColorSolicitacao(cell.value) }} />

                                                        </td>
                                                    );
                                                case "status-indicacao":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-1 text-center whitespace-nowrap"
                                                        >
                                                            <div
                                                                className="h-full w-2 py-8"
                                                                style={{ 'backgroundColor': statusColorIndicacaoCorretor(cell.value) }} />

                                                        </td>
                                                    );
                                                case "data":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-1 px-6 text-center whitespace-nowrap text-sm"
                                                        >
                                                            {cell.value !== undefined ? converterDataBr(cell.value) : "-"}
                                                        </td>
                                                    );
                                                case "telefone":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-1 px-2 text-center whitespace-nowrap text-sm"
                                                        >
                                                            {cell.value !== undefined ? mask(cell.value, "telefone") : "-"}
                                                        </td>
                                                    );
                                                case "dias":
                                                    const label = cell.value === '1' ? "dia" : "dias"
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-1 px-2 text-center whitespace-nowrap text-sm"
                                                        >

                                                            {cell.value !== undefined ? cell.value + ` ${label}` : "-"}
                                                        </td>
                                                    );
                                                case "YesOrNot":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-1 px-2 text-center whitespace-nowrap text-sm"
                                                        >
                                                            {cell.value !== null ? "Sim" : "Não"}
                                                        </td>
                                                    );
                                                case "cpf_cnpj":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-1 px-2 text-center whitespace-nowrap text-sm"
                                                        >
                                                            {cell.value !== undefined ? mask(cell.value, "cpf_cnpj") : "-"}
                                                        </td>
                                                    );
                                                case "mensagem":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-2 px-1 text-center whitespace-nowrap text-sm"
                                                        >
                                                            <div className={`flex w-full h-full p-4 border-gray-400 border rounded-lg overflow-hidden items-center justify-center`}>
                                                                <span>{cell.value !== undefined ? cell.value : "-"}</span>
                                                            </div>
                                                        </td>
                                                    );
                                                case "locatario/fiador":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-2 px-1 text-center whitespace-nowrap text-sm"
                                                        >
                                                            <span>{cell.value === "fiador" ? "Fiador" : "Locatário"}</span>
                                                        </td>
                                                    );
                                                case "itsGroup":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-1 px-2 text-center whitespace-nowrap text-sm"
                                                        >
                                                            <div className="flex ml-auto w-10 h-10 text-white  focus:outline-none items-center justify-center">
                                                                <img className="inset-0 h-8 w-8 object-center" alt="icon_group" src={iconLocatario(cell.value)} />
                                                            </div>
                                                        </td>
                                                    );
                                                default:
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-4 px-3 text-center whitespace-nowrap text-base"
                                                        >
                                                            {cell.value !== undefined ? cell.render(`Cell`) : "-"}
                                                        </td>
                                                    );
                                            }
                                        })}
                                        {isButtons === true && (
                                            <td className={`py-4 px-3 text-center w-full flex justify-center space-x-3 whitespace-nowrap text-sm text-green-400 cursor-pointer`}>
                                                {/* <td className="border py-3 px-3 text-center whitespace-nowrap text-sm text-green-400 hover:text-green-700 grid justify-items-center cursor-pointer"> */}
                                                {actions.edit && (
                                                    <FiEdit
                                                        size="1.1rem"
                                                        onClick={() => actions?.edit(row.original?.id)}
                                                    />
                                                )}
                                                {actions.file && (
                                                    <FiFileText
                                                        size="1.1rem"
                                                        onClick={() => actions?.file(row.original?.id)}
                                                    />
                                                )}
                                                {actions.show && (
                                                    withModal ?
                                                        <Link
                                                            onClick={() => actions?.show(row.original?.id)}
                                                            className="flex ml-auto w-10 h-10 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl items-center justify-center">
                                                            <FiEye
                                                                size={'1.3rem'}
                                                                className={`text-gray-system-mega-imoveis`} />
                                                        </Link>
                                                        :
                                                        (pathName === "auxiliares/colaboradores/novo-colaborador") ?
                                                            <Link
                                                                to={`/auxiliares/colaboradores/novo-colaborador?${row.original?.id}`}
                                                                className="flex ml-auto h-10 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl">
                                                                <FiEye
                                                                    size={'1.3rem'}
                                                                    className={`text-gray-system-mega-imoveis`} />
                                                            </Link>
                                                            :
                                                            (pathName == "auxiliares/clientes") ?
                                                                <Link
                                                                    to={`/auxiliares/cliente?${row.original?.id}`}
                                                                    className="flex ml-auto h-10 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl">
                                                                    <FiEye
                                                                        size={'1.3rem'}
                                                                        className={`text-gray-system-mega-imoveis`} />
                                                                </Link>
                                                                :
                                                                (pathName == "auxiliares/sedes") ?
                                                                    <Link
                                                                        to={`/auxiliares/sede?${row.original?.id}`}
                                                                        className="flex ml-auto h-10 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl">
                                                                        <FiEye
                                                                            size={'1.3rem'}
                                                                            className={`text-gray-system-mega-imoveis`} />
                                                                    </Link>
                                                                    :
                                                                    <Link
                                                                        onClick={() => actions?.show(row.original?.id)}
                                                                        to={`${pathName}/${row.original?.id}`}
                                                                        className="flex ml-auto h-10 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl">
                                                                        <FiEye
                                                                            size={'1.3rem'}
                                                                            className={`text-gray-system-mega-imoveis`} />
                                                                    </Link>
                                                )}
                                            </td>
                                        )
                                        }
                                        {
                                            data.find((element) => element.id === row.original?.id)?.status != 2 ?
                                                (pathName == "auxiliares/clientes") ?
                                                    <td class="py-4 px-3 text-center whitespace-nowrap text-base">
                                                        <img className="icon-delete-client" alt="icon_crown" src={ThreeDotsIcon} />
                                                        <div className="teste" onClick={() => openModalDelete(row.original?.id, "inativar-usuario", "Tem certeza que deseja inativar este usuário?")}><h1 className="label-excluir">Inativar</h1></div>
                                                    </td> :
                                                    (pathName == "auxiliares/colaboradores/novo-colaborador") ?
                                                        <td class="py-4 px-3 text-center whitespace-nowrap text-base">
                                                            <img className="icon-delete-client" alt="icon_crown" src={ThreeDotsIcon} />
                                                            <div className="reset-senha" onClick={() => openModalRedefinirSenha(row.original?.id, "redefinir-senha", "Redefinir senha?")}><h1 className="label-excluir">Redefinir Senha</h1></div>
                                                        </td> :
                                                        pathName == "locatario/detalhes" || pathName == "proprietario/detalhes" || pathName == "imovel/detalhes" ?
                                                            <td class="py-4 px-3 text-center whitespace-nowrap text-base">
                                                                <img className="icon-delete-client" alt="icon_crown" src={ThreeDotsIcon} />
                                                                <div className={`${pathName == "imovel/detalhes" ? 'popupcancelar-menor' : 'popupcancelar'}`}>
                                                                    <li className="opcoes-locatario-deletar">
                                                                        <button
                                                                            name="optionValue"
                                                                            className="lista-opcoes-deletar"
                                                                            onClick={() => data.find((element) => element.id === row.original?.id)?.status == 2 ? '' : openModalDelete(row.original?.id, `cancelar-${pathName == "locatario/detalhes" ? "locatario" : (pathName == "imovel/detalhes" ? "imovel" : "proprietario")}`, `Tem certeza que deseja cancelar este ${pathName == "locatario/detalhes" ? "locatario" : (pathName == "imovel/detalhes" ? "imovel" : "proprietario")}?`)}
                                                                        >
                                                                            Cancelar
                                                                        </button>
                                                                    </li>
                                                                    {pathName == "imovel/detalhes"
                                                                        ? null
                                                                        : <li className="opcoes-locatario-deletar">
                                                                            <button
                                                                                name="optionValue"
                                                                                className="lista-opcoes-deletar"
                                                                                onClick={() => data.find((element) => element.id === row.original?.id)?.status == 2 ? '' : openModalDelete(row.original?.id, `inativar-${pathName == "locatario/detalhes" ? "locatario" : "proprietario"}`, `Tem certeza que deseja excluir este ${pathName == "locatario/detalhes" ? "locatario" : "proprietario"}?`)}
                                                                            >
                                                                                Excluir
                                                                            </button>
                                                                        </li>
                                                                    }
                                                                </div>
                                                            </td> : '' : ''}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            {
                pageCount === 1 || pageCount === 0 ? (
                    ""
                ) : (
                    <Pagination
                        onClickPrev={() => previousPage()}
                        onClickNext={() => nextPage()}
                        disabledPrev={!canPreviousPage}
                        disabledNext={!canNextPage}
                        pageLinks={generatePagesLinks()}
                    />
                )
            }
        </Fragment >
    );
}

export default memo(TableWithGlobalSearch);
