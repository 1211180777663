import React, { Fragment } from 'react';
import './style.css';

interface SolicitacaoProps {
    id?: number;
    usuario_id?: number;
    imovel_id?: number;
    nome?: string;
    created_at?: string;
    updated_at?: string;
    data?: string;
    tempo_atendimento?: string;
    telefone1?: string;
    hora?: string;
    telefone2?: string;
    email?: string;
    codigo_imovel?: string;
    status_solicitacao_id?: number;
    sede_id?: number;
    tipo_manutencao_id?: number;
    seguro_id?: number;
    tipo_solicitacao_id?: number;
    tipo_anuncio_imovel_id?: number;
    mensagem?: string;
    arquivo_id?: number;
    comentario?: string;
    seguro?: SeguroProps;
    tipo_anuncio?: TipoAnuncio;
    imovel?: ImovelProps;
    venda?: boolean;
    locacao?: boolean;
    arquivo: ArquivoProps;
    tipo_solicitacao_locatario?: TipoSolicitacaoLocatarioProps;
    tipo_solicitacao?: TipoSolicitacaoProps;
    tipo_indicacao?: TipoIndicacaoProps;
    pessoa_indicada?: PessoaIndicadaProps;
    corretor?: CorretorProps;
    imovel_indicado?: ImovelIndicadoProps;
    valor_venda?: string;
    valor_locacao?: string;
    sede_responsavel?: SedeResponsavelProps;
    // st_ativo: boolean;
}

type SedeResponsavelProps = {
    id?: string,
    nome?: string,
}

type ImovelIndicadoProps = {
    id?: string,
    codigo_imovel?: string,
    cep?: string,
    complemento?: string,
    bairro?: string,
    endereco?: string,
    numero?: string,
    cidade?: string,
    estado?: string,
}

type PessoaIndicadaProps = {
    id?: string,
    nome_cliente: string,
    razao_social: string,
    nome_fantasia: string,
    cpf: string,
    cnpj: string,
    telefone1: string,
    telefone2: string,
    email: string,
    profissao: string,
    naturalidade: string,
    nacionalidade: string,
    qtd_dependentes: string,
    estado_civil_id: string,
    rg_cnh_arquivo_id: string,
}

type DadosBancariosProps = {
    id?: string,
    banco?: string,
    agencia?: string,
    conta?: string,
    operacao?: string,
}

type CorretorProps = {
    id?: string,
    creci?: string,
    usuario_id?: number,
    dados_bancarios_id?: number,
    informacoes_basicas?: PessoaIndicadaProps;
    dados_bancarios?: DadosBancariosProps;
}

type TipoIndicacaoProps = {
    id?: string,
    descricao?: string,
}

interface ArquivoProps {
    id?: number;
    nome?: string;
    caminho?: string;
    tipo_mime?: string;
}

interface ImovelProps {
    id?: number;
    codigo_imovel?: string;
}
interface TipoAnuncio {
    id?: number;
    descricao?: string;
}
interface TipoSolicitacaoLocatarioProps {
    id?: number;
    descricao?: string;
}

interface TipoSolicitacaoProps {
    id?: number;
    descricao?: string;
}

interface SeguroProps {
    id?: number;
    nome?: string;
}

type Props = {
    tipoSolicitacaoId?: number;
    data?: SolicitacaoProps;
    onChangeEventSelect?: any;
    opcoesStatus: Array<number>;
}

const FormCorretor: React.FunctionComponent<Props> = ({
    tipoSolicitacaoId,
    data,
    onChangeEventSelect,
    opcoesStatus
}) => {

    const checkFinalidade = (): any => {
        var texto = data?.valor_venda && data?.valor_locacao ? 'Para Venda e Locação' : data?.valor_venda ? 'Para Venda' : 'Para Locação';
        return texto;
    }

    return (
        <Fragment>
            <h3 className='table-sub-title-modal'>Identificação Pessoal do Corretor</h3>
            <h3 className='table-sub-sub-title-modal'>Dados Pessoais</h3>
            <div className="flex">
                <FormaLabelInput
                    titulo="Nome"
                    nome_do_campo="id"
                    valor_campo={data?.corretor?.informacoes_basicas?.nome_cliente ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo="Telefone 1"
                    nome_do_campo="telefone"
                    valor_campo={data?.corretor?.informacoes_basicas?.telefone1 ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo="Telefone 2"
                    nome_do_campo="telefone2"
                    valor_campo={data?.corretor?.informacoes_basicas?.telefone2 ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
            </div>
            <div className="flex">
                <FormaLabelInput
                    titulo="CPF"
                    nome_do_campo="cpf"
                    valor_campo={data?.corretor?.informacoes_basicas?.cpf ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo="CRECI"
                    nome_do_campo="creci"
                    valor_campo={data?.corretor?.creci ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo=""
                    nome_do_campo=""
                    valor_campo={""}
                    tipo_campo="hidden"
                    marginRigth="1.5rem"
                />
            </div>
            <h3 className='table-sub-title-modal'>Dados Bancários</h3>
            <div className="flex">
                <FormaLabelInput
                    titulo="Banco"
                    nome_do_campo="banco"
                    valor_campo={data?.corretor?.dados_bancarios?.banco ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo="Conta"
                    nome_do_campo="conta"
                    valor_campo={data?.corretor?.dados_bancarios?.conta ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
            </div>
            <div className="flex">
                <FormaLabelInput
                    titulo="Agência"
                    nome_do_campo="agencia"
                    valor_campo={data?.corretor?.dados_bancarios?.agencia ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo="Operação"
                    nome_do_campo="operacao"
                    valor_campo={data?.corretor?.dados_bancarios?.operacao ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
            </div>
            <h3 className='table-sub-title-modal'>Pessoa Indicada</h3>
            <div className="flex">
                <FormaLabelInput
                    titulo="Nome"
                    nome_do_campo="id"
                    valor_campo={data?.pessoa_indicada?.nome_cliente ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo="Telefone 1"
                    nome_do_campo="telefone"
                    valor_campo={data?.pessoa_indicada?.telefone1 ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo="Telefone 2"
                    nome_do_campo="telefone2"
                    valor_campo={data?.pessoa_indicada?.telefone2 ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
            </div>
            <div className="flex">
                <FormaLabelInput
                    titulo="Email"
                    nome_do_campo="email"
                    valor_campo={data?.pessoa_indicada?.email ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo=""
                    nome_do_campo=""
                    valor_campo={""}
                    tipo_campo="hidden"
                    marginRigth="1.5rem"
                />
            </div>
            {
                data?.imovel_indicado?.id ?
                    <><h3 className='table-sub-title-modal'>Imóvel Indicado</h3>
                        <div className="flex">
                            <FormaLabelInput
                                titulo="Código do Imóvel"
                                nome_do_campo="codigo_imovel"
                                valor_campo={data?.imovel_indicado?.codigo_imovel ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                            <FormaLabelInput
                                titulo="Complemento"
                                nome_do_campo="complemento"
                                valor_campo={data?.imovel_indicado?.complemento ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                        </div>
                        <div className="flex">
                            <FormaLabelInput
                                titulo="CEP"
                                nome_do_campo="cep"
                                valor_campo={data?.imovel_indicado?.cep ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                            <FormaLabelInput
                                titulo="Bairro"
                                nome_do_campo="bairro"
                                valor_campo={data?.imovel_indicado?.bairro ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                        </div>
                        <div className="flex">
                            <FormaLabelInput
                                titulo="Logradouro"
                                nome_do_campo="endereco"
                                valor_campo={data?.imovel_indicado?.endereco ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                            <FormaLabelInput
                                titulo="Número"
                                nome_do_campo="numero"
                                valor_campo={data?.imovel_indicado?.numero ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                            <FormaLabelInput
                                titulo="Cidade"
                                nome_do_campo="cidade"
                                valor_campo={data?.imovel_indicado?.cidade ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                            <FormaLabelInput
                                titulo="Estado"
                                nome_do_campo="estado"
                                valor_campo={data?.imovel_indicado?.estado ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                        </div>
                    </> :
                    <><h3 className='table-sub-title-modal'>Informações do Imóvel</h3>
                        <div className="flex">
                            <FormaLabelInput
                                titulo="Finalidade"
                                nome_do_campo="finalidade"
                                valor_campo={checkFinalidade() ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                            <FormaLabelInput
                                titulo="Sede Responsável Pelo Atendimento"
                                nome_do_campo="sede_responsavel"
                                valor_campo={data?.sede_responsavel?.nome ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                        </div>
                        <div className="flex">
                            <FormaLabelInput
                                titulo="Valor de Venda"
                                nome_do_campo="valor_venda"
                                valor_campo={data?.valor_venda ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                            <FormaLabelInput
                                titulo="Valor de Locação"
                                nome_do_campo="valor_locacao"
                                valor_campo={data?.valor_locacao ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                            <FormaLabelInput
                                titulo=""
                                nome_do_campo=""
                                valor_campo={""}
                                tipo_campo="hidden"
                                marginRigth="1.5rem"
                            />
                            <FormaLabelInput
                                titulo=""
                                nome_do_campo=""
                                valor_campo={""}
                                tipo_campo="hidden"
                                marginRigth="1.5rem"
                            />
                        </div>
                    </>
            }

        </Fragment>
    )
}

interface CamposForm {
    titulo?: string,
    nome_do_campo?: string,
    valor_campo?: number | string,
    tipo_campo?: string,
    required?: boolean,
    funcao?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    width?: string,
    height?: string,
    marginLeft?: string,
    marginRigth?: string,
    displayBlock?: string,
}

const FormaLabelInput: React.FC<CamposForm> = (props) => {
    return (
        <Fragment>
            <div className='form-input-solicitacao' style={{ width: props.width }}>
                <label className="form-label-titulo my-3" style={{ display: props.displayBlock }}>{props.titulo}</label>
                <input
                    required={props.required ? true : false}
                    type={props.tipo_campo}
                    name={props.nome_do_campo}
                    onChange={props.funcao}
                    value={props.valor_campo ?? ""}
                    className="modal-input mb-3 p-1"
                    style={{ width: props.width, height: props.height, marginLeft: props.marginLeft, marginRight: props.marginRigth }}
                />
            </div>
        </Fragment>
    );
};

export default FormCorretor;