import React, { useEffect } from 'react';
import Loader from 'src/components/Basicos/Loader';
import TableWithGlobalSearch from 'src/components/Basicos/TableWithGlobalSearch';
import { useControlContextUsuarios } from 'src/contexts/UsuariosContext';
import { usuariosColumns } from "./data-columns.json";
import './style.css'

const Clientes: React.FunctionComponent = () => {
    const {
        usuarios,
        loader,
        getUsuarios,
        filterSede,
        filterGrupo,
        setUsuarios,
        setGrupo,
        filterStatusUser
    } = useControlContextUsuarios();

    useEffect(() => {
        setGrupo(0)
        setUsuarios([]);
        getUsuarios();
    }, [])

    return (
        <Loader isActive={loader}>
            <div className='p-10'>
                <div className="card-content">
                    <div className="grid flex-col p-6">
                        <TableWithGlobalSearch
                            columns={usuariosColumns.columns}
                            data={filterGrupo(filterSede(filterStatusUser(usuarios))) ?? []}
                            actions={{
                                show: () => { }
                            }}
                            withModal={false}
                            itsCount={true}
                            isButtons={true}
                            pathName={`auxiliares/clientes`}
                            placeholder={"Pesquise por nome de usuário, e-mail, telefone"}
                            requestClose={null}
                        />
                    </div>
                </div>
            </div>
        </Loader >
    );
}

export default Clientes;