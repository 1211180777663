import React, { useMemo } from 'react';
import { mask } from 'src/helpers/functions';
import BlocoFiador from '../Blocos/BlocoFiador';
import Header from '../../Universal/HeaderPDF';
import BlocoLocSecundario from '../Blocos/BlocoLocSecundario';
import BlocoImovelPretendido from '../Blocos/BlocoImovelPretendido';
import Footer from '../../Universal/FooterPDF';
import TableDetails from 'src/components/Basicos/TableDetails';
import { setArraySecundarios, setHistoricoImovel } from './functions';

interface ModeloProps {
    locatario?: any
}

const useSepararSecundarios = (locatario: any) => {
    return useMemo(() => {
        const secundarios: Array<any> = setArraySecundarios(locatario).filter((item: any) => !item?.principal);
        const setSecundarios = (array: any[], length: number) => array.slice(0, length);
        const setHistorico = (array: any[], length: number) => array?.slice(0, length);

        const secundarios1 = setSecundarios(secundarios, 4);
        const secundarios2 = setSecundarios(secundarios.slice(4, 8), 4);
        const secundarios3 = setSecundarios(secundarios.slice(8, 12), 4);

        const imovel = secundarios.length >= 8 ? 3 : secundarios.length >= 4 ? 2 : 1;

        const historico = setHistoricoImovel(locatario?.historico, setHistorico, secundarios, 1);
        const [historico1, historico2, historico3] = historico;

        return { secundarios1, secundarios2, secundarios3, imovel, secundarios, historico1, historico2, historico3 };
    }, [locatario]);
};

const ModeloOutros1: React.FC<ModeloProps> = ({ locatario }) => {
    const { secundarios1, secundarios2, secundarios3, imovel, secundarios, historico1, historico2, historico3 } = useSepararSecundarios(locatario);

    return (
        <>
            {/* Página 4 */}
            {secundarios1.length !== 0 && (
                <div className="pagina-pdf">
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <div className='body-pdf'>
                        <h1 className='sub-title estilo-fonte-negrito'>
                            Outros Dados
                        </h1>
                        {/* Renderizar os componentes BlocoLocSecundario */}
                        {secundarios1.map((outros: any, index: number) => (
                            secundarios[index]?.tipo_locatario_id != undefined ?
                                <BlocoLocSecundario
                                    key={index}
                                    locatarioE={secundarios[index]?.tipo_locatario?.descricao}
                                    nome={secundarios[index]?.informacoes_basicas?.nome_cliente}
                                    cpf={mask(secundarios[index]?.informacoes_basicas?.cpf, "cpf_cnpj")}
                                    email={secundarios[index]?.informacoes_basicas?.email}
                                    telefone1={mask(secundarios[index]?.informacoes_basicas?.telefone1, "telefone")}
                                    telefone2={mask(secundarios[index]?.informacoes_basicas?.telefone2, "telefone")}
                                />
                                : <BlocoFiador
                                    key={index}
                                    nome={secundarios[index]?.informacoes_basicas?.nome_cliente}
                                    cpf={mask(secundarios[index]?.informacoes_basicas?.cpf, "cpf_cnpj")}
                                    email={secundarios[index]?.informacoes_basicas?.email}
                                    telefone1={mask(secundarios[index]?.informacoes_basicas?.telefone1, "telefone")}
                                    telefone2={mask(secundarios[index]?.informacoes_basicas?.telefone2, "telefone")}
                                />
                        ))}

                        {imovel === 1 && <BlocoImovelPretendido
                            codigo_imovel={locatario?.imovel_pretendido?.imovel?.codigo_imovel}
                            bairro={locatario?.imovel_pretendido?.imovel?.bairro}
                            logradouro={locatario?.imovel_pretendido?.imovel?.endereco}
                            numero={locatario?.imovel_pretendido?.imovel?.numero}
                            cidade={locatario?.imovel_pretendido?.imovel?.cidade}
                            estado={locatario?.imovel_pretendido?.imovel?.estado}
                            complemento={locatario?.imovel_pretendido?.imovel?.complemento}
                        />}

                        {secundarios1.length <= 1 && <TableDetails
                            isPDF={true}
                            title="Histórico de Alterações"
                            historico={historico1}
                        />}

                    </div>

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {secundarios1?.length == 3 && historico1?.length != 0 && (
                <div className="pagina-pdf">
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <div className='body-pdf'>
                        <TableDetails
                            isPDF={true}
                            title="Histórico de Alterações"
                            historico={historico1}
                        />
                    </div>

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {secundarios1.length == 0 && imovel == 1 && (
                <div className="pagina-pdf">
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <div className='body-pdf'>
                        <BlocoImovelPretendido
                            codigo_imovel={locatario?.imovel_pretendido?.imovel?.codigo_imovel}
                            bairro={locatario?.imovel_pretendido?.imovel?.bairro}
                            logradouro={locatario?.imovel_pretendido?.imovel?.endereco}
                            numero={locatario?.imovel_pretendido?.imovel?.numero}
                            cidade={locatario?.imovel_pretendido?.imovel?.cidade}
                            estado={locatario?.imovel_pretendido?.imovel?.estado}
                            complemento={locatario?.imovel_pretendido?.imovel?.complemento}
                        />
                    </div>

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {/* Página 5 */}
            {secundarios2.length !== 0 && (
                <div className="pagina-pdf">
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <div className='body-pdf'>
                        {/* Renderizar os componentes BlocoLocSecundario */}
                        {secundarios2.map((outros: any, index: number) => (
                            secundarios2[index + 4]?.tipo_locatario_id != undefined ?
                                <BlocoLocSecundario
                                    key={index + 4}
                                    locatarioE={secundarios[index + 4]?.tipo_locatario?.descricao}
                                    nome={secundarios[index + 4]?.informacoes_basicas?.nome_cliente}
                                    cpf={mask(secundarios[index + 4]?.informacoes_basicas?.cpf, "cpf_cnpj")}
                                    email={secundarios[index + 4]?.informacoes_basicas?.email}
                                    telefone1={mask(secundarios[index + 4]?.informacoes_basicas?.telefone1, "telefone")}
                                    telefone2={mask(secundarios[index + 4]?.informacoes_basicas?.telefone2, "telefone")}
                                />
                                : <BlocoFiador
                                    key={index + 4}
                                    nome={secundarios[index + 4]?.informacoes_basicas?.nome_cliente}
                                    cpf={mask(secundarios[index + 4]?.informacoes_basicas?.cpf, "cpf_cnpj")}
                                    email={secundarios[index + 4]?.informacoes_basicas?.email}
                                    telefone1={mask(secundarios[index + 4]?.informacoes_basicas?.telefone1, "telefone")}
                                    telefone2={mask(secundarios[index + 4]?.informacoes_basicas?.telefone2, "telefone")}
                                />
                        ))}

                        {imovel === 2 && <BlocoImovelPretendido
                            codigo_imovel={locatario?.imovel_pretendido?.imovel?.codigo_imovel}
                            bairro={locatario?.imovel_pretendido?.imovel?.bairro}
                            logradouro={locatario?.imovel_pretendido?.imovel?.endereco}
                            numero={locatario?.imovel_pretendido?.imovel?.numero}
                            cidade={locatario?.imovel_pretendido?.imovel?.cidade}
                            estado={locatario?.imovel_pretendido?.imovel?.estado}
                            complemento={locatario?.imovel_pretendido?.imovel?.complemento}
                        />}

                        {secundarios2.length < 3 && historico1.length != 0 && (
                            <TableDetails
                                isPDF={true}
                                title="Histórico de Alterações"
                                historico={historico1}
                            />
                        )}

                    </div>

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {secundarios2.length == 0 && imovel == 2 && (
                <div className="pagina-pdf">
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <div className='body-pdf'>
                        <BlocoImovelPretendido
                            codigo_imovel={locatario?.imovel_pretendido?.imovel?.codigo_imovel}
                            bairro={locatario?.imovel_pretendido?.imovel?.bairro}
                            logradouro={locatario?.imovel_pretendido?.imovel?.endereco}
                            numero={locatario?.imovel_pretendido?.imovel?.numero}
                            cidade={locatario?.imovel_pretendido?.imovel?.cidade}
                            estado={locatario?.imovel_pretendido?.imovel?.estado}
                            complemento={locatario?.imovel_pretendido?.imovel?.complemento}
                        />
                        {historico1?.length != 0 && (
                            <TableDetails
                                isPDF={true}
                                title="Histórico de Alterações"
                                historico={historico1}
                            />
                        )}
                    </div>
                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {/* Página 6 */}
            {secundarios3.length !== 0 && (
                <div className="pagina-pdf">
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <div className='body-pdf'>
                        {/* Renderizar os componentes BlocoLocSecundario */}
                        {secundarios3.map((outros: any, index: number) => (
                            secundarios3[index + 7]?.tipo_locatario_id != undefined ?
                                <BlocoLocSecundario
                                    key={index + 7}
                                    locatarioE={secundarios[index + 8]?.tipo_locatario?.descricao}
                                    nome={secundarios[index + 8]?.informacoes_basicas?.nome_cliente}
                                    cpf={mask(secundarios[index + 8]?.informacoes_basicas?.cpf, "cpf_cnpj")}
                                    email={secundarios[index + 8]?.informacoes_basicas?.email}
                                    telefone1={mask(secundarios[index + 8]?.informacoes_basicas?.telefone1, "telefone")}
                                    telefone2={mask(secundarios[index + 8]?.informacoes_basicas?.telefone2, "telefone")}
                                />
                                : <BlocoFiador
                                    key={index + 8}
                                    nome={secundarios[index + 8]?.informacoes_basicas?.nome_cliente}
                                    cpf={mask(secundarios[index + 8]?.informacoes_basicas?.cpf, "cpf_cnpj")}
                                    email={secundarios[index + 8]?.informacoes_basicas?.email}
                                    telefone1={mask(secundarios[index + 8]?.informacoes_basicas?.telefone1, "telefone")}
                                    telefone2={mask(secundarios[index + 8]?.informacoes_basicas?.telefone2, "telefone")}
                                />
                        ))}

                        {imovel === 3 && <BlocoImovelPretendido
                            codigo_imovel={locatario?.imovel_pretendido?.imovel?.codigo_imovel}
                            bairro={locatario?.imovel_pretendido?.imovel?.bairro}
                            logradouro={locatario?.imovel_pretendido?.imovel?.endereco}
                            numero={locatario?.imovel_pretendido?.imovel?.numero}
                            cidade={locatario?.imovel_pretendido?.imovel?.cidade}
                            estado={locatario?.imovel_pretendido?.imovel?.estado}
                            complemento={locatario?.imovel_pretendido?.imovel?.complemento}
                        />}

                        {secundarios3.length < 3 && historico1.length != 0 && (
                            <TableDetails
                                isPDF={true}
                                title="Histórico de Alterações"
                                historico={historico1}
                            />
                        )}

                    </div>

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {secundarios3.length == 0 && imovel == 3 && (
                <div className="pagina-pdf">
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <div className='body-pdf'>
                        <BlocoImovelPretendido
                            codigo_imovel={locatario?.imovel_pretendido?.imovel?.codigo_imovel}
                            bairro={locatario?.imovel_pretendido?.imovel?.bairro}
                            logradouro={locatario?.imovel_pretendido?.imovel?.endereco}
                            numero={locatario?.imovel_pretendido?.imovel?.numero}
                            cidade={locatario?.imovel_pretendido?.imovel?.cidade}
                            estado={locatario?.imovel_pretendido?.imovel?.estado}
                            complemento={locatario?.imovel_pretendido?.imovel?.complemento}
                        />
                        {historico1?.length != 0 && (
                            <TableDetails
                                isPDF={true}
                                title="Histórico de Alterações"
                                historico={historico1}
                            />
                        )}
                    </div>

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {secundarios2.length == 3 && historico1.length != 0 && (
                <div className="pagina-pdf">
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <div className='body-pdf'>
                        <TableDetails
                            isPDF={true}
                            title="Histórico de Alterações"
                            historico={historico1}
                        />
                    </div>

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {historico2?.length != 0 && (
                <>
                    <div className="pagina-pdf">
                        <Header
                            tipo='Locatário'
                            nome={locatario?.informacoes_basicas?.nome_cliente}
                            tipo_garantia={locatario?.garantia?.descricao}
                            status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                        />
                        <div className='body-pdf'>
                            <TableDetails
                                isPDF={true}
                                title="Histórico de Alterações"
                                historico={historico2}
                            />
                        </div>

                        <div className='footer-pdf'>
                            <Footer />
                        </div>
                    </div>
                    {historico3?.length != 0 && (
                        <div className="pagina-pdf">
                            <Header
                                tipo='Locatário'
                                nome={locatario?.informacoes_basicas?.nome_cliente}
                                tipo_garantia={locatario?.garantia?.descricao}
                                status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                            />
                            <div className='body-pdf'>
                                <TableDetails
                                    isPDF={true}
                                    title="Histórico de Alterações"
                                    historico={historico3}
                                />
                            </div>

                            <div className='footer-pdf'>
                                <Footer />
                            </div>
                        </div>
                    )}

                </>
            )}
        </>
    );
};

export default ModeloOutros1;