import React, { createContext, useContext, useState } from 'react';
import { alertMsg, jsonToFormData } from 'src/helpers/functions';
import { axiosApi } from 'src/services/axiosInstance';


const ControllSedesContext = createContext({});

interface IControllContextSedesProps {
    children: React.ReactNode
}

interface SedeProps {
    id: number,
    nome: string,
    hor_seg_sex: string,
    hor_sab: string,
    hor_dom: string,
    whatsapp: string,
    arquivo_id: number | null,
    endereco: string,
    regiao: string
}

const ControllSedesProvider: React.FC<IControllContextSedesProps> = React.memo(({ children }) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [sedes, setSedes] = useState<any>();
    const [sede, setSede] = useState<SedeProps>();
    const [id, setId] = useState<any>(undefined);

    const loadSedes = async () => {
        try {
            const { data } = await axiosApi.get('loja');
            setSede(undefined)
            setId(undefined)
            setSedes(data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    const getSede = async (id: number) => {
        try {
            setLoader(true)
            const { data } = await axiosApi.get(`loja/${id}`);
            setId(id);
            setSede(data.data)
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    const saveSede = async (history: any) => {
        if (sede?.regiao == 'Selecione' || sede?.regiao == undefined) {
            alertMsg("warning", "Selecione uma região!");
        }
        else {
            try {
                setLoader(true)
                var request = id == undefined ? "POST" : "PUT";
                var url = id == undefined ? "loja" : `loja/${id}`;
                var dadosFrom = jsonToFormData({ ...sede }, request);
                await axiosApi(
                    {
                        method: "POST",
                        headers: { "Content-Type": "multipart/form-data;" },
                        url: url,
                        data: dadosFrom
                    }
                );
            } catch (error) {
                console.log(error);
            } finally {
                setLoader(false);
                history.push(`/auxiliares/sedes`)
            }
        }
    }

    return (
        <ControllSedesContext.Provider value={{
            sede,
            sedes,
            loader,
            loadSedes,
            setLoader,
            getSede,
            setSede,
            saveSede
        }}>
            {children}
        </ControllSedesContext.Provider>
    );
});

function useControlContextSedes(): any {
    const context = useContext(ControllSedesContext);
    if (!context) {
        throw new Error("useControll must a be used with ControllSedesProvider");
    }
    return context;
}

export { ControllSedesProvider, useControlContextSedes };
