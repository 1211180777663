import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardTable from "src/components/Basicos/Cards/CardTable";
import { ajustUserAgent, converterDataBr } from "src/components/Basicos/funcoes";
import Loader from "src/components/Basicos/Loader";
import TableDetails from "src/components/Basicos/TableDetails";
import TimelineCadastroFiador from "src/components/Basicos/Timeline/TimelineCadastroFiador";
import TimelimeCadastroLocatario from "src/components/Basicos/Timeline/TimelineCadastroLocatario";
import { useControlContextMobile } from "src/contexts/MobileContext";
import { mask, normalizarLocatario } from "src/helpers/functions";
import { axiosApi } from "src/services/axiosInstance";
import DetalhesCadastroFiadorMobile from "./indexMobile";

const DetalhesCadastroFiador: React.FunctionComponent = () => {
    const [fiadorData, setfiadorData] = useState<any>();
    const [fiadores, setFiadores] = useState<any>([]);
    const [historico, setHistorico] = useState<any>([]);
    const [timeline, setTimeline] = useState<any>([]);
    // const [fiadorTimeline, setFiadorTimeline] = useState<any>([]);
    const [locatarios, setLocatarios] = useState<any>([]);
    const [loader, setLoader] = useState(false);
    const [etapaAtual, setEtapaAtual] = useState(0);
    const { setMobile, mobile } = useControlContextMobile();


    let { fiadorId }: any = useParams();
    let locatarioId = fiadorData?.locatario_id;

    const onTop = () => {
        window.scrollTo(0, 0);
    }

    const getFiador = async (fiadorId: number) => {
        try {
            if (fiadorId !== null) {
                setLoader(true)
                const { data } = await axiosApi.get(`/fiador/${fiadorId}`);
                setfiadorData(data?.data);

                setHistorico(data?.data?.historico);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const getLinhaTempo = async (fiadorId: number) => {
        try {
            if (fiadorId !== null && fiadorId !== undefined) {
                setLoader(true)
                const { data } = await axiosApi.get(`/linha-tempo/adm/fiador/${fiadorId}`);
                // setTimeline(data?.data?.timeLine);
                setTimeline(data?.data.filter((item: any) => { return item.id === Number(fiadorId) })[0]);
                setFiadores(data?.data.filter((item: any) => { return item.id !== Number(fiadorId) }).map((e: any) => { return e }));
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const statusTimeLine = async (status_id: number) => {
        try {
            if (fiadorId !== null && etapaAtual !== 0) {
                setLoader(true)

                const dataForm = {
                    fiador_id: Number(fiadorId),
                    etapa_id: etapaAtual,
                    comentario: null
                }
                const pathName = { '10': 'realizado', '11': 'nao-realizado' }
                await axiosApi.post(`/fiador/status/${pathName[status_id]}`, dataForm);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
            setEtapaAtual(0);
            getLinhaTempo(fiadorId);
            getFiador(fiadorId);
            getLinhaTempoLocatario(locatarioId);
        }
    }

    const getLinhaTempoLocatario = async (locatarioId: number) => {
        try {
            setLoader(true)
            if (locatarioId !== null && locatarioId !== undefined) {
                const { data } = await axiosApi.get(`/linha-tempo/adm/locatario/${locatarioId}`);
                setLocatarios(data?.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }
    const imovel = [
        { header: "Código do Imóvel", value: fiadorData?.locatario?.imovel_pretendido?.imovel?.codigo_imovel },
        { header: "Locatário", value: fiadorData?.locatario?.informacoes_basicas?.nome_cliente },
        { header: "Garantia", value: fiadorData?.locatario?.garantia?.descricao },
        { header: "Quem cadastrou?", value: normalizarLocatario(fiadorData?.locatario?.locatario_imovel?.responsavel_ficha_locatario) },
        { header: "Sede", value: fiadorData?.locatario?.sede_responsavel?.nome }
    ];

    const fiador = [
        { header: "Nome", value: fiadorData?.informacoes_basicas?.nome_cliente === null ? fiadorData?.informacoes_basicas?.razao_social : fiadorData?.informacoes_basicas?.nome_cliente },
        { header: "Email", value: fiadorData?.informacoes_basicas?.email },
        { header: "Telefone", value: mask(fiadorData?.informacoes_basicas?.telefone1, 'telefone') },
        { header: "Data Solicitação", value: converterDataBr(fiadorData?.locatario?.created_at) },
        { header: "Tempo de Atendimento", value: `${fiadorData?.tempo_atendimento ?? 0} dias` },
    ];

    const origemCadastro = [
        { header: "Data e Hora", value: converterDataBr(fiadorData?.informacoes_dispositivo?.created_at) },
        { header: "Dispositivo", value: fiadorData?.informacoes_dispositivo?.device_type },
        { header: "Versão", value: ajustUserAgent(fiadorData?.informacoes_dispositivo?.user_agent) },
        { header: "Navegador", value: fiadorData?.informacoes_dispositivo?.browser_name },
        { header: "Versão do Navegador", value: fiadorData?.informacoes_dispositivo?.browser_version },
        { header: "Sistema Operacional", value: fiadorData?.informacoes_dispositivo?.os_name }
    ];

    const InformacaoProcessoIsStack = fiadorData?.locatario?.imovel_pretendido?.imovel?.codigo_imovel ||
        fiadorData?.locatario?.informacoes_basicas?.nome_cliente ||
        fiadorData?.locatario?.garantia?.descricao ||
        fiadorData?.locatario?.cadastrou;


    const fiadorSection = (fiador: any) => {
        return (
            <div>
                {fiador !== undefined ?
                    <TimelineCadastroFiador
                        fiadorId={fiador?.id}
                        timeline={fiador?.timeline}
                        setStatusTimeLine={statusTimeLine}
                        setEtapaAtual={setEtapaAtual}
                        etapaAtual={0}
                        itsButtons={false}
                        statusSectionTimeline={fiador.status === 1}
                        fiador={fiador}
                    /> : ""}
            </div>
        );
    }

    useEffect(() => {
        getLinhaTempoLocatario(locatarioId)
    }, [locatarioId]);

    useEffect(() => {
        onTop();
        getFiador(fiadorId);
        getLinhaTempo(fiadorId);
    }, [fiadorId]);

    useEffect(() => {
        onTop();
        setMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }, []);
    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
                {
                    mobile ?
                        <DetalhesCadastroFiadorMobile
                            data={fiadorData}
                            TimelineFiador={timeline?.timeline}
                            TimelimeLocatario={locatarios}
                            fiadores={fiadores}
                            fiadorSection={fiadorSection}
                            statusTimeLine={statusTimeLine}
                            setEtapaAtual={setEtapaAtual}
                            etapaAtual={etapaAtual} />
                        :
                        <div className="p-6">
                            <div className={`${fiadorData?.status == 2 ? 'bg-cancelado' : 'bg-white'} p-10 flex flex-col space-y-20 rounded-lg border-gray-100 border h-full w-full`} style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>
                                {
                                    fiadorData?.status == 2 ?
                                        <div className="mt-5">
                                            <p className="font-light text-lg">Motivo do Cancelamento</p>
                                            <textarea
                                                rows={2}
                                                readOnly
                                                name={`comentario_fiador`}
                                                style={{ maxHeight: "6rem", minHeight: "3.5rem" }}
                                                className="block p-4 w-full h-32 mt-2 text-sm border-2 border-opacity-100 text-gray-system-mega-imoveis border-gray-system-mega-imoveis rounded-lg focus:outline-none placeholder-gray-700 resize-none"
                                                // placeholder={'Adicione um comentário'}
                                                value={fiadorData?.motivo_cancelamento}
                                            />
                                        </div>
                                        : ''
                                }
                                <CardTable
                                    data={fiador}
                                    title={`Informações do Fiador`}
                                    key={1}
                                />
                                <CardTable
                                    data={origemCadastro}
                                    title={`Origem do Cadastro`}
                                    key={2}
                                />
                                {InformacaoProcessoIsStack !== undefined ? <CardTable
                                    data={imovel}
                                    title={`Informações do Processo`}
                                    key={3}
                                /> : ""}

                                {timeline.timeline !== undefined ? <TimelineCadastroFiador
                                    fiadorId={fiadorId}
                                    timeline={timeline?.timeline}
                                    setStatusTimeLine={statusTimeLine}
                                    setEtapaAtual={setEtapaAtual}
                                    etapaAtual={etapaAtual}
                                    statusSectionTimeline={true}
                                    isPrincipal={true}
                                    fiador={timeline}
                                    itsButtons={true}

                                /> : ''}
                                {/* <div className="mt-5">
                            <p className="font-light text-lg text-gray-system-mega-imoveis">Comentário</p>
                            <textarea
                                rows={2}
                                name={`comentario_locatario`}
                                style={{ maxHeight: "20rem", minHeight: "3.5rem" }}
                                className="block p-4 w-full h-32 mt-2 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                                placeholder={'Adicione um comentário'}
                            />
                        </div> */}
                                <div className="flex flex-col">
                                    {locatarios.sort(function (x: any, y: any) { return (x?.principal === y?.principal) ? 0 : x?.principal ? -1 : 1; }).map((item: any) => {
                                        return <TimelimeCadastroLocatario
                                            locatario={item}
                                            locatarioId={item.id}
                                            timeline={item?.timeLine}
                                            setStatusTimeLine={statusTimeLine}
                                            setEtapaAtual={setEtapaAtual}
                                            etapaAtual={etapaAtual}
                                            itsButtons={false}
                                            isSecundario={!item?.principal}
                                        />
                                    })}
                                    {fiadores.map((item: any) => {
                                        return (fiadorSection(item))
                                    })}
                                </div>
                                <TableDetails
                                    title="Histórico de Alterações"
                                    historico={historico}
                                />
                            </div>
                        </div>
                }
            </Loader>
        </Fragment>
    );
}

export default DetalhesCadastroFiador;