import React, { Fragment, useEffect, useState } from "react";
import DashboardDirector from "./Director";
import DashboardUser from "./User";
import { useControlContextCadastro } from "src/contexts/CadastroControlContext";
import { axiosApi } from "src/services/axiosInstance";
import { useControlContextMobile } from "src/contexts/MobileContext";
import FiltroDesktop from "./filtroDesktop";
import FiltroMobile from "./filtroMobile";
import DashboardDirectorMobile from "./Director/indexMobile";
import DashboardUserMobile from "./User/indexMobile";

type DateType = {
    dateInicial: String | null,
    dateFinal: String | null,
}

type PropsPermissao = {
    id: Number | null,
    status: Boolean | null,
    create: Boolean | null,
    view: Boolean | null,
    edit: Boolean | null,
    delete: Boolean | null,
    aprovar_cadastro_gerencia: Boolean | null,
    aprovar_cadastro_diretoria: Boolean | null,
    created_at: String | null,
    updated_at: String | null,
    funcionalidade_id: Number | null,
    grupo_id: Number | null,
    inactivate: Boolean | null,
    dashboard_diretoria: Boolean | null,
    funcionalidade: String | null
}

const Dashboard: React.FunctionComponent = () => {
    const [typeInicio, setTypeInicio] = useState("text");
    const [typeFim, setTypeFim] = useState("text");
    const [permissaoGrupo, setPermissaoGrupo] = useState<PropsPermissao>();
    const { setMobile, mobile, setActiveSideBar } = useControlContextMobile();

    const { setDateFilter, dateActive, setSedeCadastro, sedeActive, tiposSedesCadastro, setSedesOptions } = useControlContextCadastro();

    const [dataForm, setDataForm] = useState<DateType>({
        dateInicial: null,
        dateFinal: null
    });
    const usuario = JSON.parse(localStorage.getItem("usuario") ?? "null");
    const permissaoDashboard = !permissaoGrupo?.dashboard_diretoria ? permissaoGrupo?.view ? 2 : 3 : 1;
    const getPermissoes = async () => {
        try {
            const { data } = await axiosApi.get(`permissoes-grupo/${usuario?.grupo_id}`);
            setPermissaoGrupo(data.data.filter((item: PropsPermissao) => { return item.funcionalidade === "home" })[0]);
        } catch (error) {
            console.log(error);
        }
    }

    const onChangeDate = (event: any) => {
        const { name, value } = event.target;
        setDataForm({
            ...dataForm,
            [name]: value
        })
    };

    const onChangeEventSelect = (item: any, action: any) => {
        setSedeCadastro(item.value);
    }

    useEffect(() => {
        setDateFilter(dataForm.dateInicial, dataForm.dateFinal);
    }, [dataForm]);

    useEffect(() => {
        setMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        setSedesOptions();
        getPermissoes();
        setActiveSideBar(false);
    }, []);

    const Dashboards = (grupoId: number) => {
        switch (grupoId) {
            case 1:
                return (
                    <DashboardDirector
                        dateInicial={dataForm?.dateInicial}
                        dateFinal={dataForm?.dateFinal}
                        sedeId={sedeActive}
                    />);
            case 2:
                return (
                    <DashboardUser
                        dateInicial={dataForm?.dateInicial}
                        dateFinal={dataForm?.dateFinal}
                        sedeId={sedeActive}
                    />);
            default:
                return null;
        }
    }

    const DashboardsMobile = (grupoId: number) => {
        switch (grupoId) {
            case 1:
                return (
                    <DashboardDirectorMobile
                        dateInicial={dataForm?.dateInicial}
                        dateFinal={dataForm?.dateFinal}
                        sedeId={sedeActive}
                    />);
            case 2:
                return (
                    <DashboardUserMobile
                        dateInicial={dataForm?.dateInicial}
                        dateFinal={dataForm?.dateFinal}
                        sedeId={sedeActive}
                    />);
            default:
                return null;
        }
    }

    return (
        <Fragment>
            <div className="flex flex-col w-full gap-5">
                <div className={`flex flex-col w-full pt-12 px-8 ${permissaoDashboard === 3 ? 'hidden' : ''}`}>
                    {mobile ? <FiltroMobile
                        tiposSedesCadastro={tiposSedesCadastro}
                        usuario={usuario}
                        onChangeEventSelect={onChangeEventSelect}
                        sedeActive={sedeActive}
                        typeInicio={typeInicio}
                        setTypeInicio={setTypeInicio}
                        dateActive={dateActive}
                        onChangeDate={onChangeDate}
                        typeFim={typeFim}
                        setTypeFim={setTypeFim}
                    /> : <FiltroDesktop
                        tiposSedesCadastro={tiposSedesCadastro}
                        usuario={usuario}
                        onChangeEventSelect={onChangeEventSelect}
                        sedeActive={sedeActive}
                        typeInicio={typeInicio}
                        setTypeInicio={setTypeInicio}
                        dateActive={dateActive}
                        onChangeDate={onChangeDate}
                        typeFim={typeFim}
                        setTypeFim={setTypeFim}
                    />}
                </div>
                <div className="flex flex-col w-full">
                    {mobile ? DashboardsMobile(permissaoDashboard) : Dashboards(permissaoDashboard)}
                </div>
            </div>

        </Fragment>
    );
}

export default Dashboard;