import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { MdArrowBackIos, MdDeleteOutline, MdOutlineImage, MdOutlineModeEdit, MdPlayCircle } from "react-icons/md";
import "../CardMidiaNoticia/style.css"

const grid = 3;

const getItemStyle = (draggableStyle: any) => ({
    userSelect: 'none',
    padding: grid * 2,
    ...draggableStyle,
});


const getListStyle = () => ({
    display: 'auto',
    padding: grid,
    overflow: 'auto',
    heigth: 96
});

type Props = {
    onChangePhotos?: (e: Array<any>) => void,
    onChangeVideo?: (e: any) => void,
    onClickEdit?: (e: any) => void,
    onClickDelete?: (e: number) => void,
    ArrayPhotos?: Array<any>,
    isFilesMidia?: Boolean,
};

// type ListItems = {
//     id?: any,
//     name?: string,
//     url_image?: string,
//     raw?: any
// };

export default function CardMidiaNoticia({
    onChangePhotos,
    onChangeVideo,
    onClickEdit,
    onClickDelete,
    ArrayPhotos = [],
    isFilesMidia,
}: Props): JSX.Element {
    const [buttomActive, setButtomActive] = useState('');
    const [image, setImage] = useState(
        {
            preview: "",
            raw: ""
        }
    );
    

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result
        if (!destination) return

        const items = Array.from(ArrayPhotos);
        const [newOrder] = items.splice(source.index, 1)
        items.splice(destination.index, 0, newOrder)

        onChangePhotos?.(items);
    }

    const onImageChange = (event: any) => {
        const { files } = event.target;
        if (ArrayPhotos.length < 3 && files.length) {
            //no remove
            setImage({
                ...image,
                preview: URL.createObjectURL(files[0]),
                raw: files[0],
            });
            ArrayPhotos.push({
                id: String(files[0].lastModified),
                name: files[0].name,
                url_image: URL.createObjectURL(files[0]),
                raw: files[0],
            });
        }
    };
    useEffect(() => {
        if (isFilesMidia) {
            setButtomActive("photo");
        }
    }, [isFilesMidia])


    return (
        <div className="w-full h-auto p-5 flex flex-col justify-between rounded-lg shadow-lg border border-gray-200 bg-white">
            <div>
                <div className="flex items-center gap-1">
                    {buttomActive !== '' ? <button className="flex p-2 focus:outline-none " onClick={() => setButtomActive('')}>
                        <MdArrowBackIos size={'1.3rem'} className={`text-black`} />
                    </button> : ''}
                    <h2 className="font-bold text-lg">Mídia</h2>
                </div>
                <p className="text-sm mt-2 text-gray-500">{buttomActive === 'photo' ? 'Publique com até três imagens ou um vídeo. As imagens devem ser nos formatos .png, .jpg e .jpeg, com proporção 2x1, tamanho mínimo de 296x592 pixels e máximo de 592x1184 pixels.' : 'Publique com até três imagens ou um vídeo.'}</p>
            </div>
            <div className="w-full">
                {buttomActive === 'photo' ? <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId="droppable"
                        key={"droppable"}
                        direction="vertical"
                        renderClone={(provided, snapshot, rubric) => (
                            <div
                                className={`flex rounded-lg mb-1 bg-white items-center gap-2 justify-between border border-gray-200`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                    provided.draggableProps.style
                                )}
                            >
                                <div className="flex flex-row items-center gap-2">
                                    <div className="flex flex-row gap-1">
                                        <div className="flex flex-col gap-1">
                                            <div className="rounded-full bg-gray-600 w-1 h-1" />
                                            <div className="rounded-full bg-gray-600 w-1 h-1" />
                                            <div className="rounded-full bg-gray-600 w-1 h-1" />
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <div className="rounded-full bg-gray-600 w-1 h-1" />
                                            <div className="rounded-full bg-gray-600 w-1 h-1" />
                                            <div className="rounded-full bg-gray-600 w-1 h-1" />
                                        </div>
                                    </div>
                                    <div className="rounded-lg bg-gray-600 w-24 h-24">
                                        <img className="inset-0 h-full w-full rounded-lg object-cover object-center" alt="imagem" src={ArrayPhotos[rubric.source.index].url_image} />
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <button type="button" className="flex h-10 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl" onClick={() => onClickEdit?.(ArrayPhotos[rubric.source.index].id)}>
                                        <MdOutlineModeEdit size={'1.3rem'} className={`text-gray-system-mega-imoveis`} />
                                    </button>
                                    <button type="button" className="flex h-10 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl" onClick={() => onClickDelete?.(ArrayPhotos[rubric.source.index].id)}>
                                        <MdDeleteOutline size={'1.3rem'} className={`text-gray-system-mega-imoveis`} />
                                    </button>
                                </div>
                            </div>
                        )}
                    >
                        {(provided) => (
                            <div className="w-full my-2 flex flex-col" style={getListStyle()} {...provided.droppableProps} ref={provided.innerRef}>
                                {ArrayPhotos.map(({ id, name, url_image }, index) => {
                                    return (
                                        <Draggable key={id} draggableId={String(id)} index={index} >
                                            {(provided) => (
                                                <div
                                                    className={`flex rounded-lg mb-1 bg-white items-center gap-2 justify-between border border-gray-200`}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div className="flex flex-row items-center gap-2">
                                                        <div className="flex flex-row gap-1">
                                                            <div className="flex flex-col gap-1">
                                                                <div className="rounded-full bg-gray-600 w-1 h-1" />
                                                                <div className="rounded-full bg-gray-600 w-1 h-1" />
                                                                <div className="rounded-full bg-gray-600 w-1 h-1" />
                                                            </div>
                                                            <div className="flex flex-col gap-1">
                                                                <div className="rounded-full bg-gray-600 w-1 h-1" />
                                                                <div className="rounded-full bg-gray-600 w-1 h-1" />
                                                                <div className="rounded-full bg-gray-600 w-1 h-1" />
                                                            </div>
                                                        </div>
                                                        <div className="rounded-lg bg-gray-600 w-24 h-24">
                                                            <img className="inset-0 h-full w-full rounded-lg object-cover object-center" alt="imagem" src={url_image} />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row gap-2">
                                                        <button type="button" className="flex h-10 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl" onClick={() => onClickEdit?.(id)}>
                                                            <MdOutlineModeEdit size={'1.3rem'} className={`text-gray-system-mega-imoveis`} />
                                                        </button>
                                                        <button type="button" className="flex h-10 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl" onClick={() => onClickDelete?.(id)}>
                                                            <MdDeleteOutline size={'1.3rem'} className={`text-gray-system-mega-imoveis`} />
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext> : ''}
                {buttomActive === 'video' ? <div className="flex items-center gap-4">
                    <input
                        name="video_link"
                        className="block p-4 w-full my-4 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                        placeholder={'Insira o link do vídeo do YouTube'}
                        onChange={onChangeVideo}
                    />
                    {/* <div className="flex flex-row gap-2">
                        <button className="flex h-10 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl" onClick={onClickEdit}>
                            <MdOutlineModeEdit size={'1.3rem'} className={`text-gray-system-mega-imoveis`} />
                        </button>
                        <button className="flex h-10 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl" onClick={onClickDelete}>
                            <MdDeleteOutline size={'1.3rem'} className={`text-gray-system-mega-imoveis`} />
                        </button>
                    </div> */}
                </div> : ''}
            </div>
            {buttomActive === 'photo' ? <div className="flex mt-2">
                <input
                    name="ds_caminho"
                    id="fileUploadPhoto"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={onImageChange}
                    style={{ display: "none" }}
                />
                <label
                    htmlFor="fileUploadPhoto"
                    className="flex h-10 cursor-pointer space-x-2 items-center border border-gray-400 px-3 py-2 bg-gray-300 focus:outline-none rounded-md drop-shadow-md"
                >
                    <span className="text-gray-700">Adicionar foto</span>
                    <MdOutlineImage size={'1.3rem'} style={{ color: '#807E7E' }} />
                </label>
            </div> : ''}
            {buttomActive === '' ? <div className="flex gap-3 mt-2">
                <label
                    onClick={() => setButtomActive('photo')}
                    className="flex h-10 cursor-pointer space-x-2 items-center border border-gray-400 px-3 py-2 bg-gray-300 focus:outline-none rounded-md drop-shadow-md"
                >
                    <span className="text-gray-700">Adicionar foto</span>
                    <MdOutlineImage size={'1.3rem'} style={{ color: '#807E7E' }} />
                </label>
                <label
                    onClick={() => setButtomActive('video')}
                    className="flex h-10 cursor-pointer space-x-2 items-center border border-gray-400 px-3 py-2 bg-gray-300 focus:outline-none rounded-md drop-shadow-md"
                >
                    <span className="text-gray-700">Adicionar vídeo</span>
                    <MdPlayCircle size={'1.3rem'} style={{ color: '#807E7E' }} />
                </label>
            </div> : ''}
        </div>
    );
}
