import React, { Fragment, useEffect, useState } from 'react';

import Loader from 'src/components/Basicos/Loader';
import TableWithGlobalSearch from 'src/components/Basicos/TableWithGlobalSearch';
import { compararDatas } from 'src/helpers/functions';
import { axiosApi } from 'src/services/axiosInstance';
import { useControlContextCadastro } from '../../../contexts/CadastroControlContext';
import { locatario } from "./data-columns.json";
import { useControlContextMobile } from 'src/contexts/MobileContext';
import IndexMobile from './indexMobile';


const CadastroLocatario: React.FunctionComponent = () => {
    const [data, setData] = useState<Array<any>>([]);
    const { etapaActive, dateActive, setDateFilter, sedeActive, checkCancelado, statusEtapaActive } = useControlContextCadastro();
    const [loader, setLoader] = useState(false);
    const { setMobile, mobile } = useControlContextMobile();

    const getLocatarios = async () => {
        try {
            setLoader(true)
            const { data } = await axiosApi.get(`/locatario`);
            setData(data?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
            setDateFilter(null, null);
        }
    }

    const filterLocatarioByStatus = (statusId: Number) => {
        if (statusId !== 0) {
            return data.filter((item: any) => { return item?.ultimo_historico?.etapa_historico_validacao_id === statusId });
        }
        return data;
    };

    const filterLocatarioByStatusEtapa = (data: any) => {
        if (statusEtapaActive !== 0) {
            return data.filter((item: any) => { return item?.ultimo_historico?.status_validacao_id === statusEtapaActive });
        }
        return data;
    };

    const filterLocatarioByDate = (dateInicial: any, dateFinal: any) => {
        return compararDatas(dateInicial, dateFinal, filterLocatarioByStatus(etapaActive));
    };

    const filterSedeResponsavel = (filterLocatarioByDate: any, sedeId: number) => {
        if (sedeId !== 0)
            return filterLocatarioByDate.filter((item: any) => { return item?.sede_id === sedeId });
        return filterLocatarioByDate;
    };

    const filterCancelado = (data: any) => {
        if (checkCancelado == true)
            return data.filter((item: any) => { return item?.status == 2 })
        else
            return data.filter((item: any) => { return item?.status == 1 })
    }

    useEffect(() => {
        getLocatarios();
        setMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }, []);

    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
                {mobile ?
                    <IndexMobile
                        data={filterSedeResponsavel(filterCancelado(filterLocatarioByStatusEtapa(filterLocatarioByDate(dateActive.dateInicial, dateActive.dateFinal))).sort((a: any, b: any) => Number(b.id) - Number(a.id)), sedeActive).filter((e: any) => { return e?.principal === true })}
                    />
                    :
                    <div className='row-auto' style={classes.container}>
                        <div className="card-content">
                            <div className="grid flex-col p-6">
                                <TableWithGlobalSearch
                                    columns={locatario.columns}
                                    pathName={`locatario/detalhes`}
                                    data={filterSedeResponsavel(filterCancelado(filterLocatarioByStatusEtapa(filterLocatarioByDate(dateActive.dateInicial, dateActive.dateFinal))).sort((a: any, b: any) => Number(b.id) - Number(a.id)), sedeActive).filter((e: any) => { return e?.principal === true })}
                                    actions={{
                                        show: () => { }
                                    }}
                                    isButtons={true}
                                    itsCount={true}
                                    requestClose={getLocatarios}
                                />
                            </div>
                        </div>
                    </div>
                }

            </Loader>

        </Fragment>
    );
}

const classes = {
    container: {
        marginTop: '3.5rem',
        marginButtom: '3.5rem',
        marginLeft: '2rem',
        marginRight: '2rem',
    },
};

export default CadastroLocatario;