import React, { createContext, useContext, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { axiosApi } from 'src/services/axiosInstance';
import { formatandoPermissoes } from 'src/components/Basicos/funcoes';
import { permissoesRotas } from 'src/components/Basicos/data-permissoes-rotas.json';

const ControlPermissoesContext = createContext({});

interface IControlContextPermissoesProps {
    children: React.ReactNode
}

const PermissoesProvider: React.FC<IControlContextPermissoesProps> = React.memo(({ children }) => {
    const [dadosPermissao, setDadosPermissao] = useState<any>();
    const [loader, setLoader] = useState<boolean>(true);
    const [dadosPermissaoFormatado, setDadosPermissaoFormatado] = useState<any>();
    const [infoGrupo, setInfoGrupo] = useState<any>();
    const [usuarios, setUsuarios] = useState<any>();
    const grupoId = window.location.href.split("?")[1];

    const setDados = (id: string, permissoesRotas: any, dadosPermissaoFormatado: any): void => {
        var idQuebrado = id.split("/");
        if (idQuebrado[0] == "Home" || idQuebrado[0] == "Notícias") {
            var rota: any = Object.keys(permissoesRotas).find(key => permissoesRotas[key] === idQuebrado[0]);
            var index = 1;
        } else if (idQuebrado[1] == "Antecipação") {
            var rota: any = Object.keys(permissoesRotas).find(key => permissoesRotas[key] === idQuebrado[0] + "/" + idQuebrado[1] + "/" + idQuebrado[2]);
            var index = 3;
        } else if (idQuebrado[0] == "Consulta") {
            var rota: any = Object.keys(permissoesRotas).find(key => permissoesRotas[key] === idQuebrado[1]);
            var index = 2;
        }
        else {
            var rota: any = Object.keys(permissoesRotas).find(key => permissoesRotas[key] === idQuebrado[0] + "/" + idQuebrado[1]);
            var index = 2;
        }
        var funcao = idQuebrado[index] == "Visualizar" ? "view" : idQuebrado[index] == "Incluir" ? "create" : idQuebrado[index] == "Editar" ? "edit" : idQuebrado[index] == "Excluir" ? "delete" : idQuebrado[index] == "Aprovar cadastro Gerência" ? "aprovar_cadastro_gerencia" : idQuebrado[index] == "Aprovar cadastro Diretoria" ? "aprovar_cadastro_diretoria" : idQuebrado[index] == "Visualizar Dashboard Diretoria" ? "dashboard_diretoria" : idQuebrado[index] == "Excluir Locatário Secundário" ? "excluir_locatario_secundario" : "inactivate";
        var dados = dadosPermissao?.[rota]
        var newObj = {}
        if (dados != undefined) {
            dados[funcao] = !dadosPermissaoFormatado?.[id]
            newObj = dados
        }
        else {
            if (idQuebrado[0] == "Consulta") {
                var bolFuncao = !dadosPermissaoFormatado?.[id.split("/")[1] + "/" + id.split("/")[2]]
            } else
                var bolFuncao = !dadosPermissaoFormatado?.[id]
            newObj = {
                "funcionalidade": rota,
                [funcao]: bolFuncao,
                "grupo_id": grupoId
            }
        }
        setDadosPermissao({
            ...dadosPermissao,
            [rota]: newObj
        })
    }

    const getUsuariosGrupo = async () => {
        if (grupoId != undefined) {
            const { data } = await axiosApi.get(`usuarios-grupo/${grupoId}`)
            setUsuarios(data.data)
        }
    }

    const savePermissoes = async (history: any) => {
        try {
            setLoader(true);
            var request = grupoId == undefined ? "POST" : "PUT";
            var url = grupoId == undefined ? "grupos" : `grupos/${grupoId}`;
            const response = await axiosApi(
                {
                    method: request,
                    url: url,
                    data: infoGrupo
                }
            );
            var post = undefined
            if (request == "POST") {
                post = {
                    permissoes: dadosPermissao,
                    grupo_id: response.data.data.id
                }
            }
            await axiosApi(
                {
                    method: request,
                    url: 'permissoes-funcionalidade',
                    data: post != undefined ? post : dadosPermissao
                }
            );
            setDadosPermissaoFormatado(undefined);
            setInfoGrupo(undefined);
            history.push(`/auxiliares/grupos-usuarios`)
            setLoader(false)
        } catch (error) {
            console.log(error);
        }
    }

    const getPermissoes = async () => {
        if (window.location.href.split("?")[1] != undefined) {
            const response = await axiosApi.get(`permissoes-grupo/${grupoId}`);
            setDadosPermissaoFormatado(formatandoPermissoes(response.data.data))
            const { data } = await axiosApi.get(`grupos/${grupoId}`);
            setInfoGrupo(data.data)
        } else {
            setInfoGrupo({ ...infoGrupo, status: true })
            setDadosPermissaoFormatado([])
        }
        setLoader(false)
    }

    const getValueCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        var id = event.target.id;
        if (id.split("/")[0] == "Consulta") {
            var novoFormatado = {
                ...dadosPermissaoFormatado,
                [id.split("/")[1] + "/" + id.split("/")[2]]: !dadosPermissaoFormatado?.[id.split("/")[1] + "/" + id.split("/")[2]]
            }
        } else {
            var novoFormatado = {
                ...dadosPermissaoFormatado,
                [id]: !dadosPermissaoFormatado?.[id]
            }
        }
        setDados(id, permissoesRotas, dadosPermissaoFormatado)
        setDadosPermissaoFormatado(novoFormatado)
    }

    return (
        <ControlPermissoesContext.Provider value={{
            dadosPermissao,
            loader,
            dadosPermissaoFormatado,
            infoGrupo,
            grupoId,
            usuarios,
            getUsuariosGrupo,
            setInfoGrupo,
            setLoader,
            setDados,
            savePermissoes,
            getPermissoes,
            setDadosPermissaoFormatado,
            getValueCheckBox,
            setUsuarios
        }}>
            {children}
        </ControlPermissoesContext.Provider>
    );
});

function useControlContextPermissoes(): any {
    const context = useContext(ControlPermissoesContext);
    if (!context) {
        throw new Error("useControll must a be used with PermissoesProvider");
    }
    return context;
}

export { PermissoesProvider, useControlContextPermissoes };
