import React from 'react';
import { ClassicProps } from 'src/@types/types';


type Props = {
    restricao?: any;
};

export default function ConsultaSerasa({
    restricao
}: Props & ClassicProps): JSX.Element {

    return (
        <>
            <div className='flex'>
                <h1>Tipo:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.tipo}</h1>
                <h1 className='ml-2'>SubTipo:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.subtipo}</h1>
            </div>
            <div className='flex'>
                <h1>Data da emissão do primeiro cheque à vista:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.dataemissaoprimeirocheque}</h1>
                <h1 className='ml-2'>Data da emissão do último cheque à vista:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.dataemissaoultimocheque}</h1>
            </div>
            <div className='flex'>
                <h1>Total de cheques à vista emitidos nos últimos 15 dias:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.totalchequesavistaultimos15dias}</h1>
                <h1 className='ml-2'>Total de cheques à prazo emitidos nos últimos 30 dias:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.totalchequesaprazoultimos30dias}</h1>
            </div>
            <div className='flex mb-4'>
                <h1>Total de cheques à prazo emitidos nos últimos entre 31 e 60 dias:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.totalchequesaprazoultimos30e60dias}</h1>
                <h1 className='ml-2'>Total de cheques à prazo emitidos nos últimos entre 61 e 90 dias:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.totalchequesaprazoultimos61e90dias}</h1>
            </div>
            <div className='flex mb-4'>
                <h1>Total de cheques a prazo emitidos:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.totalchequesaprazo}</h1>
            </div>
        </>
    );
}
