import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
// import Header from "src/components/Basicos/Header";
import Loader from "src/components/Basicos/Loader";
import Modal from "src/components/Funcionalidades/ModalNoticia";
import { axiosApi } from "src/services/axiosInstance";
import FormularioCadastroImovel from "./FormImovel";
import { useControlContextImovel } from "src/contexts/ImovelContext";


const FormularioValidateCadastroImovel: React.FunctionComponent = () => {
    const { imovelProprietarioId }: any = useParams();
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const [motivo, setMotivo] = useState<any>();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    const itsView = location.pathname.split('/').includes('view');
    const {
        setImovelPDF
    } = useControlContextImovel();

    let nulos = [null, undefined];

    const [valueForm, setValueForm] = useState<any>({
        finalidade_imovel: null,
        tipo_imovel: null,
        matricula_imovel: null,
        valor_locacao: null,
        valor_venda: null,
        matricula_escritura_contrato: null,
        cep_imovel: null,
        logradouro_imovel: null,
        numero_imovel: null,
        complemento_imovel: null,
        bairro_imovel: null,
        cidade_imovel: null,
        estado_imovel: null,
        iptu_imovel: null,


        dormitorio_imovel: null,
        suites_imovel: null,
        area_imovel: null,
        vagas_imovel: null,
        nome_condominio_imovel: null,
        outras_caracteristicas_imovel: null,
        vl_condominio: null,
        arquivo_agua_imovel: null,
        arquivo_gas_imovel: null,
        arquivo_condominio_imovel: null,
        arquivo_iptu_imovel: null,
        arquivo_energia_imovel: null,
    });

    const [ImovelValue, setImovelValue] = useState<any>();

    const [sections, setSections] = useState<any>({
        dados_imovel: null,
        caracteristica_imovel: null,
    });

    const handleClick = (name: string, value: boolean) => {
        setValueForm({
            ...valueForm,
            [name]: value
        });
    };

    const handleRejected = (value: boolean) => {
        setOpen(value);
    };

    const onchange = (e: any) => {
        const { name, value } = e.target;
        setImovelValue({
            ...ImovelValue,
            [name]: value
        });
        if (name === 'motivoReprovacao') {
            setMotivo(value);
        }
    };

    const onSubmitReprovacao = async (e: any) => {
        try {
            e.preventDefault();

            if (imovelProprietarioId !== null && motivo !== '') {
                setLoader(true)
                const data = {
                    imovel_proprietario_id: imovelProprietarioId,
                    etapa_id: 3,
                    comentario: motivo ?? null
                }
                await axiosApi.post(`imovel-proprietario/status/reprovado`, data);
                setOpen(false);
                history.goBack();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const getImovel = async () => {
        try {
            if (imovelProprietarioId !== null) {
                setLoader(true)
                const { data } = await axiosApi.get(`/imovel-proprietario/${imovelProprietarioId}`);
                setImovelPDF(data?.data);
                setImovelValue(data?.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const getValidacao = async () => {
        try {
            if (imovelProprietarioId !== null) {
                setLoader(true)
                const { data } = await axiosApi.get(`/validacao/imovel-proprietario/${imovelProprietarioId}`);
                const validacoes = data?.data;
                setValueForm({ ...validacoes });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }


    const selectOneSecao = (secao: number, value: boolean) => {

        switch (secao) {
            case 0:
                const dadosImovelArray = [];
                dadosImovelArray.push(
                    { [`tipo_imovel`]: value },
                    { [`finalidade_imovel`]: value },
                    { [`matricula_imovel`]: value },
                    { [`locacao_imovel`]: value },
                    { [`venda_imovel`]: value },
                    { [`principal_por_procuracao`]: value },
                    { [`matricula_escritura_contrato`]: value },
                    { [`cep_imovel`]: value },
                    { [`logradouro_imovel`]: value },
                    { [`numero_imovel`]: value },
                    { [`complemento_imovel`]: value },
                    { [`bairro_imovel`]: value },
                    { [`cidade_imovel`]: value },
                    { [`estado_imovel`]: value },
                    { [`iptu_imovel`]: value },
                    { [`valor_locacao`]: value },
                    { [`valor_venda`]: value },
                );

                const mergeObjectDadosImovel = dadosImovelArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectDadosImovel });
            case 1:
                const caracteristicasImovel = [];
                caracteristicasImovel.push(
                    { [`qtd_proprietarios`]: value }
                );

                if (!nulos.includes(ImovelValue?.arquivo_agua?.nome)) {
                    caracteristicasImovel.push({ [`arquivo_agua_imovel`]: value });

                }
                if (!nulos.includes(ImovelValue?.arquivo_gas?.nome)) {
                    caracteristicasImovel.push({ [`arquivo_gas_imovel`]: value });

                }
                if (!nulos.includes(ImovelValue?.arquivo_condominio?.nome)) {
                    caracteristicasImovel.push({ [`arquivo_condominio_imovel`]: value });

                }
                if (!nulos.includes(ImovelValue?.arquivo_iptu?.nome)) {
                    caracteristicasImovel.push({ [`arquivo_iptu_imovel`]: value });

                }
                if (!nulos.includes(ImovelValue?.arquivo_arquivo_energia?.nome)) {
                    caracteristicasImovel.push({ [`arquivo_energia_imovel`]: value });
                }

                const mergeObjectCaracteristicasImovel = caracteristicasImovel.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectCaracteristicasImovel });
        }
    }


    const secaoItsFull = (secao: number) => {

        const values = [
            // 0 - Dados do Imovel 
            [
                "tipo_imovel",
                "finalidade_imovel",
                "matricula_imovel",
                "locacao_imovel",
                "venda_imovel",
                "principal_por_procuracao",
                "matricula_escritura_contrato",
                "cep_imovel",
                "logradouro_imovel",
                "numero_imovel",
                "complemento_imovel",
                "bairro_imovel",
                "cidade_imovel",
                "estado_imovel",
                "iptu_imovel",
                "valor_locacao",
                "valor_venda",
            ],
            //1 - Caracteristicas do imovel
            [
                "qtd_proprietarios",
            ],

        ];

        if (!nulos.includes(ImovelValue?.arquivo_agua?.nome)) {
            values[1].push("arquivo_agua_imovel");

        }
        if (!nulos.includes(ImovelValue?.arquivo_gas?.nome)) {
            values[1].push("arquivo_gas_imovel");

        }
        if (!nulos.includes(ImovelValue?.arquivo_condominio?.nome)) {
            values[1].push("arquivo_condominio_imovel");

        }
        if (!nulos.includes(ImovelValue?.arquivo_iptu?.nome)) {
            values[1].push("arquivo_iptu_imovel");

        }
        if (!nulos.includes(ImovelValue?.arquivo_arquivo_energia?.nome)) {
            values[1].push("arquivo_energia_imovel");
        }

        const isFullTrue = Object.values(values[secao]).every(item => valueForm[`${item}`] === true);
        const isFullFalse = Object.values(values[secao]).every(item => valueForm[`${item}`] === false);

        return isFullTrue && !isFullFalse ? isFullTrue : !isFullTrue && isFullFalse ? !isFullFalse : null;
    }

    const onTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        getValidacao();
        getImovel();
        onTop();
    }, []);

    useEffect(() => {
        setSections({
            ...sections,
            dados_imovel: secaoItsFull(0),
            caracteristica_imovel: secaoItsFull(1),
        });
    }, [valueForm]);

    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
                <Modal
                    isOpen={open}
                    isClose={() => setOpen(false)}
                    headerStyle={''}
                    closeOnOutsideClick={true}
                    widthModal={`40%`}
                    modalStyle={`modal-style bg-white rounded-lg py-5 px-10`}
                >
                    <div>
                        <form onSubmit={onSubmitReprovacao} className={`flex flex-col items-center`}>
                            <div className="flex">
                                <p className="font-extrabold text-center text-2xl mb-4 font-mont-bold" style={{ color: "#081E52" }}>Adicione o motivo da reprovação</p>
                            </div>
                            <div className="flex w-full">
                                <textarea
                                    rows={2}
                                    name={`motivoReprovacao`}
                                    value={motivo ?? null}
                                    style={{ maxHeight: "15rem", minHeight: "3.5rem" }}
                                    className="block p-4 w-full h-20 mt-1 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                                    placeholder={'Dê detalhes do porquê o cadastro foi reprovado'}
                                    required={true}
                                    onChange={onchange}
                                />
                            </div>
                            <div className="flex w-full justify-center">
                                <button
                                    type="submit"
                                    className={`flex w-full h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs bg-blue-mega-imoveis text-white cursor-pointer rounded-lg justify-center items-center`}
                                >
                                    Enviar para o proprietário revisar
                                </button>
                            </div>
                        </form>

                    </div>
                </Modal>
                <FormularioCadastroImovel
                    imovel={ImovelValue}
                    campos={valueForm}
                    handleClick={handleClick}
                    selectSection={selectOneSecao}
                    sections={sections}
                    onChange={onchange}
                    openModal={handleRejected}
                    itsView={itsView}
                />
            </Loader>
        </Fragment>
    );
}

export default FormularioValidateCadastroImovel;