import React, { useState } from 'react';
import { MdDeleteOutline, 
    // MdGroups,
    MdOutlineGroups } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import ModalDelete from 'src/components/Funcionalidades/ModalDelete';
import ModalUsuarios from 'src/components/Funcionalidades/ModalUsuarios';
import SlideButton from 'src/components/SlideButton';
import { useControlContextPermissoes } from 'src/contexts/PermissoesContext';
import './style.css';

const Form: React.FunctionComponent = () => {
    const {
        infoGrupo,
        setInfoGrupo,
        grupoId,
        usuarios
    } = useControlContextPermissoes();
    const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
    const [openModalUsuarios, setOpenModalUsuarios] = useState<any>(false);
    const history = useHistory();

    const onChange = (event: any) => {
        const { name, value } = event.target;
        setInfoGrupo({
            ...infoGrupo,
            [name]: value
        })
    }

    const onChangeSlide = (event: any) => {
        const { name, checked } = event.target;
        setInfoGrupo({
            ...infoGrupo,
            [name]: checked
        })
    }

    const closeModal = (): void => {
        setOpenModalDelete(false);
    }

    const onClickDelete = () => {
        setOpenModalDelete(true);
    }

    const posDelete = () => {
        history.push(`/auxiliares/grupos-usuarios`)
    }

    const onClickUsuarios = () => {
        setOpenModalUsuarios(!openModalUsuarios)
    }

    return (
        <>
            <ModalDelete
                modalId={grupoId}
                open={openModalDelete}
                titulo={`Tem certeza que deseja excluir?`}
                nameRouter={'grupos'}
                isClose={closeModal}
                requestData={posDelete}
            />
            <ModalUsuarios
                open={openModalUsuarios}
                onCloseModal={onClickUsuarios}
                usuarios={usuarios}
                titulo={`Usuários - ${infoGrupo?.descricao}`}
            />
            <div className='mb-10'>
                <p>Nome do Grupo de Usuários</p>
                <div className='form-permissoes'>
                    <input
                        type='text'
                        name='descricao'
                        onChange={onChange}
                        value={infoGrupo?.descricao ?? ""}
                        className="modal-input p-1 form-permissoes"
                    />
                    <SlideButton
                        id=""
                        name="status"
                        onChange={onChangeSlide}
                        checked={infoGrupo?.status} />
                    <a className="flex h-10 w-10 items-center justify-center text-gray-system-mega-imoveis bg-gray-ligth-system-mega-imoveis p-2 focus:outline-none rounded-xl cursor-pointer" onClick={onClickDelete}>
                        <MdDeleteOutline size={'1.3rem'} className={`text-gray-system-mega-imoveis`} />
                    </a>
                    <a className="flex h-10 w-10 items-center justify-center text-gray-system-mega-imoveis bg-gray-ligth-system-mega-imoveis p-2 focus:outline-none rounded-xl cursor-pointer" onClick={onClickUsuarios}>
                        <MdOutlineGroups size={'1.3rem'} className={`text-gray-system-mega-imoveis`} />
                    </a>
                </div>
            </div>
        </>
    );
}

export default Form;