import React from 'react';
import './style.css';
import { ajustUserAgent, converterDataBr, formatarValor } from 'src/components/Basicos/funcoes';
import { mask } from 'src/helpers/functions';
// import TimelineCadastroFiadorMobile from 'src/components/Basicos/Timeline/TimelineCadastroFiador/indexMobile';
import TimelimeCadastroLocatarioMobile from 'src/components/Basicos/Timeline/TimelineCadastroLocatario/indexMobile';
// import TimelimeCadastroLocatario from 'src/components/Basicos/Timeline/TimelineCadastroLocatario';


type Props = {
    data: any
    TimelineFiador: any
    TimelimeLocatario: any
    fiadores: any
    fiadorSection: any
    statusTimeLine: any
    setEtapaAtual: any
    etapaAtual: any
}

const DetalhesCadastroFiadorMobile: React.FunctionComponent<Props> = ({
    data,
    TimelineFiador,
    TimelimeLocatario,
    fiadores,
    fiadorSection,
    statusTimeLine,
    setEtapaAtual,
    etapaAtual
}) => {
    return (
        <div className="p-6">
            <div className={`bg-white pt-10 pb-10 pl-5 pr-5 mb-10 flex flex-col space-y-5 rounded-lg border-gray-100 border h-full w-full`} style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>
                <div className='informacoes-basicas-mobile'>
                    <h1 className='font-mont-bold text-xl'>Informações do Fiador</h1>
                    <h1>Nome: <span className='text-gray-600'>{data?.informacoes_basicas?.nome_cliente}</span></h1>
                    <h1>E-mail: <span className='text-gray-600'>{data?.informacoes_basicas?.email}</span></h1>
                    <h1>Telefone: <span className='text-gray-600'>{mask(data?.informacoes_basicas?.telefone1, "telefone")}</span></h1>
                    <h1>CPF: <span className='text-gray-600'>{mask(data?.informacoes_basicas?.cpf ?? data?.informacoes_basicas?.cnpj, "cpf_cnpj")}</span></h1>
                    <h1>Data Cadastro: <span className='text-gray-600'>{converterDataBr(data?.created_at)}</span></h1>
                    <h1>Tempo de Atendimento: <span className='text-gray-600'>{data?.tempo_atendimento}</span></h1>
                </div>
                <div className='borda-separando'></div>
                <div className='informacoes-basicas-mobile'>
                    <h1 className='font-mont-bold text-xl'>Origem do Cadastro</h1>
                    <h1>Data e Hora: <span className='text-gray-600'>{converterDataBr(data?.created_at)}</span></h1>
                    <h1>Dispositivo: <span className='text-gray-600'>{data?.informacoes_dispositivo?.device_type}</span></h1>
                    <h1>Versão: <span className='text-gray-600'>{ajustUserAgent(data?.informacoes_dispositivo?.user_agent)}</span></h1>
                    <h1>Navegador: <span className='text-gray-600'>{data?.informacoes_dispositivo?.browser_name}</span></h1>
                    <h1>Versão do Navegador: <span className='text-gray-600'>{data?.informacoes_dispositivo?.browser_version}</span></h1>
                    <h1>Sistema Operacional: <span className='text-gray-600'>{data?.informacoes_dispositivo?.os_name}</span></h1>
                </div>
                <div className='borda-separando'></div>
                <div className='informacoes-basicas-mobile'>
                    <h1 className='font-mont-bold text-xl'>Informações do Imóvel</h1>
                    <h1>Código do Imóvel: <span className='text-gray-600'>{data?.locatario?.imovel_pretendido?.imovel?.codigo_imovel ?? data?.locatario?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.codigo_imovel}</span></h1>
                    <h1>Valor do Aluguel: <span className='text-gray-600'>{formatarValor(data?.locatario?.imovel_pretendido?.imovel?.preco_locacao ?? data?.locatario?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.preco_locacao, "dinheiro")}</span></h1>
                    <h1>Garantia: <span className='text-gray-600'>{data?.locatario?.garantia?.descricao ?? data?.locatario?.locatario_imovel?.locatario_principal?.garantia?.descricao}</span></h1>
                    <h1>Endereço: <span className='text-gray-600'>{data?.locatario?.imovel_pretendido?.imovel?.endereco + ", " + data?.locatario?.imovel_pretendido?.imovel?.numero + " - " + data?.locatario?.imovel_pretendido?.imovel?.bairro ?? data?.locatario?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.endereco + ", " + data?.locatario?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.numero + " - " + data?.locatario?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.bairro}</span></h1>
                    <h1>Sede: <span className='text-gray-600'>{data?.locatario?.sede_id ?? data?.locatario?.imovel_pretendido?.imovel?.sede_id ?? "-"}</span></h1>
                </div>
                <div className='borda-separando'></div>
                <div className='first-card-mobile'>
                    <h1 className='font-mont-bold text-xl'>Linha do Tempo</h1>
                    {TimelineFiador !== undefined ? <TimelimeCadastroLocatarioMobile
                        locatarioId={data?.id}
                        timeline={TimelineFiador}
                        setStatusTimeLine={statusTimeLine}
                        setEtapaAtual={setEtapaAtual}
                        etapaAtual={etapaAtual}
                        isPrincipal={data?.principal}
                        locatario={data}
                        itsButtons={true}
                        isFiador={true}
                        atual={true} //arranjo tecnico para saber qual o card principal
                    /> : ''}
                </div>
                {TimelimeLocatario.sort(function (x: any, y: any) { return (x?.principal === y?.principal) ? 0 : x?.principal ? -1 : 1; }).map((item: any) => {
                    return <TimelimeCadastroLocatarioMobile
                        locatario={item}
                        locatarioId={item.id}
                        timeline={item?.timeLine}
                        setStatusTimeLine={statusTimeLine}
                        setEtapaAtual={setEtapaAtual}
                        etapaAtual={etapaAtual}
                        itsButtons={false}
                        isSecundario={!item?.principal}
                        isPrincipal={item?.principal}
                    />
                })}
            </div>
        </div >
    );
}

export default DetalhesCadastroFiadorMobile;