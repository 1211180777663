import React, { Fragment } from "react";
import AnaliseGarantia from "src/components/Basicos/AnaliseGarantia";
import CheckBox from "src/components/Basicos/Form/CheckBox";
import InputValidate from "src/components/Basicos/Form/Input/inputValidate";
import { formatarValor } from "src/components/Basicos/funcoes";
import TitleValidate from "src/components/Basicos/TitleSelectedValidate";
import { mask } from "src/helpers/functions";
import './style.css'
import HistoricoLocatarioSecundario from "src/components/Basicos/HistoricoLocatarioSecundario";
// import { typeFormValue } from "./types";

export type Props = {
    locatario?: any;
    campos?: any;
    handleClick?: any;
    selectSection?: any;
    sections?: any;
    onChange?: any;
    openModal?: any;
    itsView?: boolean;
    etapaId?: any;
    locatarioId?: any;
    statusNotButton?: any;
    rgOrCnhConjuge?: any;
    rgOrCnh?: any;
    tipos_atividade_ids?: any;
    nameInterpriseOrServicePublicConjuge?: any;
    nulos?: any;
    nameInterpriseOrServicePublic?: any;
    rgOrCnhProcurador?: any;
    locatarioSecundarioSection?: any;
    fiadorSection?: any;
    addLocatario?: any;
    onClickLocatarioSecundario?: any;
    existFalse?: any;
    itsFullIsTrue?: any;
    onSubmit?: any;
    itsFull?: any;
}

const FormularioCadastroPessoaFisicaMobile: React.FunctionComponent<Props> = ({
    locatario,
    campos,
    handleClick,
    selectSection,
    sections,
    onChange,
    openModal,
    itsView,
    etapaId,
    locatarioId,
    statusNotButton,
    rgOrCnhConjuge,
    rgOrCnh,
    tipos_atividade_ids,
    nameInterpriseOrServicePublicConjuge,
    nulos,
    nameInterpriseOrServicePublic,
    rgOrCnhProcurador,
    locatarioSecundarioSection,
    fiadorSection,
    addLocatario,
    onClickLocatarioSecundario,
    existFalse,
    itsFullIsTrue,
    onSubmit,
    itsFull
}) => {
    return (
        <Fragment>
            <div className="bg-white p-6 flex flex-col rounded-lg border-white border h-full w-full" style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>

                <div className="flex">
                    <AnaliseGarantia
                        locatarioId={locatarioId}
                    />

                    <HistoricoLocatarioSecundario
                        locatarioId={locatarioId}
                    />
                </div>

                {/* Identificação */}
                <div>
                    <TitleValidate
                        title={'Identificação'}
                        selectSection={selectSection}
                        checkSection={sections?.identificacao_pessoa_fisica}
                        section={0}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`CPF`}
                        name={`cpf_locatario`}
                        value={mask(locatario?.informacoes_basicas?.cpf, 'cpf_cnpj') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.cpf_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Nome`}
                        name={`nome_locatario`}
                        value={locatario?.informacoes_basicas?.nome_cliente ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.nome_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <span className="font-extrabold text-lg ">Documento de Identificação Oficial com Foto*</span>
                    <InputValidate
                        title={`${rgOrCnh} - Frente`}
                        name={`rg_cnh_frente_locatario`}
                        value={locatario?.informacoes_basicas?.rg_cnh_frente?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        file={locatario?.informacoes_basicas?.rg_cnh_frente?.caminho ?? null}
                        status={itsView ? null : campos.rg_cnh_frente_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`${rgOrCnh} - Verso`}
                        name={`rg_cnh_verso_locatario`}
                        value={locatario?.informacoes_basicas?.rg_cnh_verso?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        file={locatario?.informacoes_basicas?.rg_cnh_verso?.caminho ?? null}
                        status={itsView ? null : campos.rg_cnh_verso_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Telefone 1`}
                        name={`telefone_locatario`}
                        value={mask(locatario?.informacoes_basicas?.telefone1, 'telefone') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.telefone_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Telefone 2`}
                        name={`telefone2_locatario`}
                        value={mask(locatario?.informacoes_basicas?.telefone2, 'telefone') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.telefone2_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`E-mail`}
                        name={`email_locatario`}
                        value={locatario?.informacoes_basicas?.email ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.email_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Estado Civil`}
                        name={`estado_civil_locatario`}
                        value={locatario?.informacoes_basicas?.estado_civil?.descricao ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.estado_civil_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Naturalidade`}
                        name={`naturalidade_locatario`}
                        value={locatario?.informacoes_basicas?.naturalidade ?? null}
                        hidden={locatario?.informacoes_basicas?.naturalidade === null}
                        onClick={handleClick}
                        status={itsView ? null : campos.naturalidade_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Nacionalidade`}
                        name={`nacionalidade_locatario`}
                        value={locatario?.informacoes_basicas?.nacionalidade ?? null}
                        hidden={locatario?.informacoes_basicas?.nacionalidade === null}
                        onClick={handleClick}
                        status={itsView ? null : campos.nacionalidade_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Nº de Dependentes`}
                        name={`numb_dependentes_locatario`}
                        value={locatario?.informacoes_basicas?.qtd_dependentes ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.numb_dependentes_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Garantia Locatícia`}
                        name={`garantia_locaticia_locatario`}
                        value={locatario?.garantia?.descricao ?? locatario?.locatario_imovel?.locatario_principal?.garantia?.descricao ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.garantia_locaticia_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                </div>

                {/* Cônjuge */}
                <div className={`mt-12 ${locatario?.conjuge === null ? 'hidden' : ''}`}>
                    <TitleValidate
                        title={'Cônjuge'}
                        selectSection={selectSection}
                        checkSection={sections?.conjuge ?? null}
                        section={1}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Nome`}
                        name={`nome_conjuge`}
                        value={locatario?.conjuge?.informacoes_basicas.nome_cliente ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.nome_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Telefone 1`}
                        name={`telefone_conjuge`}
                        value={mask(locatario?.conjuge?.informacoes_basicas.telefone1, 'telefone') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.telefone_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Telefone 2`}
                        name={`telefone2_conjuge`}
                        value={mask(locatario?.conjuge?.informacoes_basicas.telefone2, 'telefone') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.telefone2_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`${rgOrCnhConjuge} - Frente`}
                        name={`rg_cnh_frente_conjuge`}
                        value={locatario?.conjuge?.informacoes_basicas?.rg_cnh_frente?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        file={locatario?.conjuge?.informacoes_basicas?.rg_cnh_frente?.caminho ?? null}
                        status={itsView ? null : campos.rg_cnh_frente_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`${rgOrCnhConjuge} - Verso`}
                        name={`rg_cnh_verso_conjuge`}
                        value={locatario?.conjuge?.informacoes_basicas?.rg_cnh_verso?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        file={locatario?.conjuge?.informacoes_basicas?.rg_cnh_verso?.caminho ?? null}
                        status={itsView ? null : campos.rg_cnh_verso_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`CPF`}
                        name={`cpf_conjuge`}
                        value={mask(locatario?.conjuge?.informacoes_basicas.cpf, 'cpf_cnpj') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.cpf_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`E-mail`}
                        name={`email_conjuge`}
                        value={locatario?.conjuge?.informacoes_basicas.email ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.email_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Naturalidade`}
                        name={`naturalidade_conjuge`}
                        value={locatario?.conjuge?.informacoes_basicas.naturalidade ?? null}
                        hidden={locatario?.conjuge?.informacoes_basicas?.naturalidade === null}
                        onClick={handleClick}
                        status={itsView ? null : campos.naturalidade_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Nacionalidade`}
                        name={`nacionalidade_conjuge`}
                        value={locatario?.conjuge?.informacoes_basicas.nacionalidade ?? null}
                        hidden={locatario?.conjuge?.informacoes_basicas?.nacionalidade === null}
                        onClick={handleClick}
                        status={itsView ? null : campos.nacionalidade_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Tipo de Atividade Profissional`}
                        name={`tipo_atividade_profissional_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.tipo_atividade?.descricao ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.tipo_atividade_profissional_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Atividade Profissional`}
                        name={`atividade_profissional_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.outra_atividade_profissional ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.atividade_profissional_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.conjuge?.ultimo_historico?.status?.id)}
                        hidden={locatario?.conjuge?.atividade_profissional?.tipo_atividade?.id == 1 || locatario?.conjuge?.atividade_profissional?.tipo_atividade?.id == 2}
                    />
                    <InputValidate
                        title={`Ramo da Atividade`}
                        name={`ramo_atividade_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.ramo_atividade ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos?.ramo_atividade_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.conjuge?.ultimo_historico?.status?.id)}
                        hidden={locatario?.conjuge?.atividade_profissional?.tipo_atividade?.id != 2}
                    />
                    <InputValidate
                        title={`Renda Mensal`}
                        name={`renda_mensal_conjuge`}
                        value={formatarValor(locatario?.conjuge?.atividade_profissional?.renda_mensal, "dinheiro") ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.renda_mensal_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={nameInterpriseOrServicePublicConjuge}
                        name={`nome_empresa_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.nome_empresa ?? null}
                        onClick={handleClick}
                        hidden={nulos.includes(locatario?.conjuge?.atividade_profissional?.nome_empresa)}
                        status={itsView ? null : campos.nome_empresa_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Tempo de Empresa`}
                        name={`tempo_empresa_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.tempo_empresa?.descricao ?? null}
                        onClick={handleClick}
                        hidden={locatario?.conjuge?.atividade_profissional?.tempo_empresa?.descricao === undefined || locatario?.conjuge?.atividade_profissional?.tipo_atividade?.id == 1 || locatario?.conjuge?.atividade_profissional?.tipo_atividade?.id == 2}
                        status={itsView ? null : campos.tempo_empresa_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Cargo Atual`}
                        name={`cargo_atual_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.cargo_atual ?? null}
                        onClick={handleClick}
                        hidden={nulos.includes(locatario?.conjuge?.atividade_profissional?.cargo_atual)}
                        status={itsView ? null : campos.cargo_atual_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`CEP`}
                        name={`cep_empresa_atual_conjuge`}
                        value={mask(locatario?.conjuge?.atividade_profissional?.endereco?.cep, 'cep') ?? null}
                        onClick={handleClick}
                        hidden={locatario?.conjuge?.atividade_profissional?.endereco?.cep === undefined}
                        status={itsView ? null : campos.cep_empresa_atual_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Logradouro`}
                        name={`logradouro_empresa_atual_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.endereco?.logradouro ?? null}
                        onClick={handleClick}
                        hidden={locatario?.conjuge?.atividade_profissional?.endereco?.logradouro === undefined}
                        status={itsView ? null : campos.logradouro_empresa_atual_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Número`}
                        name={`numero_empresa_atual_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.endereco?.numero ?? null}
                        onClick={handleClick}
                        hidden={locatario?.conjuge?.atividade_profissional?.endereco?.numero === undefined}
                        status={itsView ? null : campos.numero_empresa_atual_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Bairro`}
                        name={`bairro_empresa_atual_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.endereco?.bairro ?? null}
                        onClick={handleClick}
                        hidden={locatario?.conjuge?.atividade_profissional?.endereco?.bairro === undefined}
                        status={itsView ? null : campos.bairro_empresa_atual_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Cidade`}
                        name={`cidade_empresa_atual_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.endereco?.cidade ?? null}
                        onClick={handleClick}
                        hidden={locatario?.conjuge?.atividade_profissional?.endereco?.cidade === undefined}
                        status={itsView ? null : campos.cidade_empresa_atual_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Estado`}
                        name={`estado_empresa_atual_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.endereco?.estado ?? null}
                        onClick={handleClick}
                        hidden={locatario?.conjuge?.atividade_profissional?.endereco?.estado === undefined}
                        status={itsView ? null : campos.estado_empresa_atual_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Complemento`}
                        name={`complemento_empresa_atual_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.endereco?.complemento ?? null}
                        onClick={handleClick}
                        hidden={locatario?.conjuge?.atividade_profissional?.endereco?.complemento === undefined || locatario?.conjuge?.atividade_profissional?.endereco?.complemento === null}
                        status={itsView ? null : campos.complemento_empresa_atual_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Último Extrato do Cartão de Crédito`}
                        name={`extrato_cartao_credito_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho === undefined}
                        file={locatario?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho ?? null}
                        status={itsView ? null : campos.extrato_cartao_credito_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <p className={`font-extrabold text-lg text-gray-system-mega-imoveis ml-6`}>{locatario?.conjuge?.atividade_profissional?.arquivo_outro?.caminho !== undefined
                        || locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.caminho !== undefined
                        || locatario?.conjuge?.atividade_profissional?.arquivo_ctps?.caminho !== undefined
                        || locatario?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.caminho !== undefined ? 'Comprovante de Renda*' : ''}</p>
                    <InputValidate
                        title={`Contracheque`}
                        name={`contracheque_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.caminho === undefined}
                        file={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.caminho ?? null}
                        status={itsView ? null : campos.contracheque_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Contracheque 2`}
                        name={`contracheque2_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.caminho === undefined}
                        file={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.caminho ?? null}
                        status={itsView ? null : campos.contracheque2_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Contracheque 3`}
                        name={`contracheque3_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.caminho === undefined}
                        file={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.caminho ?? null}
                        status={itsView ? null : campos.contracheque3_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`CTPS`}
                        name={`carteira_trabalho_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.arquivo_ctps?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.conjuge?.atividade_profissional?.arquivo_ctps?.caminho === undefined}
                        file={locatario?.conjuge?.atividade_profissional?.arquivo_ctps?.caminho ?? null}
                        status={itsView ? null : campos.carteira_trabalho_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Imposto de Renda`}
                        name={`imposto_renda_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.caminho === undefined}
                        file={locatario?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.caminho ?? null}
                        status={itsView ? null : campos.imposto_renda_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Extrato Bancário`}
                        name={`extrato_bancario_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.caminho === undefined}
                        file={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.caminho ?? null}
                        status={itsView ? null : campos.extrato_bancario_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Extrato Bancário 2`}
                        name={`extrato_bancario2_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.caminho === undefined}
                        file={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.caminho ?? null}
                        status={itsView ? null : campos.extrato_bancario2_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Extrato Bancário 3`}
                        name={`extrato_bancario3_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.caminho === undefined}
                        file={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.caminho ?? null}
                        status={itsView ? null : campos.extrato_bancario3_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Outro`}
                        name={`outro_conjuge`}
                        value={locatario?.conjuge?.atividade_profissional?.arquivo_outro?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.conjuge?.atividade_profissional?.arquivo_outro?.caminho === undefined}
                        file={locatario?.conjuge?.atividade_profissional?.arquivo_outro?.caminho ?? null}
                        status={itsView ? null : campos.outro_conjuge ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                </div>

                {/* Residência Atual - 11 campos*/}
                <div className={`mt-12 ${locatario?.residencia_atual === null ? 'hidden' : ''}`}>
                    <TitleValidate
                        title={'Residência Atual'}
                        selectSection={selectSection}
                        checkSection={sections?.residencia_atual}
                        section={2}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Sua Residência é`}
                        name={`sua_residencia_atual_e`}
                        value={locatario?.residencia_atual?.tipo_residencia?.descricao ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.sua_residencia_atual_e ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`CEP`}
                        name={`cep_residencia_atual`}
                        value={mask(locatario?.endereco?.cep, 'cep') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.cep_residencia_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Comprovante de Endereço`}
                        name={`comprovante_endereco_residencia_atual`}
                        value={locatario?.residencia_atual?.arquivo?.nome ?? null}
                        isFile={true}
                        file={locatario?.residencia_atual?.arquivo?.caminho ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.comprovante_endereco_residencia_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Logradouro`}
                        name={`logradouro_residencia_atual`}
                        value={locatario?.endereco?.logradouro ?? null}

                        onClick={handleClick}
                        status={itsView ? null : campos.logradouro_residencia_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Número`}
                        name={`numero_residencia_atual`}
                        value={locatario?.endereco?.numero ?? null}

                        onClick={handleClick}
                        status={itsView ? null : campos.numero_residencia_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Bairro`}
                        name={`bairro_residencia_atual`}
                        value={locatario?.endereco?.bairro ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.bairro_residencia_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Complemento`}
                        name={`complemento_residencia_atual`}
                        value={locatario?.endereco?.complemento ?? null}
                        onClick={handleClick}
                        hidden={locatario?.endereco?.complemento === null}
                        status={itsView ? null : campos.complemento_residencia_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Cidade`}
                        name={`cidade_residencia_atual`}
                        value={locatario?.endereco?.cidade ?? null}

                        onClick={handleClick}
                        status={itsView ? null : campos.cidade_residencia_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Estado`}
                        name={`estado_residencia_atual`}
                        value={locatario?.endereco?.estado ?? null}

                        onClick={handleClick}
                        status={itsView ? null : campos.estado_residencia_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Valor do Aluguel`}
                        name={`valor_aluguel_residencia_atual`}
                        value={formatarValor(locatario?.residencia_atual?.valor_aluguel, "dinheiro") ?? null}
                        hidden={locatario?.residencia_atual?.valor_aluguel === null}
                        onClick={handleClick}
                        status={itsView ? null : campos.valor_aluguel_residencia_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Tempo de Locação`}
                        name={`tempo_locacao_residencia_atual`}
                        value={locatario?.residencia_atual?.tempo_locacao ?? null}
                        hidden={locatario?.residencia_atual?.tempo_locacao === null}
                        onClick={handleClick}
                        status={itsView ? null : campos.tempo_locacao_residencia_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                </div>

                {/* Atividade Profissional - 20 campos */}
                <div className={`mt-12 ${locatario?.atividade_profissional === null ? 'hidden' : ''}`}>
                    <TitleValidate
                        title={'Atividade Profissional'}
                        selectSection={selectSection}
                        checkSection={sections.atividade_profissional}
                        section={3}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Tipo de Atividade Profissional`}
                        name={`tipo_atividade_profissional`}
                        value={locatario?.atividade_profissional?.tipo_atividade?.descricao ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.tipo_atividade_profissional ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Atividade Profissional`}
                        name={`atividade_profissional`}
                        value={locatario?.atividade_profissional?.outra_atividade_profissional ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos?.atividade_profissional ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                        hidden={locatario?.atividade_profissional?.tipo_atividade?.id !== 6 && locatario?.atividade_profissional?.tipo_atividade?.id !== 7}
                    />
                    <InputValidate
                        title={`Ramo da Atividade`}
                        name={`ramo_atividade`}
                        value={locatario?.atividade_profissional?.ramo_atividade ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos?.ramo_atividade ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                        hidden={locatario?.atividade_profissional?.ramo_atividade === null}
                    />
                    <InputValidate
                        title={`Renda Mensal`}
                        name={`renda_mensal`}
                        value={formatarValor(locatario?.atividade_profissional?.renda_mensal, "dinheiro") ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.renda_mensal ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={nameInterpriseOrServicePublic}
                        name={`nome_empresa`}
                        value={locatario?.atividade_profissional?.nome_empresa ?? null}
                        onClick={handleClick}
                        hidden={locatario?.atividade_profissional?.nome_empresa === null}
                        status={itsView ? null : campos.nome_empresa ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Tempo de Empresa`}
                        name={`tempo_empresa`}
                        value={locatario?.atividade_profissional?.tempo_empresa?.descricao ?? null}
                        onClick={handleClick}
                        hidden={locatario?.atividade_profissional?.tempo_empresa?.descricao === undefined}
                        status={itsView ? null : campos.tempo_empresa ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Cargo Atual`}
                        name={`cargo_atual`}
                        value={locatario?.atividade_profissional?.cargo_atual ?? null}
                        onClick={handleClick}
                        hidden={locatario?.atividade_profissional?.cargo_atual === null}
                        status={itsView ? null : campos.cargo_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />

                    <InputValidate
                        title={`CEP`}
                        name={`cep_empresa_atual`}
                        value={mask(locatario?.atividade_profissional?.endereco?.cep, 'cep') ?? null}
                        onClick={handleClick}
                        hidden={locatario?.atividade_profissional?.endereco?.cep === undefined}
                        status={itsView ? null : campos.cep_empresa_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Logradouro`}
                        name={`logradouro_empresa_atual`}
                        value={locatario?.atividade_profissional?.endereco?.logradouro ?? null}
                        onClick={handleClick}
                        hidden={locatario?.atividade_profissional?.endereco?.logradouro === undefined}
                        status={itsView ? null : campos.logradouro_empresa_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Número`}
                        name={`numero_empresa_atual`}
                        value={locatario?.atividade_profissional?.endereco?.numero ?? null}
                        onClick={handleClick}
                        hidden={locatario?.atividade_profissional?.endereco?.numero === undefined}
                        status={itsView ? null : campos.numero_empresa_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Complemento`}
                        name={`complemento_empresa_atual`}
                        value={locatario?.atividade_profissional?.endereco?.complemento ?? null}
                        onClick={handleClick}
                        hidden={locatario?.atividade_profissional?.endereco?.complemento === null || locatario?.atividade_profissional?.endereco?.complemento === undefined}
                        status={itsView ? null : campos.complemento_empresa_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Bairro`}
                        name={`bairro_empresa_atual`}
                        value={locatario?.atividade_profissional?.endereco?.bairro ?? null}
                        onClick={handleClick}
                        hidden={locatario?.atividade_profissional?.endereco?.bairro === undefined}
                        status={itsView ? null : campos.bairro_empresa_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Cidade`}
                        name={`cidade_empresa_atual`}
                        value={locatario?.atividade_profissional?.endereco?.cidade ?? null}
                        onClick={handleClick}
                        hidden={locatario?.atividade_profissional?.endereco?.cidade === undefined}
                        status={itsView ? null : campos.cidade_empresa_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Estado`}
                        name={`estado_empresa_atual`}
                        value={locatario?.atividade_profissional?.endereco?.estado ?? null}
                        onClick={handleClick}
                        hidden={locatario?.atividade_profissional?.endereco?.estado === undefined}
                        status={itsView ? null : campos.estado_empresa_atual ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Último Extrato do Cartão de Crédito`}
                        name={`extrato_cartao_credito`}
                        value={locatario?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho === undefined}
                        file={locatario?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho ?? null}
                        status={itsView ? null : campos.extrato_cartao_credito ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <p className={`font-extrabold text-lg text-gray-system-mega-imoveis ml-6`}>{locatario?.atividade_profissional?.arquivo_outro?.caminho !== undefined
                        || locatario?.atividade_profissional?.arquivo_contra_cheque?.caminho !== undefined
                        || locatario?.atividade_profissional?.arquivo_ctps?.caminho !== undefined
                        || locatario?.atividade_profissional?.arquivo_imposto_renda?.caminho !== undefined ? 'Comprovante de Renda*' : ''}</p>
                    <InputValidate
                        title={`Contracheque`}
                        name={`contracheque`}
                        value={locatario?.atividade_profissional?.arquivo_contra_cheque?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.atividade_profissional?.arquivo_contra_cheque?.caminho === undefined}
                        file={locatario?.atividade_profissional?.arquivo_contra_cheque?.caminho ?? null}
                        status={itsView ? null : campos.contracheque ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Contracheque 2`}
                        name={`contracheque2`}
                        value={locatario?.atividade_profissional?.arquivo_contra_cheque2?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.atividade_profissional?.arquivo_contra_cheque2?.caminho === undefined}
                        file={locatario?.atividade_profissional?.arquivo_contra_cheque2?.caminho ?? null}
                        status={itsView ? null : campos.contracheque2 ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Contracheque 3`}
                        name={`contracheque3`}
                        value={locatario?.atividade_profissional?.arquivo_contra_cheque3?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.atividade_profissional?.arquivo_contra_cheque3?.caminho === undefined}
                        file={locatario?.atividade_profissional?.arquivo_contra_cheque3?.caminho ?? null}
                        status={itsView ? null : campos.contracheque3 ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`CTPS`}
                        name={`carteira_trabalho`}
                        value={locatario?.atividade_profissional?.arquivo_ctps?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.atividade_profissional?.arquivo_ctps?.caminho === undefined}
                        file={locatario?.atividade_profissional?.arquivo_ctps?.caminho ?? null}
                        status={itsView ? null : campos.carteira_trabalho ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Imposto de Renda`}
                        name={`imposto_renda`}
                        value={locatario?.atividade_profissional?.arquivo_imposto_renda?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.atividade_profissional?.arquivo_imposto_renda?.caminho === undefined}
                        file={locatario?.atividade_profissional?.arquivo_imposto_renda?.caminho ?? null}
                        status={itsView ? null : campos.imposto_renda ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Extrato Bancário`}
                        name={`extrato_bancario`}
                        value={locatario?.atividade_profissional?.arquivo_extrato_bancario?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.atividade_profissional?.arquivo_extrato_bancario?.caminho === undefined}
                        file={locatario?.atividade_profissional?.arquivo_extrato_bancario?.caminho ?? null}
                        status={itsView ? null : campos.extrato_bancario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Extrato Bancário 2`}
                        name={`extrato_bancario2`}
                        value={locatario?.atividade_profissional?.arquivo_extrato_bancario2?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.atividade_profissional?.arquivo_extrato_bancario2?.caminho === undefined}
                        file={locatario?.atividade_profissional?.arquivo_extrato_bancario2?.caminho ?? null}
                        status={itsView ? null : campos.extrato_bancario2 ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Extrato Bancário 3`}
                        name={`extrato_bancario3`}
                        value={locatario?.atividade_profissional?.arquivo_extrato_bancario3?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.atividade_profissional?.arquivo_extrato_bancario3?.caminho === undefined}
                        file={locatario?.atividade_profissional?.arquivo_extrato_bancario3?.caminho ?? null}
                        status={itsView ? null : campos.extrato_bancario3 ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Outro`}
                        name={`outro`}
                        value={locatario?.atividade_profissional?.arquivo_outro?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        hidden={locatario?.atividade_profissional?.arquivo_outro?.caminho === undefined}
                        file={locatario?.atividade_profissional?.arquivo_outro?.caminho ?? null}
                        status={itsView ? null : campos.outro ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />

                </div>

                {/* Dados do Procurador - 19 campos*/}
                <div className={`mt-12 ${locatario?.procurador === undefined || locatario?.procurador === null ? 'hidden' : ''}`}>
                    <TitleValidate
                        title={'Dados do Procurador'}
                        selectSection={selectSection}
                        checkSection={sections?.procurador ?? null}
                        section={8}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Nome`}
                        name={`nome_procurador`}
                        value={locatario?.procurador?.informacao_basica?.nome_cliente ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.nome_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Telefone 1`}
                        name={`telefone_procurador`}
                        value={mask(locatario?.procurador?.informacao_basica?.telefone1, 'telefone') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.telefone_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Telefone 2`}
                        name={`telefone2_procurador`}
                        value={mask(locatario?.procurador?.informacao_basica?.telefone2, 'telefone') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.telefone2_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <span className="font-extrabold text-lg">Documento de Identificação Oficial com Foto*</span>
                    <InputValidate
                        title={`${rgOrCnhProcurador} - Frente`}
                        name={`rg_cnh_frente_procurador`}
                        value={locatario?.procurador?.informacao_basica?.rg_cnh_frente?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        file={locatario?.procurador?.informacao_basica?.rg_cnh_frente?.caminho ?? null}
                        status={itsView ? null : campos.rg_cnh_frente_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`${rgOrCnhProcurador} - Verso`}
                        name={`rg_cnh_verso_procurador`}
                        value={locatario?.procurador?.informacao_basica?.rg_cnh_verso?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        file={locatario?.procurador?.informacao_basica?.rg_cnh_verso?.caminho ?? null}
                        status={itsView ? null : campos.rg_cnh_verso_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`CPF`}
                        name={`cpf_procurador`}
                        value={mask(locatario?.procurador?.informacao_basica?.cpf, 'cpf_cnpj') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.cpf_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`E-mail`}
                        name={`email_procurador`}
                        value={locatario?.procurador?.informacao_basica?.email ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.email_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    {locatario?.procurador?.informacao_basica?.nacionalidade === null ? "" :
                        <>
                            <InputValidate
                                title={`Naturalidade`}
                                name={`naturalidade_procurador`}
                                value={locatario?.procurador?.informacao_basica?.naturalidade ?? null}
                                hidden={locatario?.procurador?.informacao_basica?.naturalidade === null}
                                onClick={handleClick}
                                status={itsView ? null : campos.naturalidade_procurador ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <InputValidate
                                title={`Nacionalidade`}
                                name={`nacionalidade_procurador`}
                                value={locatario?.procurador?.informacao_basica?.nacionalidade ?? null}
                                hidden={locatario?.procurador?.informacao_basica?.nacionalidade === null}
                                onClick={handleClick}
                                status={itsView ? null : campos.nacionalidade_procurador ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                        </>
                    }
                    <InputValidate
                        title={`Profissão`}
                        name={`profissao_procurador`}
                        value={locatario?.procurador?.informacao_basica?.profissao ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.profissao_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Estado Civil`}
                        name={`estado_civil_procurador`}
                        value={locatario?.procurador?.informacao_basica?.estado_civil?.descricao ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.estado_civil_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Documento da Procuração`}
                        name={`documento_procurador`}
                        value={locatario?.procurador?.documento_procuracao?.nome ?? null}
                        onClick={handleClick}
                        isFile={true}
                        file={locatario?.procurador?.documento_procuracao?.caminho ?? null}
                        status={itsView ? null : campos.documento_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`CEP`}
                        name={`cep_procurador`}
                        value={mask(locatario?.procurador?.endereco?.cep, 'cep') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.cep_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Logradouro`}
                        name={`logradouro_procurador`}
                        value={locatario?.procurador?.endereco?.logradouro ?? null}

                        onClick={handleClick}
                        status={itsView ? null : campos.logradouro_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Número`}
                        name={`numero_procurador`}
                        value={locatario?.procurador?.endereco?.numero ?? null}

                        onClick={handleClick}
                        status={itsView ? null : campos.numero_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Complemento`}
                        name={`complemento_procurador`}
                        value={locatario?.procurador?.endereco?.complemento ?? null}
                        onClick={handleClick}
                        hidden={locatario?.procurador?.endereco?.complemento === null}
                        status={itsView ? null : campos.complemento_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Bairro`}
                        name={`bairro_procurador`}
                        value={locatario?.procurador?.endereco?.bairro ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.bairro_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Cidade`}
                        name={`cidade_procurador`}
                        value={locatario?.procurador?.endereco?.cidade ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.cidade_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Estado`}
                        name={`estado_procurador`}
                        value={locatario?.procurador?.endereco?.estado ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.estado_procurador ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                </div>

                {/* Outros Dados - x campos*/}
                <div className={`mt-12 ${locatario?.fiador.filter((item: any) => { return item?.status === 1 }).length > 0 || locatario?.locatario_secundario.filter((item: any) => { return item?.principal === false }).length > 0 ? '' : 'hidden'}`}>
                    <TitleValidate
                        title={'Outros Dados'}
                        selectSection={selectSection}
                        checkSection={sections?.outros_dados ?? null}
                        section={9}
                        itsButton={true}
                    />
                    {locatario?.locatario_secundario.filter((item: any) => { return item?.principal === false }).map((lo: any) => {
                        return locatarioSecundarioSection(lo);
                    })}

                    {locatario?.fiador.map((item: any) => {
                        return fiadorSection(item);
                    })}
                </div>

                {/* Imóvel Pretendido */}
                <div className={`mt-12 ${locatario?.imovel_pretendido === null ? 'hidden' : ''}`}>
                    <TitleValidate
                        title={'Imóvel Pretendido'}
                        selectSection={selectSection}
                        checkSection={sections?.imovel_pretendido ?? null}
                        section={4}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Finalidade`}
                        name={`finalidade_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.finalidade?.descricao ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.finalidade_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Código do Imóvel`}
                        name={`codigo_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.imovel?.codigo_imovel ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.codigo_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Logradouro`}
                        name={`logradouro_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.imovel?.endereco ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.logradouro_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Número`}
                        name={`numero_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.imovel?.numero ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.numero_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Complemento`}
                        name={`complemento_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.imovel?.complemento ?? null}
                        onClick={handleClick}
                        hidden={locatario?.imovel_pretendido?.imovel?.complemento === null}
                        status={itsView ? null : campos.complemento_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Bairro`}
                        name={`bairro_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.imovel?.bairro ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.bairro_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Cidade`}
                        name={`cidade_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.imovel?.cidade ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.cidade_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Estado`}
                        name={`estado_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.imovel?.estado ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.estado_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Aluguel`}
                        name={`aluguel_imovel_pretendido`}
                        value={formatarValor(locatario?.imovel_pretendido?.imovel?.preco_locacao, "dinheiro") ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.aluguel_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`IPTU`}
                        name={`iptu_imovel_pretendido`}
                        value={formatarValor(locatario?.imovel_pretendido?.imovel?.iptu, "dinheiro") ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.iptu_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Condomínio`}
                        name={`condominio_imovel_pretendido`}
                        value={formatarValor(locatario?.imovel_pretendido?.imovel?.condominio, "dinheiro") ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.condominio_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Sede Responsável pelo Atendimento`}
                        name={`sede_responsavel_atendimento`}
                        value={locatario?.sede_responsavel?.nome ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.sede_responsavel_atendimento ?? null}
                        itsButton={true}
                    />
                </div>

                {locatario?.principal ?
                    <CheckBox
                        id="novo_locatario"
                        checked={!addLocatario}
                        funcao={onClickLocatarioSecundario}
                        label={'Solicitar adição de novos Locatários Secundários?'}
                        style={'mb-2 mt-2'}
                    // id={'1'}
                    />
                    : ""
                }
                {/* comentario*/}
                <div className={`${itsView ? 'hidden' : 'instrucoes-reenvio'} mt-2`}>
                    <div>
                        <p className="font-extrabold text-lg text-gray-system-mega-imoveis">Instruções para reenvio*</p>
                        <textarea
                            rows={2}
                            name={`instrucao_reenvio`}
                            value={locatario?.instrucao_reenvio}
                            style={{ maxHeight: "20rem", minHeight: "3.5rem", resize: "none" }}
                            className="block p-4 w-full h-32 mt-2 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                            placeholder={'Adicione instruções para reenvio'}
                            onChange={onChange}
                            required={existFalse}
                        />
                    </div>
                </div>
            </div>
            {
                !statusNotButton.includes(locatario?.ultimo_historico?.status?.id) ? <div>
                    {itsFullIsTrue() ? <div className="w-full flex flex-row gap-4 justify-center items-center mb-8">
                        <div>
                            <button
                                type="button"
                                onClick={() => openModal(true)}
                                className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                                style={{ backgroundColor: '#FF3B3B' }}
                            >
                                reprovar cadastro
                            </button>
                        </div>
                        <div>
                            <button
                                type="submit"
                                onClick={() => onSubmit("aprovado", etapaId)}
                                className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                                style={{ backgroundColor: '#0DCB64' }}
                            >
                                aprovar cadastro
                            </button>
                        </div>
                    </div> : <div className="w-full flex flex-row gap-4 justify-center items-center mb-8">
                        <div>
                            <button
                                type="submit"
                                className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs border-2 border-blue-mega-imoveis text-blue-dark-mega-imoveis bg-transparent cursor-pointer rounded-lg justify-center items-center`}
                            >
                                Salvar e continuar depois
                            </button>
                        </div>
                        <div>
                            <button
                                type="submit"
                                disabled={!itsFull() ?? true}
                                onClick={() => onSubmit("reenviado", etapaId)}
                                className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs ${itsFull() ? 'bg-blue-dark-mega-imoveis text-white' : 'bg-gray-300 text-gray-700'}   cursor-pointer rounded-lg justify-center items-center`}
                            >
                                enviar para usuário revisar
                            </button>
                        </div>
                    </div>}
                </div> : ''
            }

        </Fragment >
    );
}

export default FormularioCadastroPessoaFisicaMobile;