import React from "react";
import Select, { GroupBase } from "react-select";
import { selectStyle } from "../InputSelect/style";
// import { Title } from "src/Components";

type Props = {
    name: string;
    isMulti?: boolean;
    isSearchable?: boolean;
    className?: string;
    noOptions?: string,
    onChange?: (valores: any, action: any) => void;
    options:
    | readonly (
        | {
            value: string | number;
            label: string;
        }
        | GroupBase<{
            value: string | number;
            label: string;
        }>
    )[]
    | undefined;
    defaultValue?: {
        value: string | number;
        label: string;
    };
    value:
    | {
        value: string | number;
        label: string;
    }
    | Array<{
        value: string | number;
        label: string;
    }>;
    colClassname?: string;
    titleClassname?: string;
    inputTitle?: string;
    border?: string;
    placeholder?: string;
    isDisable?: boolean;
    styleSelectModified?: any
};

export default function InputSelect({
    name,
    isMulti,
    isSearchable,
    className = "px-4 w-full border border-gray-500 shadow-md h-10 text-sm md:text-base text-gray-800",
    onChange,
    options,
    defaultValue,
    value,
    border,
    colClassname = "col-span-12 md:col-span-6 pb-3",
    titleClassname = "text-base font-bold text-gray-900",
    inputTitle,
    placeholder = "Selecione",
    isDisable = false,
    styleSelectModified = selectStyle,
    noOptions = 'No Options'

}: Props): JSX.Element {

    return (
        <div className={`${colClassname}`}>
            {/* <Title className={`${titleClassname}`} content={`${inputTitle}`} /> */}
            <div className="cursor-pointer flex">
                <Select
                    name={name}
                    isMulti={isMulti}
                    className={
                        className ||
                        border ||
                        "text-base font-bold text-gray-600 border border-solid border-gray-300"
                    }
                    styles={styleSelectModified}
                    placeholder={placeholder}
                    options={options}
                    isSearchable={isSearchable}
                    defaultValue={defaultValue}
                    value={value}
                    onChange={onChange}
                    isDisabled={isDisable}
                    noOptionsMessage={() => noOptions}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: "#ddd",
                            primary: "#0A21FF",

                        },
                    })}
                />
            </div>
        </div>
    );
}
