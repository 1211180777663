import React, { useState, useEffect } from 'react';
import { ClassicProps } from "src/@types/types";
import logoMegaImoveis from 'src/assets/login/logo-mega-imoveis.svg';
import people from "src/assets/icones/people.svg"
import { sidebar } from "./data/data-sidebar.json";
import SidebarItem from './SidebarItem';
import ModalInfoUsuario from '../ModalInfoUsuario';
import './style.css'


function Sidebar({ children }: ClassicProps): JSX.Element {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [usuario, setUsuario] = useState<any>();

    useEffect(() => {
        var usuarioLogado = localStorage.getItem('usuario');
        var user = typeof usuarioLogado == 'string' ? JSON.parse(usuarioLogado) : 'nada';
        setUsuario(user)
    }, []);

    const openModalUser = () => {
        setOpenModal(true);
    }

    const closeModal = () => {
        setOpenModal(false)
    }

    return (
        <>
            <ModalInfoUsuario
                usuario={usuario}
                open={openModal}
                isClose={closeModal}
                setUsuario={setUsuario}
            />
            <div className="flex justify-between">
                <div className="flex lg:w-1/5">
                    <div className="z-40 fixed bg-white align-content-middle shadow-xl top-0 h-full w-full flex flex-col sidebar-content overflow-y-scroll">
                        <div className='align-content-middle my-8 m-auto flex home-logo-edit'>
                            <img src={logoMegaImoveis} width='120' height='120' alt='logo Mega Imoveis' />
                            <div className='info-user-home' onClick={openModalUser}>
                                {usuario?.arquivo ?
                                    <>
                                        <img src={usuario?.arquivo.caminho} width='120' height='120' alt='logo Mega Imoveis' className="foto-perfil" />
                                        <h2>{usuario?.nome}</h2>
                                    </>
                                    :
                                    <>
                                        <div className='foto-nova'>
                                            <img className="icon-usuario-sem-foto" alt="icon_group" src={people} />
                                        </div>
                                        <h2>{usuario?.nome}</h2>
                                    </>
                                }
                            </div>
                        </div>
                        <div className={`flex flex-col h-full mt-4 mb-12 text-gray-system-mega-imoveis`}>
                            {sidebar.menus.map((item: any, index: number) => {
                                return (
                                    <div key={index}>
                                        <SidebarItem
                                            data={item}
                                            chave={index}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className='w-4/5 columns-1'>
                    {children}
                </div>
            </div>
        </>
    );
}

export default Sidebar;